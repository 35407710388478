import {
  ChartConfig,
  TimeSeriesChartType,
  GenericTimeSeriesDataChartConfig,
  ChartAnnotation,
  DataConverter,
  DataConverterOption,
} from '../template/time_series_data_chart'
import {DailyTimeSeriesDataChart, DailyTimeSeriesChartProps} from '../template/daily_time_series_chart'
import {TimeSeriesDataSource} from '../../../model'
import {VisualizerGraphDataType} from '../../../shared/db'

export const TimeSeriesGlucoseChart = (props: DailyTimeSeriesChartProps) => {
  const {height, width, participantId, plotDataRange, commonTaskDataMarkerList} = props

  const generateValueMaker = (high: number, low: number): ChartAnnotation[] => {
    const generateHorizontalLineAnnoation = (value: number, color: string): ChartAnnotation => {
      return {
        type: 'line',
        content: {
          start: ['start', value],
          end: ['end', value],
          top: true,
          style: {
            stroke: color,
            lineWidth: 2,
          },
          text: {
            content: `${value}`,
            position: 'start',
            autoRotate: false,
            offsetY: 15,
            style: {
              fill: color,
            },
          },
        },
      }
    }
    return [generateHorizontalLineAnnoation(high, '#ff8000'), generateHorizontalLineAnnoation(low, '#ff0040')]
  }

  const mgdlChartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Line,
    point: {}, // show point
    chartAnnotations: generateValueMaker(180, 70),
    scaleRange: [40, 360],
  }

  const defaultDataConverterOption: DataConverterOption = {
    label: 'mg/dl',
    value: 'mg/dl',
    chartConfig: mgdlChartConfig,
  }

  const mgdl_to_mmol: DataConverter = (data: TimeSeriesDataSource[]) => {
    return data.map((item) => {
      return {
        t: item.t,
        v: item.v ? parseFloat((item.v * 0.0555).toFixed(1)) : item.v,
      }
    })
  }

  const mmolChartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Line,
    point: {}, // show point
    chartAnnotations: generateValueMaker(10, 3.9),
    scaleRange: [2.2, 20],
  }

  const dataConverterOptions: DataConverterOption[] = [
    defaultDataConverterOption,
    {
      label: 'mmol/l',
      value: 'mmol/l',
      dataConverter: mgdl_to_mmol,
      chartConfig: mmolChartConfig,
    },
  ]

  const dataConfig = {
    defaultDataConverterOption,
    dataConverterOptions,
  }

  const chartConfig: ChartConfig = mgdlChartConfig

  const chartCardConfig = {
    height,
    width,
    title: 'Estimate Glucose',
    hintDescription: '',
  }

  const config: GenericTimeSeriesDataChartConfig = {
    dataConfig,
    chartConfig,
    chartCardConfig,
  }

  const timeSeriesChartProp = {
    dataType: VisualizerGraphDataType.DexcomBloodGlucose,
    config,
    participantId,
    plotDataRange,
    commonTaskDataMarkerList,
    defaultChartAnnotations: generateValueMaker(180, 70),
  }

  return <DailyTimeSeriesDataChart {...timeSeriesChartProp} />
}
