import {useEffect, useState, useRef, Dispatch, SetStateAction} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {
  selectTheme,
  selectProfile,
  selectWorkspaces,
  selectAuth,
  selectProjectData,
  createDispatchActions,
} from '../../store'
import {
  RIF,
  useClickOutside,
  setSessionStorage,
  useCurrentWorkspaceAccountPlan,
  checkIsValidUUID,
  useCurrentWorkspaceState,
  useCollaboratorTypeInProject,
  collaboratorTypeHasRole,
  RequestResult,
} from '../../lib'
import {
  TickIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ThunderIcon,
  LogoutIcon,
  ProfileIcon,
  ProfileImageDefault,
  QuestionMarkBlackIcon,
  ThunderWhiteIcon,
} from '../../asset/image'
import LabfrontLogo from '../../asset/image/labfront_logo.png'
import {
  Tooltip,
  ButtonReverse,
  WorkspaceSettings,
  ProfileSettingsPage,
  PricingPage,
  ToolTipHover,
  StatusTag,
  DropdownMenu,
} from '..'
import {IProject, ProjectCollaboratorRole, WorkspaceCollaboratorRole} from '../../shared/db'

export const Navbar = () => {
  const {fontWeight, color, fontSize} = selectTheme()

  /* ------------------ req ------------------ */
  const {
    doREQUEST_PROJECT_FETCH,
    doREQUEST_PROJECT_UPDATE,
    doREQUEST_IDENTITY_WORKSPACE_LIST_FETCH,
    doREQUEST_IDENTITY_WORKSPACE_COLLABORATOR_LIST_FETCH,
    doREQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH,
    doCURRENT_WORKSPACE_SET,
  }: any = createDispatchActions()

  const [requestResultOfProjectUpdate, setRequestResultOfProjectUpdate] = useState<RequestResult | null>(null)
  const [requestResultOfProjectFetch, setRequestResultOfProjectFetch] = useState<RequestResult | null>(null)

  /* ------------------ reducer ------------------ */
  const location = useLocation()
  const navigate = useNavigate()
  const accessToken = selectAuth()?.accessToken
  const profile = selectProfile()
  const projectData = selectProjectData()

  /* ------------------ basic ------------------ */
  const isLogin: boolean = accessToken && profile?.id
  const pathUrl = location.pathname
  const pageName = location?.pathname?.split('/')?.[1]
  const projectRelatedPageNameList = ['dashboard', 'project_setup', 'participants', 'adherence', 'settings']
  const isAtProjectRelatedPage: boolean = projectRelatedPageNameList.includes(pageName)
  // can not use useParams in Navbar(out of route)
  const projectId = location?.pathname?.split('/')?.slice(-1)[0] ?? ''
  const project: IProject = projectData?.[projectId]
  const {name: projectName, status: projectStatus} = project ?? {}

  // workspace / role / account
  const workspacesState = selectWorkspaces()
  const {
    workspaceId: currentWorkspaceId,
    workspace: currentWorkspace,
    isFavoritesOrSharedWithMe,
  } = useCurrentWorkspaceState({projectId})
  const collaboratorType = useCollaboratorTypeInProject()
  const canUpdateProject = collaboratorTypeHasRole({
    collaboratorType,
    projectRole: ProjectCollaboratorRole.ProjectUpdate,
    workspaceRole: WorkspaceCollaboratorRole.ProjectUpdate,
  })
  const {accountPlan, hasActivePlanPurchase} = useCurrentWorkspaceAccountPlan({projectId})

  const [displayChangePlanPage, setDisplayChangePlanPage] = useState(false)
  const projectContentFromLocalStorage = JSON.parse(localStorage.getItem('labfront_project_content') as string)
  const [tooltipHoverState, setTooltipHoverState] = useState({
    workspace: false,
    upgradePlan: false,
    helpCenter: false,
    profile: false,
  })

  /* ------------------ dropdown menu state ------------------ */
  // workspace & profile dropdown menu
  const [workspaceDropped, setWorkspaceDropped] = useState(false)
  const [profileDropped, setProfileDropped] = useState(false)
  const [isWorkspaceSettingsPage, setIsWorkspaceSettingsPage] = useState(false)
  const [isProfileSettingsPage, setIsProfileSettingsPage] = useState(false)
  // click outside to dismiss dropdown menu
  const workspaceRef: any = useRef()
  useClickOutside(workspaceRef, () => setWorkspaceDropped(false))
  const profileRef: any = useRef()
  useClickOutside(profileRef, () => setProfileDropped(false))

  const toggleDropdown = (clickSection: 'workspace' | 'profile') =>
    clickSection === 'workspace' ? setWorkspaceDropped(!workspaceDropped) : setProfileDropped(!profileDropped)

  /* ------------------ default effect ------------------ */
  useEffect(() => {
    if (isLogin && !currentWorkspace) {
      doREQUEST_IDENTITY_WORKSPACE_COLLABORATOR_LIST_FETCH({
        payload: {
          page: 0,
        },
      })
      doREQUEST_IDENTITY_WORKSPACE_LIST_FETCH({
        payload: {
          page: 0,
        },
      })
    }
  }, [isLogin])

  useEffect(() => {
    if (currentWorkspaceId && !isFavoritesOrSharedWithMe) {
      doREQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH({
        payload: {
          workspaceId: currentWorkspaceId,
          page: 0,
        },
      })
    }
  }, [currentWorkspaceId])

  useEffect(() => {
    if (!isAtProjectRelatedPage || !projectId) return
    setTooltipHoverState({
      workspace: false,
      upgradePlan: false,
      helpCenter: false,
      profile: false,
    })
    // when user refresh project setup page, check localStorage at first
    if (projectContentFromLocalStorage?.id && canUpdateProject && pageName === 'project_setup') {
      doREQUEST_PROJECT_UPDATE({
        setRequestResult: setRequestResultOfProjectUpdate,
        payload: {
          projectId: projectContentFromLocalStorage.id,
          name: projectContentFromLocalStorage.name || undefined,
          investigator: projectContentFromLocalStorage.investigator || undefined,
          organization: projectContentFromLocalStorage.organization || undefined,
          description: projectContentFromLocalStorage.description || undefined,
          contactDescription: projectContentFromLocalStorage.contactValue || undefined,
          participantInstructions: projectContentFromLocalStorage.instructionValue || undefined,
        },
      })
    } else if (pathUrl != '/' && pathUrl != '/signup') {
      doREQUEST_PROJECT_FETCH({
        setRequestResult: setRequestResultOfProjectFetch,
        payload: {
          projectId,
        },
      })
    }
  }, [pathUrl])

  useEffect(() => {
    if (!projectData || !projectId) {
      return
    }
    const currentProject = projectData[projectId]
    if (!currentProject) {
      return
    }
    doREQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH({
      payload: {
        workspaceId: currentProject.workspaceId,
        projectId,
        page: 0,
      },
    })
  }, [projectData, projectId])

  useEffect(() => {
    if (!requestResultOfProjectUpdate?.success) return
    if (requestResultOfProjectUpdate?.result.statusCode === 404) navigate(`/project`)
    localStorage.removeItem('labfront_project_content')
    doREQUEST_PROJECT_FETCH({
      setRequestResult: setRequestResultOfProjectFetch,
      payload: {
        projectId,
      },
    })
  }, [requestResultOfProjectUpdate])

  useEffect(() => {
    if (requestResultOfProjectFetch?.result.statusCode === 404) navigate(`/project`)
  }, [requestResultOfProjectFetch])

  useEffect(() => {
    if (workspacesState.currentWorkspaceId) return
    const ownWorkspace = Object.values(workspacesState).find((item) => item.identityId === profile?.identityId)
    if (ownWorkspace?.id) {
      doCURRENT_WORKSPACE_SET({
        id: ownWorkspace.id,
      })
    }
  }, [workspacesState, currentWorkspaceId])

  /* ------------------ global / error handle / edge cases ------------------ */
  // TODO: create other component to do global API request
  // TODO: check all scenario about redirect
  // redirect to landing page, if no access or draft/deleted
  useEffect(() => {
    if (!isAtProjectRelatedPage || !projectId) return
    if (!checkIsValidUUID(projectId)) return navigate(`/project`)
    if (['dashboard', 'participants', 'adherence'].includes(pageName)) {
      if (['deleted', 'draft'].includes(projectStatus)) {
        return navigate('/project')
      }
    }
    // const projectDataIdList = Object.keys(projectData)
    // if (projectDataIdList.length !== 0 && pageName === 'project_setup' && !projectDataIdList.includes(projectId)) {
    //   return navigate(`/project`)
    // }
  }, [pathUrl])

  const handleLogout = () => {
    window.sessionStorage.removeItem('labfront')
    window.localStorage.removeItem('labfront_project_content')
    window.location.href = '/'
  }

  const profileDropdownOptions = [
    {
      text: 'Profile Settings',
      img: ProfileIcon,
      onClick: () => setIsProfileSettingsPage(true),
    },
    accountPlan
      ? {
          text: hasActivePlanPurchase && accountPlan === 'Advanced' ? 'View Plan' : 'Upgrade Plan',
          img: ThunderIcon,
          onClick: () => setDisplayChangePlanPage(true),
        }
      : null,
    {
      text: 'Logout',
      img: LogoutIcon,
      onClick: handleLogout,
    },
  ]

  const handleBackToWorkspace = () => {
    if (currentWorkspaceId === 'favorites') {
      doCURRENT_WORKSPACE_SET({
        id: project?.workspaceId,
      })
    }
    navigate('/project')
  }

  return (
    <>
      {RIF(
        pathUrl !== '/project',
        <div
          css={{
            width: '100%',
            height: '48px',
            backgroundColor: 'white',
            boxShadow: isAtProjectRelatedPage ? '' : '0px 2px 10px rgba(0, 0, 0, 0.04)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: `0 30px`,
            position: 'fixed',
            top: '0',
            left: '0',
            boxSizing: 'border-box',
            zIndex: '99',
          }}
        >
          {/* ------------------ left navbar ------------------ */}
          <div css={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Link to='/project' css={{display: 'flex'}}>
              <img src={LabfrontLogo} width='55' height='14' css={{marginRight: '10px'}} />
            </Link>

            {RIF(
              isLogin && isAtProjectRelatedPage,
              <div css={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {RIF(
                  currentWorkspaceId !== 'sharedWithMe',
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      onClick={handleBackToWorkspace}
                      onMouseOver={() => setTooltipHoverState((prev) => ({...prev, workspace: true}))}
                      onMouseOut={() => setTooltipHoverState((prev) => ({...prev, workspace: false}))}
                      css={{
                        fontWeight: fontWeight.bold,
                        marginRight: '5px',
                        cursor: 'pointer',
                        borderRadius: '5px',
                        padding: '8px 12px',
                        position: 'relative',
                        ':hover': {
                          background: '#0000000A',
                        },
                      }}
                    >
                      {currentWorkspace?.name}
                      {RIF(
                        tooltipHoverState.workspace,
                        <ToolTipHover
                          {...{
                            title: 'Back to workspace',
                            hoverTopPosition: '120%',
                          }}
                        />,
                      )}
                    </div>
                    <img src={ChevronRightIcon} width='14' height='14' />
                  </div>,
                )}
                <div
                  css={{
                    fontWeight: fontWeight.bold,
                    height: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: '12px',
                  }}
                >
                  {projectName}
                  {RIF(projectName, <StatusTag status={status} css={{marginLeft: '10px'}} />)}
                  {RIF(
                    projectStatus === 'draft',
                    <Link
                      css={{
                        padding: '4px 8px',
                        borderRadius: '3px',
                        border: `1px solid ${color.border._160}`,
                        color: color.textIcon.secondary,
                        fontSize: fontSize.h8,
                        marginLeft: '20px',
                        ':hover': {
                          backgroundColor: color.surface.hover.white,
                        },
                      }}
                      to={`settings/${project?.id}`}
                    >
                      Settings
                    </Link>,
                  )}
                </div>
              </div>,
            )}

            {RIF(
              isLogin && !isAtProjectRelatedPage,
              <div
                onClick={() => toggleDropdown('workspace')}
                ref={workspaceRef}
                css={{
                  fontWeight: fontWeight.bold,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                  padding: '8px 12px',
                  borderRadius: '5px',
                  ':hover': {
                    background: '#0000000A',
                  },
                }}
              >
                {currentWorkspace?.name}
                <img src={ChevronDownIcon} width='14' height='14' css={{marginLeft: '8px'}} />
                <WorkSpaceDropDown
                  {...{
                    workspaceDropped,
                    setIsWorkspaceSettingsPage,
                  }}
                />
              </div>,
            )}
          </div>

          {/* ------------------ right navbar ------------------ */}
          {RIF(
            isLogin,
            <div css={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
              {RIF(
                !hasActivePlanPurchase,
                <div
                  onClick={() => setDisplayChangePlanPage(true)}
                  onMouseOver={() => setTooltipHoverState((prev) => ({...prev, upgradePlan: true}))}
                  onMouseOut={() => setTooltipHoverState((prev) => ({...prev, upgradePlan: false}))}
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    padding: '5px 8px',
                    width: '80px',
                    height: '26px',
                    position: 'relative',
                    borderRadius: '5px',
                    background: color.upgrade,
                  }}
                >
                  <img src={ThunderWhiteIcon} width='16' height='16' css={{marginRight: '4px'}} />
                  <span
                    css={{
                      fontSize: '12px',
                      fontWeight: fontWeight.thick,
                      color: color.white,
                    }}
                  >
                    Upgrade
                  </span>
                  {RIF(
                    tooltipHoverState.upgradePlan,
                    <ToolTipHover
                      {...{
                        title: 'Upgrade plan',
                      }}
                    />,
                  )}
                </div>,
              )}

              <a
                href='https://help.labfront.com/'
                target='_blank'
                onMouseOver={() => setTooltipHoverState((prev) => ({...prev, helpCenter: true}))}
                onMouseOut={() => setTooltipHoverState((prev) => ({...prev, helpCenter: false}))}
                css={{
                  cursor: 'pointer',
                  height: '30px',
                  width: '30px',
                  padding: '10px',
                  margin: '0 15px',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  ':hover': {
                    background: '#0000000A',
                  },
                }}
              >
                <img src={QuestionMarkBlackIcon} width='20' height='20' />
                {RIF(
                  tooltipHoverState.helpCenter,
                  <ToolTipHover
                    {...{
                      title: 'Help Center',
                    }}
                  />,
                )}
              </a>

              {/* profile */}
              <div
                onClick={() => {
                  setTooltipHoverState((prev) => ({...prev, profile: false}))
                  toggleDropdown('profile')
                }}
                onMouseOver={() => {
                  if (!profileDropped) setTooltipHoverState((prev) => ({...prev, profile: true}))
                }}
                onMouseOut={() => setTooltipHoverState((prev) => ({...prev, profile: false}))}
                ref={profileRef}
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  height: '100%',
                  cursor: 'pointer',
                }}
              >
                <img
                  src={profile?.imageUrl ? profile?.imageUrl : ProfileImageDefault}
                  width='30'
                  height='30'
                  css={{borderRadius: '50%'}}
                />
                <DropdownMenu
                  dropped={profileDropped}
                  options={profileDropdownOptions}
                  parentCSS={{
                    top: '30px',
                    right: '-10px',
                    height: '136px',
                  }}
                />
                {RIF(
                  tooltipHoverState.profile,
                  <ToolTipHover
                    {...{
                      title: profile?.firstName ?? '',
                      subtitle: profile?.email ?? '',
                      hoverRightPosition: '10%',
                      pseudoRightPosition: '16%',
                    }}
                  />,
                )}
              </div>
            </div>,
          )}

          {RIF(
            !isLogin,
            <Link to={`${pathUrl === '/' ? '/signup' : '/'}`}>
              <ButtonReverse btnPadding='medium'>{pathUrl === '/' ? 'Sign Up' : 'Sign In'}</ButtonReverse>
            </Link>,
          )}

          {RIF(
            isWorkspaceSettingsPage,
            <WorkspaceSettings
              {...{
                setIsWorkspaceSettingsPage,
              }}
            />,
          )}

          {RIF(isProfileSettingsPage, <ProfileSettingsPage {...{setIsProfileSettingsPage}} />)}

          {RIF(
            displayChangePlanPage,
            <PricingPage
              {...{
                setDisplayChangePlanPage,
              }}
            />,
          )}
        </div>,
      )}
    </>
  )
}

interface WorkSpaceDropDownT {
  workspaceDropped: boolean
  setIsWorkspaceSettingsPage: Dispatch<SetStateAction<boolean>>
}

const WorkSpaceDropDown = (props: WorkSpaceDropDownT) => {
  const {color} = selectTheme()
  const {workspaceDropped, setIsWorkspaceSettingsPage} = props
  const workspacesState = selectWorkspaces()

  return (
    <div
      css={{
        width: '312px',
        background: color.white,
        border: `1px solid ${color.grey_160}`,
        borderRadius: '5px',
        position: 'absolute',
        top: '35px',
        left: '0',
        display: `${workspaceDropped ? 'flex' : 'none'}`,
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxHeight: '400px',
        boxShadow: '0px 4px 10px 1px #68686840',
        cursor: 'auto',
        zIndex: '102',
      }}
    >
      <div css={{overflowY: 'scroll'}}>
        {Object.keys(workspacesState).map((workspaceId) => {
          if (checkIsValidUUID(workspaceId)) {
            return (
              <WorkspaceSelection
                {...{
                  key: workspaceId,
                  workspaceId,
                  setIsWorkspaceSettingsPage,
                }}
              />
            )
          }
        })}
      </div>

      <div
        css={{display: 'flex', padding: '12px 20px', alignItems: 'center', borderTop: `1px solid ${color.grey_160}`}}
      >
        <div css={{fontSize: '12px', color: color.grey_400, width: 'fit-content'}}>
          To view another workspace you're a collaborator on, select it from the menu.
        </div>
        <Tooltip content='Only workspace owners can define workspace permissions' />
      </div>
    </div>
  )
}

interface WorkspaceSelectionT {
  workspaceId: string
  setIsWorkspaceSettingsPage: Dispatch<SetStateAction<boolean>>
}

const WorkspaceSelection = (props: WorkspaceSelectionT) => {
  const {fontWeight, color} = selectTheme()
  const {workspaceId, setIsWorkspaceSettingsPage} = props

  const workspacesState = selectWorkspaces()
  const profileState = selectProfile()
  const {doCURRENT_WORKSPACE_SET}: any = createDispatchActions()
  const {workspaceId: currentWorkspaceId, workspace: currentWorkspace} = useCurrentWorkspaceState()
  const userPermission = workspacesState.roleInWorkspaces[currentWorkspaceId ?? ''] || 'owner'

  const handleClick = () => {
    doCURRENT_WORKSPACE_SET({id: workspaceId})
    setSessionStorage({currentWorkspaceId: workspaceId})
  }

  const workspaceOwnerWording = (() => {
    const workspaceOwnerEmail = workspacesState[workspaceId]?.ownerProfile?.email
    const isWorkspaceOwner = workspaceOwnerEmail && workspaceOwnerEmail === profileState?.email
    return isWorkspaceOwner ? 'Owned by you' : `Owner: ${workspaceOwnerEmail}`
  })()

  return (
    <div
      onClick={handleClick}
      css={{
        display: 'flex',
        padding: '20px',
        borderBottom: `1px solid ${color.grey_160}`,
        position: 'relative',
        cursor: 'pointer',
        ':hover': {
          background: 'rgba(156, 158, 174, 0.05)',
        },
      }}
    >
      {RIF(
        currentWorkspaceId === workspaceId,
        <img src={TickIcon} width='18' height='14' css={{position: 'absolute'}} />,
      )}

      <div css={{marginLeft: '30px'}}>
        <div css={{fontWeight: fontWeight.thick}}>{workspacesState[workspaceId].name}</div>
        <div css={{fontSize: '12px', color: color.grey_600, marginTop: '4px'}}>{workspaceOwnerWording}</div>
        {RIF(
          currentWorkspaceId === workspaceId && !userPermission.includes('project'),
          <button
            onClick={() => setIsWorkspaceSettingsPage(true)}
            css={{
              fontWeight: fontWeight.bold,
              color: color.grey_600,
              border: `1px solid ${color.grey_600}`,
              borderRadius: '5px',
              background: 'transparent',
              cursor: 'pointer',
              marginTop: '8px',
              padding: '3px 7px',
            }}
          >
            Workspace Settings
          </button>,
        )}
      </div>
    </div>
  )
}
