import {CSSObject} from '@emotion/react'
import {selectTheme} from '../../store'

export interface InputDisabledProps {
  content: string
  css?: CSSObject
}

export const InputDisabled = (props: InputDisabledProps) => {
  const {color, pad} = selectTheme()
  return (
    <div
      {...props}
      css={{
        width: '100%',
        height: '43px',
        border: '1px solid #9C9EAE',
        borderRadius: '5px',
        color: color.grey_400,
        padding: pad.mediumSmall,
        background: color.background,
      }}
    >
      {props.content}
    </div>
  )
}
