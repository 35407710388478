import {GarminConnectHookType} from '../../../shared/api'
import {VisualizerGraphDataType} from '../../../shared/db'
import {
  DexcomDeviceDataType,
  GarminDeviceStreamDataType,
  MovesenseDeviceStreamDataType,
  AnalysisDataType,
  GarminDeviceLogDataType,
} from '../../../shared/mongo'

export const GraphDataTypeMap: {[K in VisualizerGraphDataType]: string} = {
  [VisualizerGraphDataType.DexcomBloodGlucose]: DexcomDeviceDataType.DexcomRecord,
  [VisualizerGraphDataType.GarminDirectAccelerometerRaw]: GarminDeviceStreamDataType.GarminAcc,
  [VisualizerGraphDataType.GarminDirectBBI]: GarminDeviceLogDataType.GarminBBI,
  [VisualizerGraphDataType.GarminDirectActigraphyRaw]: GarminDeviceLogDataType.GarminActigraphy,
  [VisualizerGraphDataType.GarminDirectHeartRate]: GarminDeviceLogDataType.GarminHeartRate,
  [VisualizerGraphDataType.GarminDirectSpO2]: GarminDeviceLogDataType.GarminPulseOx,
  [VisualizerGraphDataType.GarminDirectStress]: GarminDeviceLogDataType.GarminStress,
  [VisualizerGraphDataType.GarminDirectSteps]: GarminDeviceLogDataType.GarminStep,
  [VisualizerGraphDataType.GarminDirectTemperature]: GarminDeviceLogDataType.GarminTemperature,
  [VisualizerGraphDataType.GarminConnectStress]: GarminConnectHookType.HealthStress,
  [VisualizerGraphDataType.GarminConnectHeartRate]: GarminConnectHookType.HealthDailies, // fetch HR from dailies
  [VisualizerGraphDataType.GarminConnectRestingHR]: GarminConnectHookType.HealthDailies,
  [VisualizerGraphDataType.GarminConnectBodyBattery]: GarminConnectHookType.HealthStress,
  [VisualizerGraphDataType.GarminConnectSleepStaging]: GarminConnectHookType.HealthSleeps,
  [VisualizerGraphDataType.GarminConnectActivityEpochs]: GarminConnectHookType.HealthEpochs,
  [VisualizerGraphDataType.GarminConnectSteps]: GarminConnectHookType.HealthEpochs,
  [VisualizerGraphDataType.GarminConnectCalories]: GarminConnectHookType.HealthDailies,
  [VisualizerGraphDataType.GarminDeviceWearingTime]: GarminConnectHookType.HealthDailies,
  [VisualizerGraphDataType.MovesenseIMU]: MovesenseDeviceStreamDataType.MovesenseImu,
  [VisualizerGraphDataType.MovesenseECGWaveform]: MovesenseDeviceStreamDataType.MovesenseEcg,
  [VisualizerGraphDataType.MovesenseECGRR]: MovesenseDeviceStreamDataType.MovesenseRr,
  [VisualizerGraphDataType.AnalysisSleepScore]: AnalysisDataType.ActigraphySleep,
  [VisualizerGraphDataType.AnalysisHrv]: AnalysisDataType.HRV,
}

export const GarminLogDataTypeToGraphDataTypeMap: {[K in GarminDeviceLogDataType]: string | null} = {
  [GarminDeviceLogDataType.GarminBBI]: VisualizerGraphDataType.GarminDirectBBI,
  [GarminDeviceLogDataType.GarminEnhancedBBI]: VisualizerGraphDataType.GarminDirectBBI,
  [GarminDeviceLogDataType.GarminActigraphy]: VisualizerGraphDataType.GarminDirectActigraphyRaw,
  [GarminDeviceLogDataType.GarminHeartRate]: VisualizerGraphDataType.GarminDirectHeartRate,
  [GarminDeviceLogDataType.GarminPulseOx]: VisualizerGraphDataType.GarminDirectSpO2,
  [GarminDeviceLogDataType.GarminStress]: VisualizerGraphDataType.GarminDirectStress,
  [GarminDeviceLogDataType.GarminStep]: VisualizerGraphDataType.GarminDirectSteps,
  [GarminDeviceLogDataType.GarminRespiration]: null,
  [GarminDeviceLogDataType.GarminZeroCrossing]: null,
  [GarminDeviceLogDataType.GarminActigraphy2]: null,
  [GarminDeviceLogDataType.GarminActigraphy3]: null,
  [GarminDeviceLogDataType.GarminAcc]: null,
  [GarminDeviceLogDataType.GarminTemperature]: VisualizerGraphDataType.GarminDirectTemperature,
}

export const StreamDataTypeToGraphDataTypeMap: {
  [K in GarminDeviceStreamDataType | MovesenseDeviceStreamDataType]: string
} = {
  [GarminDeviceStreamDataType.GarminAcc]: VisualizerGraphDataType.GarminDirectAccelerometerRaw,
  [MovesenseDeviceStreamDataType.MovesenseImu]: VisualizerGraphDataType.MovesenseIMU,
  [MovesenseDeviceStreamDataType.MovesenseRr]: VisualizerGraphDataType.MovesenseECGRR,
  [MovesenseDeviceStreamDataType.MovesenseEcg]: VisualizerGraphDataType.MovesenseECGWaveform,
  [MovesenseDeviceStreamDataType.MovesenseAcc]: 'MovesenseAcc',
  [MovesenseDeviceStreamDataType.MovesenseMagnetometer]: 'MovesenseMagneto',
  [MovesenseDeviceStreamDataType.MovesenseGyroscope]: 'MovesenseGyro',
}
