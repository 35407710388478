import {makeZip, InputWithoutMeta, InputWithSizeMeta} from 'client-zip'
import {FileSystemFileHandle, showSaveFilePicker} from 'native-file-system-adapter'

import {FileMerger, FileMergerInput, ItemGrouper} from '../tabular_data'
import {
  DataDownloadRequestPayload,
  DataDownloadResponsePayload,
  DataDownloadResponsePayloadObject,
  DataDownloaderItem,
} from './model'
import {baseRequest} from '../request'
import {API_URL} from '../../lib'
import {doAUTH_SET} from '../../store'
import {RefreshTokenAction} from '../auth/refresh_token_action'

export interface DataDownloaderArgs {
  payload: DataDownloadRequestPayload
  fileHandle: FileSystemFileHandle
}

interface DataDownloaderAuth {
  accessToken: string
  refreshToken: string
  accessTokenExpiresInSeconds: number
  refreshTokenExpiresInSeconds: number
}

export interface DataDownloaderConfig {
  auth: DataDownloaderAuth
  onFetch?: (filename: string) => void
}

const decode = (string: string): object | string => {
  const decoded = window.atob(string)
  try {
    return JSON.parse(decoded)
  } catch (_err) {
    return decoded
  }
}

export class DataDownloader {
  constructor(config: DataDownloaderConfig) {
    const {auth, onFetch} = config
    this.auth = auth
    this.onFetch = onFetch
  }

  private auth: DataDownloaderAuth
  private onFetch?: DataDownloaderConfig['onFetch']

  private get placeholderInput(): InputWithSizeMeta {
    return {
      input: '',
      name: 'Powered by Labfront.txt',
    }
  }

  async run(args: DataDownloaderArgs): Promise<void> {
    const {payload, fileHandle} = args
    const zipStream = makeZip(this.inputIterable(payload), {buffersAreUTF8: true})
    const saveStream = await fileHandle.createWritable({keepExistingData: false})
    return zipStream.pipeTo(saveStream)
  }

  private async *inputIterable(data: DataDownloadRequestPayload): AsyncIterable<InputWithSizeMeta | InputWithoutMeta> {
    yield this.placeholderInput
    for await (const page of this.pagesIterable(data)) {
      for await (const file of this.filesIterable(page)) {
        yield file
      }
    }
  }

  private async *pagesIterable(data: DataDownloadRequestPayload): AsyncIterable<DataDownloaderItem[]> {
    let continuation: string | undefined = undefined
    do {
      const payload: DataDownloadResponsePayload = await this.requestDownload({
        ...data,
        continuation,
      })
      continuation = payload.continuation

      // debug
      // if (continuation) {
      //   const arg = decode(continuation)
      //   console.log('arg')
      //   console.log(arg)
      // }

      if (payload.objects.length) {
        yield payload.objects.map(({remotePath, displayPath, ...object}: DataDownloadResponsePayloadObject) => ({
          ...object,
          key: remotePath,
          name: displayPath,
        }))
      }
    } while (continuation)
  }

  private REQUEST_DOWNLOAD_RETRY_MAX = 5

  private async *filesIterable(inputs: DataDownloaderItem[]): AsyncIterable<InputWithoutMeta> {
    const grouper = new ItemGrouper()
    const merger = new FileMerger()

    for (const group of grouper.group(inputs)) {
      const files: FileMergerInput[] = []
      for (const {url, ...metadata} of group) {
        this.onFetch?.(metadata.key)

        let retry = 0
        while (retry < this.REQUEST_DOWNLOAD_RETRY_MAX) {
          try {
            const response = await fetch(url)
            if (response.status >= 200 && response.status <= 299) {
              if (response.body) {
                files.push({
                  ...metadata,
                  input: response.body,
                })
              }
              break
            } else {
              response.body?.cancel()
              throw new Error(`fetch ${url} failed: ${response}`)
            }
          } catch (e) {
            retry++
            console.log(`retry: ${retry}`)
            if (retry == this.REQUEST_DOWNLOAD_RETRY_MAX) {
              console.log(e)
              throw e
            }
          }
        }
      }
      if (files.length) {
        const mergedFile = await merger.merge(files)
        yield mergedFile
      }
    }
  }

  private async requestDownload(data: DataDownloadRequestPayload): Promise<DataDownloadResponsePayload> {
    const doRequest = () =>
      baseRequest({
        method: 'post',
        url: `${API_URL}/v2/web/project-data-download-metadata-fetch`,
        data,
        accessToken: this.auth.accessToken,
      })

    let response = await doRequest()
    if (!response.success) {
      if (response.statusCode === 401) {
        await this.refreshToken()
        response = await doRequest()
        if (!response.success) {
          throw response.error
        }
      } else {
        throw response.error
      }
    }
    return response.payload as DataDownloadResponsePayload
  }

  private async refreshToken() {
    const response = await RefreshTokenAction.instance.execute(this.auth.refreshToken)
    if (!response.success) {
      throw response.error
    }
    const {accessToken, refreshToken, accessTokenExpiresInSeconds, refreshTokenExpiresInSeconds} =
      response.payload as DataDownloaderAuth
    this.auth = {
      accessToken,
      refreshToken,
      accessTokenExpiresInSeconds,
      refreshTokenExpiresInSeconds,
    }
    doAUTH_SET({
      accessToken,
      refreshToken,
      accessTokenExpiresInSeconds,
      refreshTokenExpiresInSeconds,
    })
  }
}
