import {TagIcon} from '../../asset/image'
import {ITag} from '../../shared/db'
import {selectTheme} from '../../store'
import {Tag, TagClickHandler} from '../atoms'

export interface ParticipantTagsContainerProps {
  tags: ITag[]
  showTrailingTagIcon?: boolean
  onClick?: () => void
}

export const ParticipantTagsContainer = (props: ParticipantTagsContainerProps) => {
  const {pad} = selectTheme()
  const {tags, showTrailingTagIcon, onClick} = props
  const tagElements = tags.map((tag) => <Tag key={tag.id} tag={tag} />)
  if (showTrailingTagIcon) {
    tagElements.push(<img key='tagIcon' src={TagIcon} width={16} />)
  }
  return (
    <div
      onClick={onClick}
      style={{
        width: '100%',
        height: '100%',
        padding: `0 ${pad.medium}`,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 4,
      }}
    >
      {tagElements}
    </div>
  )
}
