import {IGarminDevice} from '../../../shared/db'
import {AnalysisDataType, GarminDeviceLogDataType} from '../../../shared/mongo'
import {DexcomDeviceDataType} from '../../../shared/mongo'

export function getDataIntervalByDataType(
  dataType: GarminDeviceLogDataType | DexcomDeviceDataType.DexcomRecord | AnalysisDataType,
  garminDeviceTaskConfig?: IGarminDevice,
) {
  switch (dataType) {
    case AnalysisDataType.ActigraphySleep:
      return getDataIntervalFromTaskConfig(GarminDeviceLogDataType.GarminActigraphy, garminDeviceTaskConfig)
    case AnalysisDataType.HRV:
      return 300 // 5 minutes
    default:
      return getDataIntervalFromTaskConfig(dataType, garminDeviceTaskConfig)
  }
}

export function getDataIntervalFromTaskConfig(
  dataType: GarminDeviceLogDataType | DexcomDeviceDataType.DexcomRecord,
  garminDeviceTaskConfig?: IGarminDevice,
): number {
  const defaultInterval = 60
  switch (dataType) {
    case GarminDeviceLogDataType.GarminBBI:
      return 1
    case GarminDeviceLogDataType.GarminStep:
      return 60
    case DexcomDeviceDataType.DexcomRecord:
      return 300
    default:
      if (dataType === GarminDeviceLogDataType.GarminHeartRate) {
        return garminDeviceTaskConfig?.heartRateSampleRate ?? defaultInterval
      } else if (dataType === GarminDeviceLogDataType.GarminPulseOx) {
        return garminDeviceTaskConfig?.pulseOxSampleRate ?? defaultInterval
      } else if (dataType === GarminDeviceLogDataType.GarminStress) {
        return garminDeviceTaskConfig?.stressSampleRate ?? defaultInterval
      } else if (dataType === GarminDeviceLogDataType.GarminRespiration) {
        return garminDeviceTaskConfig?.respirationSampleRate ?? defaultInterval
      } else if (dataType === GarminDeviceLogDataType.GarminActigraphy) {
        return garminDeviceTaskConfig?.actigraphySampleRate ?? defaultInterval
      } else if (dataType === GarminDeviceLogDataType.GarminZeroCrossing) {
        return garminDeviceTaskConfig?.zeroCrossingSampleRate ?? defaultInterval
      } else if (dataType === GarminDeviceLogDataType.GarminTemperature) {
        return garminDeviceTaskConfig?.temperatureSampleRate ?? defaultInterval
      }
      return defaultInterval
  }
}
