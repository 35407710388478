import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import {isAfter} from 'date-fns'

import {collaboratorTypeHasRole, RIF, useClickOutside, useCollaboratorTypeInProject} from '../../lib'
import {createDispatchActions, selectAdherenceData, selectTheme} from '../../store'
import {ParticipantSelected} from '../../model'
import {IParticipant, ITag, ProjectCollaboratorRole, WorkspaceCollaboratorRole} from '../../shared/db'
import {
  Checkbox,
  NotificationButton,
  ParticipantDeletePop,
  ParticipantPopup,
  ParticipantRegenerateCodePop,
  ParticipantTagsContainer,
  PopupAddTag,
  PopupConfirmDeleteTag,
  PopupEditTag,
  Tooltip,
} from '..'
import {
  CheckAllIcon,
  CheckboxDefaultIcon,
  EditIcon,
  NotificationDefaultIcon,
  RefreshIcon,
  TagIcon,
} from '../../asset/image'
import {debounce} from 'lodash'

export interface ParticipantsPanelEntryPropsT {
  formHeader: boolean
  projectId?: string
  index?: number
  participant?: IParticipant
  selectedList: ParticipantSelected[]
  setSelectedList: Dispatch<SetStateAction<ParticipantSelected[]>>
  isHeaderSelectBtnClicked: boolean
  setIsHeaderSelectBtnClicked?: Dispatch<SetStateAction<boolean>>
  displayParticipantList?: IParticipant[]
  setDisplayNotificationPop?: Dispatch<SetStateAction<boolean>>
  projectTagList?: ITag[]
}

export const ParticipantsPanelEntry = (props: ParticipantsPanelEntryPropsT) => {
  const {color, pad} = selectTheme()

  const {
    formHeader,
    projectId,
    index,
    participant,
    displayParticipantList,
    selectedList,
    setSelectedList,
    isHeaderSelectBtnClicked,
    setIsHeaderSelectBtnClicked,
    setDisplayNotificationPop,
    projectTagList,
  } = props

  const tagRef = useRef(null)
  useClickOutside(tagRef, () => {
    setIsAddTagPopup(false)
    setEditingTagId(null)
  })

  const collaboratorType = useCollaboratorTypeInProject()
  const canUpdateParticipant = collaboratorTypeHasRole({
    collaboratorType: collaboratorType,
    projectRole: ProjectCollaboratorRole.ParticipantUpdate,
    workspaceRole: WorkspaceCollaboratorRole.ParticipantUpdate,
  })
  const adherence = participant && selectAdherenceData()?.[participant?.projectId]
  const adherenceParticipant = adherence?.adherenceList?.find(
    (item: {participantId: string}) => item.participantId === participant?.id,
  )
  const participantDeviceType = adherenceParticipant?.status?.deviceType
  const participantDeviceFirmware = adherenceParticipant?.status?.deviceFirmware
  const participantMobileType = adherenceParticipant?.status?.mobileType
  const participantAppVersion = adherenceParticipant?.status?.appVersion

  // invite code
  const credentials = participant?.credentials
  const loginCode = credentials?.loginCode
  const loginCodeExpired = credentials && isAfter(new Date(), new Date(credentials?.expiresUnixTimestamp))
  const [regenerateCode, setRegenerateCode] = useState<string | null>(null)

  // request
  const {doREQUEST_PROJECT_TAG_DELETE}: any = createDispatchActions()

  // UI
  const [isEditPopup, setIsEditPopup] = useState(false)
  const [isDeletePopup, setIsDeletePopup] = useState(false)
  const [isRegenerateCodePopup, setIsRegenerateCodePopup] = useState(false)
  const [isAddTagPopup, setIsAddTagPopup] = useState(false)
  const [isConfirmDeleteTagPopup, setIsConfirmDeleteTagPopup] = useState(false)
  const [editingTagId, setEditingTagId] = useState<string | null>(null)
  const [hovering, setHovering] = useState(false)

  const participantSelected: boolean = selectedList?.some((item: any) => item.participantId === participant?.id)

  const handleClick = () => {
    if (canUpdateParticipant) setIsEditPopup(true)
  }

  const handleClickHeaderSelectBtn = () => {
    setIsHeaderSelectBtnClicked?.((prev) => !prev)
    // select all
    if (selectedList.length === 0) {
      const tempObjArr: ParticipantSelected[] | undefined = displayParticipantList?.map((item) => ({
        participantId: item?.id,
        insignia: item?.insignia,
        pushEnable: !!item?.pushDeviceId,
      }))
      if (tempObjArr) setSelectedList?.(tempObjArr)
    }
    // cancel all
    if (selectedList.length !== 0) {
      if (isHeaderSelectBtnClicked) {
        setSelectedList?.([])
      }
    }
  }

  const handleClickSelect = () => {
    if (!participant) return
    if (!participantSelected) {
      setSelectedList?.((prev) => [
        ...prev,
        {
          participantId: participant?.id,
          insignia: participant?.insignia,
          pushEnable: !!participant?.pushDeviceId,
        },
      ])
    }
    if (participantSelected) {
      setSelectedList?.((prev) => prev.filter((item) => item.participantId !== participant?.id))
    }
  }

  const handleClickDeleteTag = debounce(
    () => {
      const selectedTag = projectTagList?.find(({id}) => id === editingTagId)
      if (selectedTag) {
        const {projectId, id} = selectedTag
        doREQUEST_PROJECT_TAG_DELETE({
          payload: {
            projectId,
            tagIds: [id],
          },
        })
        setIsConfirmDeleteTagPopup(false)
        setEditingTagId(null)
      }
    },
    1000,
    {leading: true, trailing: false},
  )

  useEffect(() => {
    if (selectedList?.length > 0) return setIsHeaderSelectBtnClicked?.(true)
    if (selectedList?.length === 0) return setIsHeaderSelectBtnClicked?.(false)
  }, [selectedList?.length])

  return (
    <>
      {RIF(
        isEditPopup && !formHeader,
        <ParticipantPopup
          {...{
            method: 'Edit',
            projectId,
            closeAction: setIsEditPopup,
            setIsDeletePopup,
            participant,
          }}
        />,
      )}

      {RIF(
        isDeletePopup,
        <ParticipantDeletePop
          {...{
            closeAction: setIsDeletePopup,
            participant,
          }}
        />,
      )}

      {RIF(
        isRegenerateCodePopup,
        <ParticipantRegenerateCodePop
          {...{
            closeAction: setIsRegenerateCodePopup,
            participant,
            setRegenerateCode,
          }}
        />,
      )}

      {RIF(
        isConfirmDeleteTagPopup,
        <PopupConfirmDeleteTag onClose={setIsConfirmDeleteTagPopup} onDelete={handleClickDeleteTag} />,
      )}

      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        css={{
          display: 'flex',
          width: '100%',
          minHeight: '44px',
          borderTop: formHeader ? 'none' : `1px solid ${color.grey_100}`,
          borderBottom: formHeader ? `1px solid ${color.grey_100}` : 'none',
          cursor: 'pointer',
          background: hovering ? ' #9C9EAE0D' : color.white,
        }}
      >
        {/* Index */}
        <div
          css={{
            width: '3%',
            borderRight: `2px solid ${color.grey_100}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {index ? index : '#'}
        </div>

        {/* Checkbox */}
        {RIF(
          canUpdateParticipant,
          <div
            css={{
              width: '3%',
              minWidth: '16px',
              borderRight: `1px solid ${color.grey_100}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {RIF(
              formHeader,
              <div onClick={handleClickHeaderSelectBtn} css={{cursor: 'pointer'}}>
                {RIF(isHeaderSelectBtnClicked, <img src={CheckAllIcon} width={16} />)}
                {RIF(!isHeaderSelectBtnClicked, <img src={CheckboxDefaultIcon} width={16} />)}
              </div>,
            )}
            {RIF(
              !formHeader,
              <Checkbox
                name={participant?.insignia}
                onChange={handleClickSelect}
                value={participantSelected}
                data-testid='test_checkbox'
              />,
            )}
          </div>,
        )}

        {/* Insignia  */}
        <div
          onClick={handleClick}
          css={{
            display: 'flex',
            alignItems: 'center',
            width: '12%',
            borderRight: `1px solid ${color.grey_100}`,
          }}
        >
          <span
            css={{
              paddingLeft: pad.medium,
              paddingRight: pad.mediumSmall,
              cursor: !formHeader ? 'pointer' : 'auto',
              // textOverflow: 'ellipsis',
              // overflow: 'hidden',
              // whiteSpace: 'nowrap',
              wordBreak: 'break-word',
            }}
          >
            {formHeader ? (
              'ID'
            ) : participant?.insignia ? (
              participant?.insignia
            ) : (
              <span css={{color: color.grey_400}}>--</span>
            )}
          </span>
        </div>

        {/* Notification Button */}
        <div
          css={{
            width: '3%',
            borderRight: `1px solid ${color.grey_100}`,
            display: canUpdateParticipant ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {RIF(formHeader, <img src={NotificationDefaultIcon} width='20' />)}
          {RIF(
            !formHeader,
            participant?.activated ? (
              <NotificationButton
                enable={!!participant?.pushDeviceId}
                onClick={() => {
                  setSelectedList?.([
                    {
                      participantId: participant?.id,
                      insignia: participant?.insignia,
                      pushEnable: !!participant?.pushDeviceId,
                    },
                  ])
                  setDisplayNotificationPop?.(true)
                }}
                data-testid='test_notificationButton'
              />
            ) : (
              <span css={{color: color.grey_400}}>--</span>
            ),
          )}
        </div>

        {/* Tag */}
        <div
          ref={tagRef}
          css={{
            width: '12%',
            borderRight: `1px solid ${color.grey_100}`,
            position: 'relative',
          }}
        >
          {RIF(
            formHeader,
            <div
              css={{
                width: '100%',
                height: '100%',
                padding: `0 ${pad.medium}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span>Tag</span>
              <Tooltip content='You can attach tags to participants for grouping' />
            </div>,
          )}
          {RIF(
            !formHeader && participant?.tagList?.length,
            <ParticipantTagsContainer tags={participant?.tagList ?? []} onClick={() => setIsAddTagPopup(true)} />,
          )}
          {RIF(
            !formHeader && !participant?.tagList?.length,
            <div
              onClick={() => setIsAddTagPopup(true)}
              css={{
                width: '100%',
                height: '100%',
                padding: `0 ${pad.medium}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <img src={TagIcon} height='15' css={{marginRight: '4px'}} />
              <span css={{color: color.grey_300}}>Add tag</span>
            </div>,
          )}
          {RIF(
            isAddTagPopup,
            <PopupAddTag
              {...{
                participant,
                projectTagList: projectTagList ?? [],
                isEditingTag: !!editingTagId,
                onClose: setIsAddTagPopup,
                onEditTag: ({id}) => {
                  if (editingTagId !== id) {
                    setEditingTagId(id)
                  } else {
                    setEditingTagId(null)
                  }
                },
                style: {
                  position: 'absolute',
                  width: '150%',
                  height: 'auto',
                  top: 0,
                  left: 0,
                },
              }}
            />,
          )}
          {RIF(
            editingTagId,
            <PopupEditTag
              key={editingTagId}
              {...{
                tag: projectTagList?.find(({id}) => id === editingTagId) ?? null,
                onClose: () => setEditingTagId(null),
                onDeleteTag: () => setIsConfirmDeleteTagPopup(true),
                style: {
                  position: 'absolute',
                  top: 0,
                  left: '150%',
                },
              }}
            />,
          )}
        </div>

        {/* Activate Status */}
        <div
          css={{
            width: '8%',
            borderRight: `1px solid ${color.grey_100}`,
            display: 'flex',
            alignItems: 'center',
            padding: `0 ${pad.medium}`,
            background: color.grey_20,
            mixBlendMode: 'multiply',
          }}
        >
          {RIF(
            formHeader,
            <div css={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <span>Status</span>
              <Tooltip content='Participants have joined when they have entered their invite code.' />
            </div>,
          )}
          {RIF(
            !formHeader,
            participant?.activated ? <span css={{color: color.task.green}}>Joined</span> : 'Not joined yet',
          )}
        </div>

        {/* Invite code */}
        <div
          css={{
            width: '15%',
            borderRight: `1px solid ${color.grey_100}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: `0 ${pad.medium}`,
            background: color.grey_20,
            mixBlendMode: 'multiply',
          }}
        >
          {RIF(
            formHeader,
            <div
              css={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span>Invite Code</span>
              <Tooltip content='Participants will enter code on their app to join study' />
            </div>,
          )}
          {RIF(
            !formHeader,
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: canUpdateParticipant ? 'space-between' : 'center',
                width: '100%',
              }}
            >
              {/* if had regenerateCode from pop then show it */}
              {regenerateCode ? (
                <span
                  data-testid='regenerateCode'
                  css={{
                    fontFamily: 'Roboto Mono, monospace',
                    fontWeight: '500',
                    letterSpacing: '1px',
                    fontSize: 'initial',
                  }}
                >
                  {regenerateCode}
                </span>
              ) : loginCodeExpired ? (
                <span css={{color: color.grey_400}}>Expired</span>
              ) : !participant?.credentials?.loginCode ? (
                <span css={{color: color.grey_400}}>Used</span>
              ) : (
                <span
                  data-testid='loginCode'
                  css={{
                    fontFamily: 'Roboto Mono, monospace',
                    fontWeight: '500',
                    letterSpacing: '1px',
                    fontSize: 'initial',
                  }}
                >
                  {loginCode}
                </span>
              )}
              {RIF(
                canUpdateParticipant,
                <button
                  onClick={() => setIsRegenerateCodePopup(true)}
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border: 'none',
                    backgroundColor: 'transparent',
                    height: '100%',
                  }}
                >
                  <img src={RefreshIcon} height='15' />
                  <span css={{color: color.primary, marginLeft: '8px'}}>Regenerate</span>
                </button>,
              )}
            </div>,
          )}
        </div>

        {/* Mobile Type */}
        <div
          css={{
            width: '9%',
            borderRight: `1px solid ${color.grey_100}`,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: pad.medium,
          }}
        >
          {formHeader ? (
            'Mobile Type'
          ) : (
            <span css={{color: color.grey_400, wordBreak: 'break-word'}}>
              {participantMobileType ? participantMobileType.toUpperCase() : '--'}
            </span>
          )}
        </div>

        {/* App Version */}
        <div
          css={{
            width: '12%',
            borderRight: `1px solid ${color.grey_100}`,
            display: 'flex',
            alignItems: 'center',
            padding: `0 ${pad.medium}`,
          }}
        >
          {formHeader ? (
            <div
              css={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                textAlign: 'start',
              }}
            >
              <p>App Version</p>
              <Tooltip
                content={
                  <p css={{color: color.white}}>
                    To check the latest version of iOS, go&nbsp;
                    <a
                      target='_blank'
                      css={{color: color.primary, textDecoration: 'underline'}}
                      href='https://apps.apple.com/us/app/labfront-companion/id1626813782'
                    >
                      here
                    </a>
                    &nbsp;(Check the What's New section where it has version history). To check the latest version of
                    Android, go&nbsp;
                    <a
                      target='_blank'
                      css={{color: color.primary, textDecoration: 'underline'}}
                      href='https://play.google.com/store/apps/details?id=com.labfront.production'
                    >
                      here
                    </a>
                    &nbsp;(Check the About this App section).
                  </p>
                }
              />
            </div>
          ) : (
            <span css={{color: color.grey_400, wordBreak: 'break-word'}}>
              {participantAppVersion ? participantAppVersion : '--'}
            </span>
          )}
        </div>

        {/* Device Type */}
        <div
          css={{
            width: '9%',
            borderRight: `1px solid ${color.grey_100}`,
            display: 'flex',
            alignItems: 'center',
            padding: `0 ${pad.medium}`,
          }}
        >
          {formHeader ? (
            <div
              css={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                textAlign: 'start',
              }}
            >
              <p>Device Type</p>
              <Tooltip
                content={
                  <p css={{color: color.white}}>
                    Check if your participants are using the latest device firmware. For a full list of recommended
                    devices, check&nbsp;
                    <a
                      target='_blank'
                      css={{color: color.primary, textDecoration: 'underline'}}
                      href='https://help.labfront.com/garmin-firmware-version-guide-for-supported-devices'
                    >
                      here
                    </a>
                    . To learn how to update firmware, check&nbsp;
                    <a
                      target='_blank'
                      css={{color: color.primary, textDecoration: 'underline'}}
                      href='https://help.labfront.com/update-garmin-device'
                    >
                      this guide
                    </a>
                    .
                  </p>
                }
              />
            </div>
          ) : (
            <span css={{color: color.grey_400, wordBreak: 'break-word'}}>
              {participantDeviceType ? `${participantDeviceType} (${participantDeviceFirmware ?? '--'})` : '--'}
            </span>
          )}
        </div>

        {/* Note */}
        <div
          onClick={handleClick}
          css={{
            width: canUpdateParticipant ? '14%' : '20%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: pad.medium,
            pointerEvents: formHeader ? 'none' : 'auto',
            ':hover': {
              cursor: formHeader ? 'auto' : 'pointer',
            },
          }}
        >
          {RIF(formHeader, 'Note')}
          {RIF(
            !formHeader,
            <div css={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <span
                css={{
                  width: '75%',
                  wordBreak: 'break-word',
                  color: participant?.note ? color.black : color.primary,
                }}
              >
                {participant?.note ? participant?.note : canUpdateParticipant ? 'Add Note' : ''}
              </span>
              {RIF(
                canUpdateParticipant && hovering,
                <span css={{marginRight: '20px', display: 'flex', alignItems: 'center', minWidth: 'fit-content'}}>
                  <img src={EditIcon} width='18' />
                  <span css={{color: color.primary, marginLeft: '6px'}}>Edit</span>
                </span>,
              )}
            </div>,
          )}
        </div>
      </div>
    </>
  )
}
