import {RIF} from '../../lib'
import {selectTheme} from '../../store'
import {Tooltip, AdherenceOverviewCard, AdherenceOverviewDataType, ProjectAdherenceOverviewProp} from '..'

export const DexcomAdherenceCard = (props: ProjectAdherenceOverviewProp) => {
  const {color} = selectTheme()
  const weekCardProps = {
    ...props,
    title: 'Dexcom Data Collection',
    hintDescription: '',
    dataType: AdherenceOverviewDataType.DexcomEGV,
    mainColor: color.task.green,
  }

  return <AdherenceOverviewCard {...weekCardProps} />
}
