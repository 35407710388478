import {useState, useEffect} from 'react'
import {Controller, FieldErrorsImpl} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {selectTheme, createDispatchActions} from '../../store'
import {v, debug, useForm, useTranslation, RequestResult} from '../../lib'
import {LabelBox, Legend, Input, Button, Checkbox} from '..'
import {v4} from 'uuid'

const errorUUID = {
  uppercase: v4(),
  lowercase: v4(),
  number: v4(),
  // space: v4(),
  invalid: v4(),
}

const schema = v.object({
  email: v
    .string()
    .email({tlds: {allow: false}})
    .required(),
  password: v
    .string()
    .min(8)
    .pattern(/[A-Z]/, errorUUID.uppercase) // At least one uppercase letter
    .pattern(/[a-z]/, errorUUID.lowercase) // At least one lowercase letter
    .pattern(/\d/, errorUUID.number) // At least one number
    // .pattern(/^\S*$/, errorUUID.space) // No spaces
    .pattern(/^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/, errorUUID.invalid) // ASCII only
    .required(),
  termAgreement: v.boolean().not(false),
})
export interface SignupFormProps {
  defaultEmail?: string
  defaultPassword?: string
  defaultTermAgreement?: boolean
}

export const SignupForm = (props: SignupFormProps) => {
  const {t} = useTranslation()
  const {pad, color} = selectTheme()

  /* ------------------ req ------------------ */
  const {doREQUEST_IDENTITY_CREATE}: any = createDispatchActions()
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)

  /* ------------------ basic state ------------------ */
  const navigate = useNavigate()
  const [inputCompleted, setInputComplete] = useState(false)

  // warning message
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>('')
  const [passwordErrorMsg, setPasswordErrorMsg] = useState<string>('')

  const {register, control, handleSubmit, formState, watch} = useForm({schema})
  const formValues = watch()

  useEffect(() => {
    if (formValues.email?.length && formValues.password?.length > 7 && formValues.termAgreement) {
      setInputComplete(true)
    } else {
      setInputComplete(false)
    }
  }, [formValues])

  const onSubmit = (data: any) => {
    doREQUEST_IDENTITY_CREATE({
      setRequestResult,
      payload: data,
    })
  }

  // handle form validation
  const onError = (error: FieldErrorsImpl<{[x: string]: any}>) => {
    if (!error) return
    debug('SignupForm.onError', {error})
    setEmailErrorMsg('')
    setPasswordErrorMsg('')
    if (error.email) {
      const emailError = error.email
      switch (emailError.type) {
        case 'string.required':
          return setEmailErrorMsg('Email is Required')
        case 'string.email':
          return setEmailErrorMsg('Please enter a valid email address.')
        default:
          return setEmailErrorMsg('Please enter a valid email address.')
      }
    } else if (error.password) {
      const passwordError = error.password
      switch (passwordError.type) {
        case 'string.required':
          return setPasswordErrorMsg('Password is Required')
        case 'string.min':
          return setPasswordErrorMsg('Must be at least 8 characters long.')
        case 'string.pattern.name':
          if (passwordError.message?.toString().includes(errorUUID.uppercase))
            return setPasswordErrorMsg('Password must include a uppercase letter.')
          if (passwordError.message?.toString().includes(errorUUID.lowercase))
            return setPasswordErrorMsg('Password must include a lowercase letter.')
          if (passwordError.message?.toString().includes(errorUUID.number))
            return setPasswordErrorMsg('Password must include a number.')
          // if (passwordError.message?.toString().includes(errorUUID.space))
          //   return setPasswordErrorMsg('Password can not contain spaces.')
          if (passwordError.message?.toString().includes(errorUUID.invalid))
            return setPasswordErrorMsg('Password contains invalid characters.')
          return setPasswordErrorMsg('Password does not meet the requirements.')
        default:
          return setPasswordErrorMsg('Password does not meet the requirements.')
      }
    }
  }

  useEffect(() => {
    if (!requestResult) return
    if (requestResult?.result.statusCode === 500) return alert('Oops! something is wrong with our server')
    if (requestResult?.result.statusCode === 400) return setEmailErrorMsg('Please enter a valid email address.')
    if (requestResult?.result.statusCode === 410) return setEmailErrorMsg('Email already exists')
    if (requestResult?.result.statusCode === 200) return navigate(`/account_setup?${watch('email')}`)
  }, [requestResult])

  return (
    <div css={{width: '386px'}}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Legend css={{marginBottom: '16px', fontSize: '20px'}}>{t('sign_up.sign_up')}</Legend>
        <div css={{marginBottom: '40px'}}>
          Already have an account?&nbsp;
          <a onClick={() => navigate('/')} css={{color: color.primary, cursor: 'pointer'}}>
            Sign in
          </a>
        </div>
        <LabelBox
          {...{
            label: t('sign_up.form_field_email_label'),
            warningMsg: emailErrorMsg,
          }}
        >
          <Input
            {...register('email')}
            css={{marginBottom: '25px', marginTop: '5px'}}
            defaultValue={props.defaultEmail}
            type='email'
            placeholder={t('sign_up.form_field_email_placeholder')}
          />
        </LabelBox>
        <LabelBox
          {...{
            label: t('sign_up.form_field_password_label'),
            warningMsg: passwordErrorMsg,
          }}
        >
          <>
            <Input
              {...register('password')}
              css={{marginBottom: pad.medium, marginTop: '5px'}}
              defaultValue={props.defaultPassword}
              type='password'
              placeholder={t('sign_up.form_field_password_placeholder')}
            />
            <span css={{color: color.grey_400}}>
              8+ characters. Must include uppercase, lowercase, and a number, with no spaces.
            </span>
          </>
        </LabelBox>
        <label
          css={{
            marginBottom: pad.medium,
            marginTop: '30px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Controller
            control={control}
            defaultValue={props.defaultTermAgreement || false}
            name='termAgreement'
            render={({field: {name, value, onChange}}) => (
              <Checkbox
                {...{
                  name: name,
                  value: value,
                  onChange: onChange,
                }}
              />
            )}
          />
          <span css={{fontSize: '13px', marginLeft: '5px', cursor: 'pointer'}}>
            I Agree to the&nbsp;
            <a
              css={{fontSize: '13px', color: color.primary, textDecoration: 'underline'}}
              href='https://help.labfront.com/terms-of-service'
            >
              Terms and Conditions
            </a>{' '}
            and&nbsp;
            <a
              css={{fontSize: '13px', color: color.primary, textDecoration: 'underline'}}
              href='https://help.labfront.com/privacy-policy'
            >
              Privacy Policy
            </a>
          </span>
        </label>
        <Button type='submit' disabled={!inputCompleted} css={{marginTop: '35px'}}>
          {t('sign_up.sign_up')}
        </Button>
      </form>
    </div>
  )
}
