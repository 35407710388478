import {useEffect, useState, Dispatch, SetStateAction} from 'react'
import {debug, RequestResult} from '../../lib'
import {createDispatchActions} from '../../store'
import {PopupSimple} from '..'
import {IParticipant} from '../../shared/db'
export interface ParticipantsRegenerateCodePopPropsT {
  participant?: IParticipant
  closeAction: Dispatch<SetStateAction<boolean>>
  setRegenerateCode: Dispatch<SetStateAction<string | null>>
}

export const ParticipantRegenerateCodePop = (props: ParticipantsRegenerateCodePopPropsT) => {
  const {closeAction, participant, setRegenerateCode} = props

  const {doREQUEST_PARTICIPANT_CREDENTIALS_REGENERATE}: any = createDispatchActions()
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)

  const handleSubmit = (data: any) => {
    debug('ParticipantRegenerateCodePop.onSubmit', {data})
    doREQUEST_PARTICIPANT_CREDENTIALS_REGENERATE({
      setRequestResult,
      payload: {
        participantId: participant?.id,
      },
    })
  }

  useEffect(() => {
    if (!requestResult?.success) return
    // setRegenerateCode(requestComplete?.payload?.payload?.credentials?.loginCode)
    setRegenerateCode(requestResult?.result.payload?.credentials?.loginCode)
    closeAction(false)
  }, [requestResult])

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 102,
      }}
      data-testid='test_participant_regenerate_pop'
    >
      <PopupSimple
        {...props}
        {...{
          method: 'Regenerate',
          name: 'Invite Code',
          description:
            'If a participant accidentally logs out or gets a new phone, regenerating a new code will render the previous code invalid.',
          buttonColor: 'primary',
          buttonText: 'Regenerate Code',
          closeAction,
          doAction: handleSubmit,
        }}
      />
    </div>
  )
}
