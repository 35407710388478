import { TaskType } from '../db'

export type TabularTaskType =
  | TabularDexcomRecordType
  | TabularGarminConnectRecordType
  | TabularBasicTaskType
  | TabularStopwatchTaskType
  | ExtendRecordType

export enum TabularDexcomRecordType {
  Alert = 'dexcom_alert',
  Calibration = 'dexcom_calibration',
  EGV = 'dexcom_egv',
  Event = 'dexcom_event',
}

export enum TabularGarminConnectRecordType {
  DailySummary = 'garmin_connect_daily_summary',
  DailyHeartRate = 'garmin_connect_daily_heart_rate',
  ThirdPartyDailySummary = 'garmin_connect_third_party_daily_summary',
  ThirdPartyDailyHeartRate = 'garmin_connect_third_party_daily_heart_rate',
  Epoch = 'garmin_connect_epoch',
  SleepSummary = 'garmin_connect_sleep_summary',
  SleepStage = 'garmin_connect_sleep_stage',
  SleepRespiration = 'garmin_connect_sleep_respiration',
  SleepPulseOx = 'garmin_connect_sleep_pulse_ox',
  BodyComposition = 'garmin_connect_body_composition',
  Stress = 'garmin_connect_stress',
  UserMetrics = 'garmin_connect_user_metrics',
  PulseOx = 'garmin_connect_pulse_ox',
  Respiration = 'garmin_connect_respiration',
  HealthSnapshotSummary = 'garmin_connect_health_snapshot_summary',
  HealthSnapshotHeartRate = 'garmin_connect_health_snapshot_heart_rate',
  HealthSnapshotStress = 'garmin_connect_health_snapshot_stress',
  HealthSnapshotPulseOx = 'garmin_connect_health_snapshot_pulse_ox',
  HealthSnapshotRespiration = 'garmin_connect_health_snapshot_respiration',
  HRVSummary = 'garmin_connect_hrv_summary',
  HRVValues = 'garmin_connect_hrv_values',
  BloodPressure = 'garmin_connect_blood_pressure',
  ActivitySummary = 'garmin_connect_activity_summary',
  ActivityManuallyUpdatedSummary = 'garmin_connect_activity_manually_updated_summary',
  ActivityDetailsSummary = 'garmin_connect_activity_details_summary',
  ActivityDetailsSample = 'garmin_connect_activity_details_sample',
  ActivityDetailsLap = 'garmin_connect_activity_details_lap',
  ActivityFiles = 'garmin_connect_activity_files',
  ActivityMoveIQSummary = 'garmin_connect_activity_move_iq_summary',
  WomenMCTSummary = 'garmin_connect_women_mct_summary',
  WomenMCTBloodGlucose = 'garmin_connect_women_mct_blood_glucose',
}

export type TabularBasicTaskType = Exclude<
  TaskType,
  | TaskType.TaskSet
  | TaskType.StopwatchGarminStream
  | TaskType.StopwatchMovesenseStream
  | TaskType.StopwatchMovesenseLogData
>

export enum TabularStopwatchTaskType {
  GarminStream = 'garmin_stream',
  MovesenseStream = 'movesense_stream',
}

export enum ExtendRecordType {
  Analysis = 'analysis',
}

export type TabularDataType =
  | TabularGarminDeviceDataType
  | TabularGarminDeviceStreamDataType
  | TabularMovesenseDeviceStreamDataType
  | StopwatchEventDataType
  | TabularAnalysisDataType

export type StopwatchEventDataType = 'stopwatch_event'

export enum TabularGarminDeviceDataType {
  BBI = 'bbi',
  EnhancedBBI = 'enhanced_bbi',
  Step = 'step',
  PulseOx = 'pulse_ox',
  Acc = 'acc',
  HeartRate = 'heart_rate',
  Respiration = 'respiration',
  Stress = 'stress',
  ZeroCrossing = 'zero_crossing',
  Actigraphy = 'actigraphy',
  Actigraphy2 = 'actigraphy2',
  Actigraphy3 = 'actigraphy3',
  Temperature = 'temperature',
}

export enum TabularGarminDeviceStreamDataType {
  Acc = 'acc',
}

export enum TabularMovesenseDeviceStreamDataType {
  Imu = 'imu',
  Acc = 'acc',
  Gyroscope = 'gyroscope',
  Magnetometer = 'magnetometer',
  Ecg = 'ecg',
  Rr = 'rr',
}

export enum TabularAnalysisDataType {
  HRV = 'hrv',
  ActigraphySleep = 'actigraphy_sleep',
}
