import {useState, useEffect, useRef, Dispatch, SetStateAction} from 'react'
import {selectTheme, selectProfile, selectWorkspaces, selectProjectData} from '../../store'
import {RIF, useClickOutside, sortBy, _, useCurrentWorkspaceState, useCurrentProjectState} from '../../lib'
import {CollaboratorProfileImage, ButtonMore} from '..'
import {ProfileImageDefault} from '../../asset/image'
import {Collaborator} from '../../model'

export interface CollaboratorEntryProps {
  index?: string
  collaborator?: Collaborator
  setRemovingCollaborator?: Dispatch<SetStateAction<any>>
  setShowingManageCollaboratorPopup?: Dispatch<SetStateAction<boolean>>
  setEditingCollaborator?: Dispatch<SetStateAction<Collaborator | null>>
}

export const CollaboratorEntry = (props: CollaboratorEntryProps) => {
  const {index, collaborator, setRemovingCollaborator, setShowingManageCollaboratorPopup, setEditingCollaborator} =
    props
  // const {doREQUEST_WORKSPACE_COLLABORATOR_UPDATE}: any = createDispatchActions()
  const {color, fontWeight} = selectTheme()
  const [isShowingDropdown, setIsShowingDropdown] = useState(false)
  const ref = useRef(null)
  useClickOutside(ref, () => {
    setIsShowingDropdown(false)
  })

  const userProfile = selectProfile()
  const workspacesState = selectWorkspaces()
  const {workspaceId} = useCurrentWorkspaceState()
  const userPermission = workspacesState.roleInWorkspaces[workspaceId ?? ''] || 'owner'
  const {projectId} = useCurrentProjectState()
  const projectData = selectProjectData()
  const [projectList, setProjectList] = useState<any[]>([])

  useEffect(() => {
    if (workspaceId && !!Object.values(projectData)?.length) {
      const listWithoutDeletedProject = Object.values(projectData).filter((item: any) => item.status !== 'deleted')
      const listOfProjectInCurrentWorkspace = listWithoutDeletedProject
        .filter((item: any) => item.workspaceId === workspaceId)
        .sort(sortBy('createdAt'))
        .reverse()
      setProjectList([...listOfProjectInCurrentWorkspace])
      if (projectId) {
        setProjectList((prev) => prev.filter((p) => p.id === projectId))
      }
    }
  }, [projectData, workspaceId, location])

  const [showMoreBtn, setShowMoreBtn] = useState(false)
  useEffect(() => {
    if (!collaborator?.profile?.email) return
    if (userProfile.email === collaborator.profile.email) setShowMoreBtn(true)
    if (
      !['viewer', 'editor', 'projectCollaborator'].includes(userPermission) &&
      permissionLevelTranslator[userPermission] >= permissionLevelTranslator[collaborator?.type]
    )
      setShowMoreBtn(true)
  }, [userProfile, collaborator])

  const [showEditPermissionBtn, setShowEditPermissionBtn] = useState(false)
  useEffect(() => {
    if (!userProfile || !collaborator || !userPermission) return
    const userIsNotTheCollaborator = userProfile.email !== collaborator?.profile?.email
    const userIsNotViewerOrEditor = ['owner', 'admin', 'project_collaborator'].includes(userPermission)
    const userHasHigherPermissionThanTarget =
      permissionLevelTranslator[userPermission] >= permissionLevelTranslator[collaborator?.type || '']
    const isInProjectPage = !!projectId
    setShowEditPermissionBtn(
      (userIsNotTheCollaborator && userIsNotViewerOrEditor && userHasHigherPermissionThanTarget) ||
        (userIsNotTheCollaborator &&
          isInProjectPage &&
          collaborator.type === 'project_collaborator' &&
          _.find(collaborator.projectCollaboratorList, ['projectId', projectId])?.type === 'projectAdmin'),
    )
  }, [userProfile, collaborator, userPermission])

  const permissionLevelTranslator: {
    [key: string]: number
  } = {
    owner: 4,
    admin: 3,
    editor: 2,
    viewer: 1,
    project_collaborator: 0,
  }

  const handleClickRemove = () => {
    if (setRemovingCollaborator && collaborator) setRemovingCollaborator(collaborator)
  }

  const generateCollaboratorListString = (collaboratorList: {projectId: string; type: string}[]) => {
    if (projectId) {
      return _.find(collaboratorList, ['projectId', projectId])?.type.replace('project', '')
    }
    return collaboratorList
      .map((c) => {
        return `(${c.type.replace('project', '')}) ${_.find(projectList, ['id', c.projectId])?.name}`
      })
      .join(', ')
  }

  const handleClickOnEditPermission = () => {
    if (setEditingCollaborator && collaborator) setEditingCollaborator(collaborator)
    if (setShowingManageCollaboratorPopup) setShowingManageCollaboratorPopup(true)
  }

  return (
    <div
      css={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderBottom: `.5px solid ${color.grey_100}`,
        position: 'relative',
        padding: '13px 15px',
      }}
    >
      <p
        css={{
          width: '4%',
          color: index ? color.black : color.textIcon.light,
        }}
      >
        {index ? index : '#'}
      </p>
      <div
        css={{
          width: '42.6%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {RIF(
          index,
          <CollaboratorProfileImage
            src={collaborator?.profile?.imageUrl ?? ProfileImageDefault}
            css={{
              width: '40px',
              height: '40px',
              marginRight: '16px',
            }}
          />,
        )}
        <div>
          <p
            css={{
              color: collaborator?.profile?.firstName ? color.black : color.textIcon.light,
            }}
          >
            {collaborator?.profile?.firstName
              ? `${collaborator?.profile.firstName} ${collaborator?.profile.lastName}`
              : 'Name'}
          </p>
          {RIF(
            index,
            <p
              css={{
                marginTop: '3px',
                color: color.grey_400,
              }}
            >
              {collaborator?.profile?.email}
            </p>,
          )}
        </div>
      </div>
      <div
        css={{
          width: '53.4%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* Show "Permission" for column */}
          <p
            css={{
              color: collaborator ? color.black : color.textIcon.light,
            }}
          >
            {collaborator?.type
              ? collaborator.type === 'project_collaborator'
                ? generateCollaboratorListString(collaborator.projectCollaboratorList || [])
                : collaborator.type.replace(/^\w/, (c) => c.toUpperCase())
              : 'Permission'}
          </p>
        </div>
        {RIF(
          index && (collaborator?.type === 'project_collaborator' || index !== '01'),
          <div
            ref={ref}
            css={{
              position: 'relative',
              display: 'flex',
            }}
          >
            {/* show edit permission button for all ohter collaborators that has equal or lower level than user */}
            {RIF(
              showEditPermissionBtn,
              <button
                onClick={handleClickOnEditPermission}
                css={{
                  color: color.textIcon.secondary,
                  padding: '3px 8px',
                  border: '1px solid',
                  borderColor: color.border._160,
                  borderRadius: '3px',
                  cursor: 'pointer',
                  backgroundColor: 'transparent',
                  marginRight: '16px',
                  ':hover': {
                    backgroundColor: color.surface.hover.white,
                    borderColor: color.border._400,
                  },
                }}
              >
                Edit Permission
              </button>,
            )}
            {/* Show more button for user's own entry and for all collaborators that has lower permission than user */}
            {RIF(showMoreBtn, <ButtonMore onClick={() => setIsShowingDropdown((oldState) => !oldState)} />)}
            {RIF(
              isShowingDropdown,
              <div
                css={{
                  backgroundColor: color.white,
                  boxShadow: '0px 4px 10px 1px rgba(104, 104, 104, 0.25)',
                  borderRadius: '5px',
                  border: `1px solid ${color.disabled}`,
                  position: 'absolute',
                  top: '26px',
                  right: '-20px',
                  zIndex: 99999,
                }}
              >
                <button
                  onClick={handleClickRemove}
                  css={{
                    background: 'transparent',
                    width: '100%',
                    padding: '13px 17px',
                    border: 'none',
                    fontWeight: fontWeight.thick,
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    ':hover': {
                      color: color.primary,
                    },
                    ':focus': {outline: 'none'},
                  }}
                >
                  {collaborator?.profile?.email === userProfile.email ? 'Leave workspace' : 'Remove'}
                </button>
              </div>,
            )}
          </div>,
        )}
      </div>
    </div>
  )
}
