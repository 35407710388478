import {cloneDeep} from 'lodash'
import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'
import {Adherence, AdherenceGarminDirect, DigestDay, ParticipantDigestDay} from '../../../model/adherence'
import {GarminDeviceLogDataType} from '../../../shared/mongo'

export enum AdherenceActionType {
  ADHERENCE_SET = 'ADHERENCE_SET',
  ADHERENCE_DELETE = 'ADHERENCE_DELETE',
  ADHERENCE_PROJECT_PARTICIPANT_LIST_SET = 'ADHERENCE_PROJECT_PARTICIPANT_LIST_SET',
  ADHERENCE_PROJECT_DATA_DIGEST_SET = 'ADHERENCE_PROJECT_DATA_DIGEST_SET',
  ADHERENCE_DIGEST_DAY_DETAIL_SET = 'ADHERENCE_DIGEST_DAY_DETAIL_SET',
  ADHERENCE_CURRENT_SIDEBAR_CONTENT_INDEX_SET = 'ADHERENCE_CURRENT_SIDEBAR_CONTENT_INDEX_SET',
  ADHERENCE_GARMIN_DIRECT_BBI_DIGEST_SET = 'ADHERENCE_GARMIN_DIRECT_BBI_DIGEST_SET',
  ADHERENCE_GARMIN_CONNECT_WEAR_TIME_DATA_SET = 'ADHERENCE_GARMIN_CONNECT_WEAR_TIME_DATA_SET',
}

export const doADHERENCE_SET = createAction(AdherenceActionType.ADHERENCE_SET)
export const doADHERENCE_DELETE = createAction(AdherenceActionType.ADHERENCE_DELETE)
export const doADHERENCE_PROJECT_PARTICIPANT_LIST_SET = createAction(
  AdherenceActionType.ADHERENCE_PROJECT_PARTICIPANT_LIST_SET,
)
export const doADHERENCE_PROJECT_DATA_DIGEST_SET = createAction(AdherenceActionType.ADHERENCE_PROJECT_DATA_DIGEST_SET)
export const doADHERENCE_DIGEST_DAY_DETAIL_SET = createAction(AdherenceActionType.ADHERENCE_DIGEST_DAY_DETAIL_SET)
export const doADHERENCE_CURRENT_SIDEBAR_CONTENT_INDEX_SET = createAction(
  AdherenceActionType.ADHERENCE_CURRENT_SIDEBAR_CONTENT_INDEX_SET,
)
export const doADHERENCE_GARMIN_DIRECT_BBI_DIGEST_SET = createAction(
  AdherenceActionType.ADHERENCE_GARMIN_DIRECT_BBI_DIGEST_SET,
)
export const doADHERENCE_GARMIN_CONNECT_WERA_TIME_DATA_SET = createAction(
  AdherenceActionType.ADHERENCE_GARMIN_CONNECT_WEAR_TIME_DATA_SET,
)

interface AdherenceState {
  [projectId: string]: ProjectAdherence
}

export interface ProjectAdherence {
  adherenceList?: Adherence[]
  projectDataDigest?: ProjectDataDigest
  garminTaskId?: string
  lastDigestDayDetailUpdatedTime?: number
  currentSidebarContentIndex?: SidebarContentIndex
}

export interface ProjectDataDigest {
  projectId: string
  coveredYYMMIndex: number[]
  coveredGarminDirectBbiYymmddIndexList?: number[]
  coveredGarminConnectWearTimeDataYymmddIndexList?: number[]
  participantDataDigestMap: ParticipantDataDigestMap
}

export type ParticipantDataDigestMap = Record<string, ParticipantDataDigest>

export type BbiDataDigestDateMap = Record<string, GarminBbiDigest>
export type ParticipantBbiDataDigestMap = Record<string, BbiDataDigestDateMap>
export type GarminConnectWearTimeDateMap = Record<string, number>
export type ParticipantGarminConnectWearTimeMap = Record<string, GarminConnectWearTimeDateMap>

export interface GarminBbiDigest {
  wearTime: number
}

interface ParticipantDataDigest {
  taskDataDates?: Record<string, number[]>
  taskCompletionDateCountMap?: Record<string, Record<string, number>>
  garminDeviceDataDates?: number[]
  garminConnectDataDates?: number[]
  dexcomDataDates?: number[]
  garminDeviceDataDatesByType?: Record<string, number[]>
  garminDirectBbiDataDigest?: BbiDataDigestDateMap
  garminConnectWearTimeDateMap?: GarminConnectWearTimeDateMap
}

type GarminAdherenceKey = keyof AdherenceGarminDirect

interface ProjectBbiDataDigest {
  projectId: string
  queryYymmddIndexList: number[]
  participantBbiDataDigestMap: ParticipantBbiDataDigestMap
}

interface ProjectGarminConnectWearTime {
  projectId: string
  queryYymmddIndexList: number[]
  participantGarminConnectWearTimeMap: ParticipantGarminConnectWearTimeMap
}

export interface SidebarContentIndex {
  participantId: string
  yymmddIndex: number
}

interface RootState {
  adherence: AdherenceState
}

/* selector */
export const selectAdherenceData = () => {
  return useSelector((state: RootState) => state.adherence)
}

export const adherenceActionCreators = {
  doADHERENCE_SET,
  doADHERENCE_DELETE,
  doADHERENCE_PROJECT_PARTICIPANT_LIST_SET,
  doADHERENCE_PROJECT_DATA_DIGEST_SET,
  doADHERENCE_DIGEST_DAY_DETAIL_SET,
  doADHERENCE_CURRENT_SIDEBAR_CONTENT_INDEX_SET,
  doADHERENCE_GARMIN_DIRECT_BBI_DIGEST_SET,
  doADHERENCE_GARMIN_CONNECT_WERA_TIME_DATA_SET,
}

export const adherenceDefaultState: AdherenceState = {}

type Action =
  | {
      type: AdherenceActionType.ADHERENCE_SET
      payload: {
        projectId: string
        batchId: string
        startDate: Date | string
        endDate: Date | string
        batchAdherenceList: Adherence[]
      }
    }
  | {
      type: AdherenceActionType.ADHERENCE_DELETE
      payload: {
        projectId: string
      }
    }
  | {
      type: AdherenceActionType.ADHERENCE_PROJECT_PARTICIPANT_LIST_SET
      payload: {
        projectId: string
        projectParticipantList: Adherence[]
      }
    }
  | {
      type: AdherenceActionType.ADHERENCE_PROJECT_DATA_DIGEST_SET
      payload: {
        garminTaskId: string | undefined
        projectDataDigest: {
          projectId: string
          coveredYYMMIndex: number[]
          participantDataDigestMap: {
            [participantId: string]: any
          }
        }
      }
    }
  | {
      type: AdherenceActionType.ADHERENCE_DIGEST_DAY_DETAIL_SET
      payload: {
        projectId: string
        participantDigestDay: ParticipantDigestDay
      }
    }
  | {
      type: AdherenceActionType.ADHERENCE_CURRENT_SIDEBAR_CONTENT_INDEX_SET
      payload: {
        projectId: string
        sidebarContentIndex: SidebarContentIndex
      }
    }
  | {
      type: AdherenceActionType.ADHERENCE_GARMIN_DIRECT_BBI_DIGEST_SET
      payload: {
        projectBbiDataDigest: ProjectBbiDataDigest
      }
    }
  | {
      type: AdherenceActionType.ADHERENCE_GARMIN_CONNECT_WEAR_TIME_DATA_SET
      payload: {
        projectGarminConnectWearTime: ProjectGarminConnectWearTime
      }
    }

export const adherenceReducer = (state = {...adherenceDefaultState}, {type, payload}: Action): AdherenceState => {
  const newState = cloneDeep(state)
  switch (type) {
    case AdherenceActionType.ADHERENCE_PROJECT_DATA_DIGEST_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          garminTaskId: v.string().optional(),
          projectDataDigest: v.object({
            projectId: v.string().exist(),
            coveredYYMMIndex: v.array().items(v.number()).exist(),
            participantDataDigestMap: v.object().exist(),
          }),
        }),
      })
      return updateProjectDataDigestInState(state, payload.projectDataDigest, payload.garminTaskId)

    case AdherenceActionType.ADHERENCE_PROJECT_PARTICIPANT_LIST_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          projectId: v.string().uuid().exist(),
          projectParticipantList: v.array(),
        }),
      })

      if (payload?.projectParticipantList) {
        if (newState[payload.projectId]) {
          newState[payload.projectId].adherenceList = payload.projectParticipantList
        } else {
          newState[payload.projectId] = {
            adherenceList: payload.projectParticipantList,
          }
        }
      }
      return newState

    case AdherenceActionType.ADHERENCE_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          projectId: v.string().uuid().exist(),
          batchId: v.string().uuid().exist(),
          startDate: v.date(),
          endDate: v.date(),
          batchAdherenceList: v.array(),
        }),
      })

      if (payload?.batchAdherenceList) {
        if (newState[payload.projectId]) {
          newState[payload.projectId].adherenceList = payload.batchAdherenceList
        } else {
          newState[payload.projectId] = {
            adherenceList: payload.batchAdherenceList,
          }
        }
      }
      return newState

    case AdherenceActionType.ADHERENCE_DELETE:
      assertPartialSchema({
        payload,
        schema: v.object({
          projectId: v.string().uuid().exist(),
        }),
      })
      delete newState[payload.projectId]
      return newState

    case AdherenceActionType.ADHERENCE_CURRENT_SIDEBAR_CONTENT_INDEX_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          projectId: v.string().uuid().exist(),
          sidebarContentIndex: v.object({
            participantId: v.string().exist(),
            yymmddIndex: v.number().exist(),
          }),
        }),
      })
      newState[payload.projectId].currentSidebarContentIndex = payload.sidebarContentIndex
      return newState

    case AdherenceActionType.ADHERENCE_DIGEST_DAY_DETAIL_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          projectId: v.string().exist(),
          participantDigestDay: v.object({
            day: v.string().exist(),
            dayDate: v.string().exist(),
            dayUnixTimestamp: v.number(),
            participantId: v.string().uuid().exist(),
            garminConnectSynced: v.boolean(),
            garminConnect: v.object(),
            task: v.object(),
            dexcomDataCount: v.number(),
          }),
        }),
      })
      return updateProjectDataDigestDayDetailInState({...state}, payload.projectId, payload.participantDigestDay)

    case AdherenceActionType.ADHERENCE_GARMIN_DIRECT_BBI_DIGEST_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          projectBbiDataDigest: v.object({
            projectId: v.string().exist(),
            queryYymmddIndexList: v.array().items(v.number()).required(),
            participantBbiDataDigestMap: v.object().exist(),
          }),
        }),
      })

      return updateProjectBbiDataDigestInState(cloneDeep(state), payload.projectBbiDataDigest)

    case AdherenceActionType.ADHERENCE_GARMIN_CONNECT_WEAR_TIME_DATA_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          projectGarminConnectWearTime: v.object({
            projectId: v.string().exist(),
            queryYymmddIndexList: v.array().items(v.number()).required(),
            participantGarminConnectWearTimeMap: v.object().exist(),
          }),
        }),
      })

      return updateProjectGarminConnectWearTimeInState(cloneDeep(state), payload.projectGarminConnectWearTime)

    default:
      return state
  }
}

function updateProjectDataDigestInState(
  state: AdherenceState,
  inputProjectDataDigest: ProjectDataDigest,
  garminTaskId?: string,
) {
  const projectId = inputProjectDataDigest.projectId
  let projectDataDigest = state[projectId]?.projectDataDigest

  if (projectDataDigest) {
    if (!isSubset(projectDataDigest.coveredYYMMIndex, inputProjectDataDigest.coveredYYMMIndex)) {
      projectDataDigest.coveredYYMMIndex.push(...inputProjectDataDigest.coveredYYMMIndex)
      projectDataDigest.participantDataDigestMap = mergeParticipantDataDigestMap(
        projectDataDigest.participantDataDigestMap,
        inputProjectDataDigest.participantDataDigestMap,
      )
    }
  } else {
    const projectAdherence = state[projectId]
    if (projectAdherence) {
      projectAdherence.projectDataDigest = inputProjectDataDigest
    } else {
      state[projectId] = {
        projectDataDigest: inputProjectDataDigest,
      }
    }
    projectDataDigest = inputProjectDataDigest
  }

  const adherenceList = state[projectId]?.adherenceList
  if (adherenceList && projectDataDigest) {
    state[projectId].adherenceList = insertDigest(adherenceList, projectDataDigest, garminTaskId)
  }
  return {...state}
}

const isSubset = (parent: number[], children: number[]) => {
  if (parent) {
    return children.every((item) => {
      return parent.includes(item)
    })
  }
  return false
}

function mergeParticipantDataDigestMap(
  d1: ParticipantDataDigestMap,
  d2: ParticipantDataDigestMap,
): ParticipantDataDigestMap {
  const merged: ParticipantDataDigestMap = {...d1}

  for (const key in d2) {
    if (key in merged) {
      merged[key].taskDataDates = mergeDataDatesRecord(merged[key].taskDataDates, d2[key].taskDataDates)
      merged[key].taskCompletionDateCountMap = mergeDatesDataCountMap(
        merged[key].taskCompletionDateCountMap,
        d2[key].taskCompletionDateCountMap,
      )
      merged[key].garminDeviceDataDatesByType = mergeDataDatesRecord(
        merged[key].garminDeviceDataDatesByType,
        d2[key].garminDeviceDataDatesByType,
      )
      merged[key].garminDeviceDataDates = mergeDataDates(
        merged[key].garminDeviceDataDates,
        d2[key].garminDeviceDataDates,
      )
      merged[key].garminConnectDataDates = mergeDataDates(
        merged[key].garminConnectDataDates,
        d2[key].garminConnectDataDates,
      )
      merged[key].dexcomDataDates = mergeDataDates(merged[key].dexcomDataDates, d2[key].dexcomDataDates)
    } else {
      merged[key] = d2[key]
    }
  }

  return merged
}

function mergeDataDatesRecord(
  d1?: Record<string, number[]>,
  d2?: Record<string, number[]>,
): Record<string, number[]> | undefined {
  if (d1 && d2) {
    const merged: Record<string, number[]> = {...d1}
    for (const key in d2) {
      if (key in merged) {
        const mergedDates = mergeDataDates(merged[key], d2[key])
        if (mergedDates) {
          merged[key] = mergedDates
        }
      } else {
        merged[key] = d2[key]
      }
    }
    return merged
  } else {
    return d1 || d2
  }
}

function mergeDataDates(d1?: number[], d2?: number[]): number[] | undefined {
  if (d1 && d2) {
    const mergedSet = new Set([...d1, ...d2])
    return Array.from(mergedSet)
  } else {
    return d1 || d2
  }
}

function mergeDatesDataCountMap(
  d1?: Record<string, Record<string, number>>,
  d2?: Record<string, Record<string, number>>,
): Record<string, Record<string, number>> | undefined {
  if (d1 && d2) {
    const merged: Record<string, Record<string, number>> = {...d1}
    for (const key in d2) {
      if (key in merged) {
        merged[key] = {
          ...merged[key],
          ...d2[key],
        }
      } else {
        merged[key] = d2[key]
      }
    }
    return merged
  } else {
    return d1 || d2
  }
}

function insertDigest(
  adherenceList: Adherence[],
  projectDataDigest: ProjectDataDigest,
  garminTaskId?: string,
): Adherence[] {
  const updateDigestDayMapOnTask = (
    digestDayMap: Record<number, DigestDay>,
    yymmddList: number[],
    taskId: string,
    taskType?: string,
  ) => {
    yymmddList.forEach((yymmdd) => {
      const taskData = {
        taskType: taskType,
      }
      if (digestDayMap[yymmdd]) {
        if (digestDayMap[yymmdd].task) {
          digestDayMap[yymmdd].task = {...digestDayMap[yymmdd].task, [taskId]: taskData}
        } else {
          digestDayMap[yymmdd].task = {[taskId]: taskData}
        }
      } else {
        const newDigestDay = getEmptyDigestDay(yymmdd)
        newDigestDay.task = {[taskId]: taskData}
        digestDayMap[yymmdd] = newDigestDay
      }
    })

    if (taskType === 'garmin_device') {
      yymmddList.forEach((yymmdd) => {
        digestDayMap[yymmdd].garminDirectCollected = true
      })
    }

    return digestDayMap
  }

  const updateDigestDayMapOnTaskCompletionCountMap = (
    digestDayMap: Record<number, DigestDay>,
    taskId: string,
    dateCompletionCountMap: Record<string, number>,
  ) => {
    for (const [yymmddString, completionCount] of Object.entries(dateCompletionCountMap)) {
      const yymmdd = +yymmddString
      const existingDigestDay = digestDayMap[yymmdd]
      const taskCompletionCountMap = existingDigestDay?.taskCompletionCountMap ?? {}

      digestDayMap[yymmdd] = {
        ...(existingDigestDay ?? getEmptyDigestDay(yymmdd)),
        taskCompletionCountMap: {
          ...taskCompletionCountMap,
          [taskId]: completionCount,
        },
      }
    }

    return digestDayMap
  }

  return adherenceList.map((adherence) => {
    const participantId = adherence.participantId
    const participantDigest = projectDataDigest.participantDataDigestMap[participantId]
    if (participantDigest) {
      let digestDayMap: Record<number, DigestDay> = {}

      if (participantDigest.taskDataDates) {
        for (const [taskId, yymmddList] of Object.entries(participantDigest.taskDataDates)) {
          digestDayMap = updateDigestDayMapOnTask(digestDayMap, yymmddList, taskId)
        }
      }

      if (participantDigest.taskCompletionDateCountMap) {
        for (const [taskId, dateCompletionCountMap] of Object.entries(participantDigest.taskCompletionDateCountMap)) {
          digestDayMap = updateDigestDayMapOnTaskCompletionCountMap(digestDayMap, taskId, dateCompletionCountMap)
        }
      }

      if (garminTaskId && participantDigest.garminDeviceDataDates) {
        digestDayMap = updateDigestDayMapOnTask(
          digestDayMap,
          participantDigest.garminDeviceDataDates,
          garminTaskId,
          'garmin_device',
        )
      }

      if (participantDigest.garminDeviceDataDatesByType) {
        for (const [dataType, dates] of Object.entries(participantDigest.garminDeviceDataDatesByType)) {
          const adherenceKey = convertGarminDataTypeToAdherenceKey(dataType)

          if (adherenceKey) {
            dates.forEach((yymmdd) => {
              if (digestDayMap[yymmdd]) {
                const garminDirectAdherence = digestDayMap[yymmdd].garminDirectAdherence
                if (garminDirectAdherence) {
                  garminDirectAdherence[adherenceKey] = true
                } else {
                  digestDayMap[yymmdd].garminDirectAdherence = {
                    [adherenceKey]: true,
                  }
                }
              } else {
                const newDigestDay = getEmptyDigestDay(yymmdd)
                newDigestDay.garminDirectAdherence = {
                  [adherenceKey]: true,
                }
                digestDayMap[yymmdd] = newDigestDay
              }
            })
          }
        }
      }

      if (participantDigest.garminConnectDataDates) {
        participantDigest.garminConnectDataDates.forEach((yymmdd) => {
          if (digestDayMap[yymmdd]) {
            digestDayMap[yymmdd].garminConnectSynced = true
          } else {
            const newDigestDay = getEmptyDigestDay(yymmdd)
            newDigestDay.garminConnectSynced = true
            digestDayMap[yymmdd] = newDigestDay
          }
        })
      }

      if (participantDigest.dexcomDataDates) {
        participantDigest.dexcomDataDates.forEach((yymmdd) => {
          if (digestDayMap[yymmdd]) {
            digestDayMap[yymmdd].dexcomSynced = true
          } else {
            const newDigestDay = getEmptyDigestDay(yymmdd)
            newDigestDay.dexcomSynced = true
            digestDayMap[yymmdd] = newDigestDay
          }
        })
      }
      adherence.digestDayList = Object.values(digestDayMap)
    }
    return adherence
  })
}

function getEmptyDigestDay(yymmddIndex: number): DigestDay {
  const yymmddDate = parseYYMMDDIndexToUtcDate(yymmddIndex)
  return {
    yymmddIndex: yymmddIndex,
    day: convertYYMMDDIndexToString(yymmddIndex),
    dayDate: yymmddDate.toISOString(),
    dayUnixTimestamp: yymmddDate.getTime(),
  }
}

function convertGarminDataTypeToAdherenceKey(dataTypeString: string): GarminAdherenceKey | undefined {
  const dataType = dataTypeString as GarminDeviceLogDataType
  switch (dataType) {
    case GarminDeviceLogDataType.GarminBBI:
      return 'bbi'
    case GarminDeviceLogDataType.GarminStep:
      return 'steps'
    case GarminDeviceLogDataType.GarminPulseOx:
      return 'pulseOx'
    case GarminDeviceLogDataType.GarminHeartRate:
      return 'hr'
    case GarminDeviceLogDataType.GarminRespiration:
      return 'respiration'
    case GarminDeviceLogDataType.GarminStress:
      return 'stress'
    case GarminDeviceLogDataType.GarminZeroCrossing:
      return 'zeroCrossing'
    case GarminDeviceLogDataType.GarminActigraphy:
      return 'actigraphy'
    case GarminDeviceLogDataType.GarminActigraphy2:
      return 'actigraphy'
    case GarminDeviceLogDataType.GarminActigraphy3:
      return 'actigraphy'
    case GarminDeviceLogDataType.GarminAcc:
      return 'acc'
    case GarminDeviceLogDataType.GarminTemperature:
      return 'temperature'
    default:
      return
  }
}

function parseYYMMDDIndexToUtcDate(yymmddIndex: number): Date {
  const year = 2000 + Math.trunc(yymmddIndex / 10000)
  const month = Math.trunc((yymmddIndex % 10000) / 100)
  const day = Math.trunc(yymmddIndex % 100)

  return new Date(Date.UTC(year, month - 1, day))
}

function convertYYMMDDIndexToString(yymmddIndex: number): string {
  const year = 2000 + Math.trunc(yymmddIndex / 10000)
  const month = Math.trunc((yymmddIndex % 10000) / 100)
    .toString()
    .padStart(2, '0')
  const day = Math.trunc(yymmddIndex % 100)
    .toString()
    .padStart(2, '0')
  return `${year}-${month}-${day}`
}

function updateProjectDataDigestDayDetailInState(
  state: AdherenceState,
  projectId: string,
  participantDigestDay: ParticipantDigestDay,
) {
  const projecctAdherenceList = state[projectId]?.adherenceList
  if (projecctAdherenceList) {
    for (const adherence of projecctAdherenceList) {
      if (adherence.participantId === participantDigestDay.participantId) {
        for (const digestDay of adherence.digestDayList) {
          if (digestDay.day === participantDigestDay.day) {
            digestDay.detailInfo = {
              task: participantDigestDay.task,
              garminConnect: participantDigestDay.garminConnect,
              dexcomDataCount: participantDigestDay.dexcomDataCount,
            }
            break
          }
        }
        break
      }
    }
    state[projectId].lastDigestDayDetailUpdatedTime = new Date().getTime()
    return {...state}
  }
  return state
}

function updateProjectBbiDataDigestInState(state: AdherenceState, projectBbiDataDigest: ProjectBbiDataDigest) {
  const {projectId, queryYymmddIndexList, participantBbiDataDigestMap} = projectBbiDataDigest

  if (!state[projectId]) {
    state[projectId] = {}
  }

  let projectDataDigest = state[projectId]?.projectDataDigest
  if (!projectDataDigest) {
    projectDataDigest = {
      projectId,
      coveredYYMMIndex: [],
      coveredGarminDirectBbiYymmddIndexList: [],
      participantDataDigestMap: {},
    }
  }

  if (projectDataDigest.coveredGarminDirectBbiYymmddIndexList == undefined) {
    projectDataDigest.coveredGarminDirectBbiYymmddIndexList = []
  }
  projectDataDigest.coveredGarminDirectBbiYymmddIndexList?.push(...queryYymmddIndexList)

  for (const [particiipnatId, bbiDataDigestMap] of Object.entries(participantBbiDataDigestMap)) {
    if (!projectDataDigest.participantDataDigestMap) {
      projectDataDigest.participantDataDigestMap = {}
    }

    if (!projectDataDigest.participantDataDigestMap[particiipnatId]) {
      projectDataDigest.participantDataDigestMap[particiipnatId] = {}
    }

    const participantDigestMap = projectDataDigest.participantDataDigestMap[particiipnatId]
    for (const [yymmddIndex, bbiDigest] of Object.entries(bbiDataDigestMap)) {
      if (!participantDigestMap.garminDirectBbiDataDigest) {
        participantDigestMap.garminDirectBbiDataDigest = {}
      }
      participantDigestMap.garminDirectBbiDataDigest[yymmddIndex] = bbiDigest
    }
  }

  state[projectId].projectDataDigest = projectDataDigest
  return state
}

function updateProjectGarminConnectWearTimeInState(
  state: AdherenceState,
  projectGarminConnectWearTime: ProjectGarminConnectWearTime,
) {
  const {projectId, queryYymmddIndexList, participantGarminConnectWearTimeMap} = projectGarminConnectWearTime

  if (!state[projectId]) {
    state[projectId] = {}
  }

  let projectDataDigest = state[projectId]?.projectDataDigest
  if (!projectDataDigest) {
    projectDataDigest = {
      projectId,
      coveredYYMMIndex: [],
      coveredGarminConnectWearTimeDataYymmddIndexList: [],
      participantDataDigestMap: {},
    }
  }

  if (projectDataDigest.coveredGarminConnectWearTimeDataYymmddIndexList == undefined) {
    projectDataDigest.coveredGarminConnectWearTimeDataYymmddIndexList = []
  }
  projectDataDigest.coveredGarminConnectWearTimeDataYymmddIndexList?.push(...queryYymmddIndexList)

  for (const [particiipnatId, wearTimeMap] of Object.entries(participantGarminConnectWearTimeMap)) {
    if (!projectDataDigest.participantDataDigestMap) {
      projectDataDigest.participantDataDigestMap = {}
    }

    if (!projectDataDigest.participantDataDigestMap[particiipnatId]) {
      projectDataDigest.participantDataDigestMap[particiipnatId] = {}
    }

    const participantDigestMap = projectDataDigest.participantDataDigestMap[particiipnatId]
    for (const [yymmddIndex, wearTime] of Object.entries(wearTimeMap)) {
      if (!participantDigestMap.garminConnectWearTimeDateMap) {
        participantDigestMap.garminConnectWearTimeDateMap = {}
      }
      participantDigestMap.garminConnectWearTimeDateMap[yymmddIndex] = wearTime
    }
  }

  state[projectId].projectDataDigest = projectDataDigest
  return state
}
