import {useEffect, useState, MutableRefObject} from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {RIF, t, _} from '../../lib'
import {selectTheme, createDispatchActions, selectQuestionnaires} from '../../store'
import {
  DragHandle,
  Select,
  ButtonText,
  SlideCheckbox,
  RequiredTag,
  QuestionnaireInput,
  QuestionnaireInputSize,
  QuestionnaireOption,
} from '..'

import {Question, Option, QuestionnaireQuestionType, ContentListItemCategory, ContentListItem} from '../../model'

import {TimeIcon, CalendarGreyIcon} from '../../asset/image'
export interface QuestionnaireQuestionProps {
  key: string
  id: string
  index: number
  sectionId: string | undefined
  isLast: boolean
  ref?: MutableRefObject<undefined>
  contentList?: ContentListItem[]
}

export const QuestionnaireQuestion = (props: QuestionnaireQuestionProps) => {
  const {pad, color, fontWeight} = selectTheme()

  const {id, index, sectionId, isLast, contentList} = props
  const {
    doQUESTIONNAIRES_SET,
    doQUESTIONNAIRES_EDITING_STATUS_SET,
    doQUESTIONNAIRES_QUESTION_SET,
    doQUESTIONNAIRES_QUESTION_DELETE,
    doQUESTIONNAIRES_SECTION_SET,
  }: any = createDispatchActions()

  const questionnairesState = selectQuestionnaires()
  const [questionIndex, setQuestionIndex] = useState(1)
  const [hovering, setHovering] = useState(false)

  const {editingQuestionnaireId, editingBlock, viewOnly} = questionnairesState.editing
  const [editingQuestion, setEditingQuestion] = useState<Question | undefined>()

  useEffect(() => {
    if (!id) return
    setEditingQuestion(_.find(questionnairesState.questionList, ['id', id]))
  }, [id])

  useEffect(() => {
    if (!editingQuestion) return
    doQUESTIONNAIRES_QUESTION_SET(editingQuestion)
  }, [editingQuestion])

  const questionOptions = [
    {value: 'multi_select', label: 'Multiple Selection'},
    {value: 'radio', label: 'Multiple Choice'},
    {value: 'text', label: 'Open Question'},
    {value: 'time', label: 'Time'},
    {value: 'date', label: 'Date'},
    {value: 'date_time', label: 'Date and Time'},
  ]

  const optionTranslator: {[key: string]: string} = {
    multi_select: 'Multiple Selection',
    radio: 'Multiple Choice',
    text: 'Open Question',
    time: 'Time',
    date: 'Date',
    date_time: 'Date and Time',
  }

  useEffect(() => {
    const i = _.findIndex(_.filter(contentList, ['category', ContentListItemCategory.Question]), ['id', id]) + 1
    setQuestionIndex(i)
  }, [contentList])

  const generateNewQuestion = () => {
    return {
      sectionId,
      id: t.uuid(),
      label: t.uuid(),
      description: 'New Question',
      type: QuestionnaireQuestionType.MultiSelect,
      required: false,
      inputConfig: {
        options: [{name: 'New Option'}],
      },
    }
  }

  const generateNewSection = () => {
    return {
      questionnaireId: editingQuestionnaireId,
      id: t.uuid(),
      legend: 'New Section Title',
      description: 'Description goes here',
      questionList: [],
    }
  }

  const addSection = () => {
    const newSection = generateNewSection()
    const findEditingQuestionnaireResult = _.find(questionnairesState.questionnaireList, ['id', editingQuestionnaireId])
    const eidtingQuestionnaireCopy = JSON.parse(JSON.stringify(findEditingQuestionnaireResult))
    eidtingQuestionnaireCopy.contentList?.splice(index + 1, 0, {
      id: newSection.id,
      category: ContentListItemCategory.Section,
    })
    doQUESTIONNAIRES_SECTION_SET(newSection)
    doQUESTIONNAIRES_SET(eidtingQuestionnaireCopy)
    doQUESTIONNAIRES_EDITING_STATUS_SET({editingBlock: newSection.id})
  }

  const addQuestion = (itemId: string) => {
    const newQuestion = generateNewQuestion()
    const findEditingQuestionnaireResult = _.find(questionnairesState.questionnaireList, ['id', editingQuestionnaireId])
    const eidtingQuestionnaireCopy = JSON.parse(JSON.stringify(findEditingQuestionnaireResult))
    const i = 1 + _.findIndex(eidtingQuestionnaireCopy.contentList, ['id', itemId])
    eidtingQuestionnaireCopy.contentList.splice(i, 0, {
      id: newQuestion.id,
      category: ContentListItemCategory.Question,
      sectionId,
    })
    doQUESTIONNAIRES_SET(eidtingQuestionnaireCopy)
    doQUESTIONNAIRES_QUESTION_SET(newQuestion)
    doQUESTIONNAIRES_EDITING_STATUS_SET({editingBlock: newQuestion.id})
  }

  const duplicateQuestion = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const newQuestion = JSON.parse(JSON.stringify(editingQuestion))
    newQuestion.id = t.uuid()
    newQuestion.inputConfig.options.map((option: Option) => {
      delete option.value
      return option
    })
    const findEditingQuestionnaireResult = _.find(questionnairesState.questionnaireList, ['id', editingQuestionnaireId])
    const eidtingQuestionnaireCopy = JSON.parse(JSON.stringify(findEditingQuestionnaireResult))
    eidtingQuestionnaireCopy.contentList.splice(index + 1, 0, {
      id: newQuestion.id,
      category: ContentListItemCategory.Question,
      sectionId,
    })
    doQUESTIONNAIRES_SET(eidtingQuestionnaireCopy)
    doQUESTIONNAIRES_QUESTION_SET(newQuestion)
    doQUESTIONNAIRES_EDITING_STATUS_SET({editingBlock: newQuestion.id})
  }

  const addOption = () => {
    const updatedQuestion = JSON.parse(JSON.stringify(editingQuestion))
    updatedQuestion.inputConfig?.options.push({name: 'New Option'})
    setEditingQuestion(updatedQuestion)
  }

  const deleteQuestion = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const findEditingQuestionnaireResult = _.find(questionnairesState.questionnaireList, ['id', editingQuestionnaireId])
    const eidtingQuestionnaireCopy = JSON.parse(JSON.stringify(findEditingQuestionnaireResult))
    eidtingQuestionnaireCopy.contentList.splice(index, 1)
    doQUESTIONNAIRES_EDITING_STATUS_SET({editingBlock: eidtingQuestionnaireCopy.contentList[index - 1].id})
    doQUESTIONNAIRES_SET(eidtingQuestionnaireCopy)
    doQUESTIONNAIRES_QUESTION_DELETE({id})
  }

  const handleChangeOfQuestionType = (e: {option: string; value: QuestionnaireQuestionType}) => {
    const updatedQuestion = JSON.parse(JSON.stringify(editingQuestion))
    updatedQuestion.type = e.value
    if (['text', 'time', 'date', 'date_time'].includes(e.value)) {
      updatedQuestion.inputConfig = {
        options: [
          {
            name: 'New Option',
          },
        ],
      }
    }
    setEditingQuestion(updatedQuestion)
  }

  const handleChangeOfQuestionDescription = (value: string) => {
    const updatedQuestion = JSON.parse(JSON.stringify(editingQuestion))
    updatedQuestion.description = value
    setEditingQuestion(updatedQuestion)
  }

  const handleChangeOfRequiredStatus = (e: boolean) => {
    const updatedQuestion = JSON.parse(JSON.stringify(editingQuestion))
    updatedQuestion.required = e
    setEditingQuestion(updatedQuestion)
  }

  const handleClick = () => {
    if (editingBlock !== id && !viewOnly) doQUESTIONNAIRES_EDITING_STATUS_SET({editingBlock: id})
  }

  const handleMouseEnter = () => {
    setHovering(true)
  }

  const handleMouseLeave = () => {
    setHovering(false)
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} css={{marginBottom: pad.large}}>
          <div
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            css={{
              width: '57.4rem',
              borderRadius: '5px',
              border: editingBlock === id ? `1px solid ${color.primary}` : 'none',
              padding: `${pad.large} ${pad.xl}`,
              background: color.white,
              position: 'relative',
              cursor: viewOnly ? 'auto' : 'pointer',
              boxShadow: '0px 4px 12px rgba(213, 213, 213, 0.25)',
              marginBottom: pad.mediumSmall,
              ':hover': {
                background: viewOnly ? color.white : color.grey_20,
              },
            }}
          >
            {RIF(
              !viewOnly,
              <DragHandle
                {...provided.dragHandleProps}
                isDragging={snapshot.isDragging}
                isShowing={editingBlock === id || hovering || snapshot.isDragging}
                css={{
                  position: 'absolute',
                  top: '0',
                  left: '-24px',
                }}
              />,
            )}
            {RIF(
              editingBlock !== id,
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  css={{
                    fontWeight: fontWeight.bold,
                  }}
                >{`${questionIndex}. ${editingQuestion?.description}`}</div>
                {RIF(editingQuestion?.required, <RequiredTag />)}
              </div>,
            )}
            {RIF(
              editingBlock === id,
              <div>
                <div css={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                  <div css={{display: 'flex', alignItems: 'center'}}>
                    <span css={{width: '2rem'}}>{`${questionIndex}.`}</span>
                    <QuestionnaireInput
                      size={QuestionnaireInputSize.medium}
                      defaultValue={editingQuestion?.description}
                      onChangeAction={handleChangeOfQuestionDescription}
                      placeholder={'New Question'}
                      initialValue={'New Question'}
                      maxLength={250}
                    />
                  </div>
                  <Select
                    options={questionOptions}
                    defaultValue={{
                      value: editingQuestion?.type,
                      label: optionTranslator[editingQuestion?.type || 'multi_select'],
                    }}
                    onChange={handleChangeOfQuestionType}
                    css={{
                      height: '43px',
                      width: '12.143rem',
                    }}
                  />
                </div>
              </div>,
            )}
            <div>
              {RIF(
                editingQuestion?.type === 'text',
                <div css={{marginLeft: '2rem', color: color.grey_400, marginTop: '15px'}}>
                  Participant's answer goes here
                </div>,
              )}
              {RIF(
                editingQuestion?.type === 'time',
                <div css={{display: 'flex', alignItems: 'center', marginLeft: '2rem', marginTop: '15px'}}>
                  <img src={TimeIcon} width={20} />
                  <p
                    css={{
                      color: color.grey_400,
                      marginLeft: '8px',
                    }}
                  >
                    Time
                  </p>
                </div>,
              )}
              {RIF(
                editingQuestion?.type === 'date',
                <div css={{display: 'flex', alignItems: 'center', marginLeft: '2rem', marginTop: '15px'}}>
                  <img src={CalendarGreyIcon} width={20} />
                  <p
                    css={{
                      color: color.grey_400,
                      marginLeft: '8px',
                    }}
                  >
                    Month/Day/Year
                  </p>
                </div>,
              )}
              {RIF(
                editingQuestion?.type === 'date_time',
                <div css={{display: 'flex', alignItems: 'center', marginLeft: '2rem', marginTop: '15px'}}>
                  <img src={CalendarGreyIcon} width={20} />
                  <p
                    css={{
                      color: color.grey_400,
                      marginLeft: '8px',
                    }}
                  >
                    Time/Month/Day/Year
                  </p>
                </div>,
              )}
              {RIF(
                ['multi_select', 'radio'].includes(editingQuestion?.type || ''),
                editingQuestion?.inputConfig.options.map((option, i) => (
                  <QuestionnaireOption
                    {...{
                      key: id,
                      index: i,
                      isEditing: editingBlock === id,
                      option,
                      editingQuestion,
                      setEditingQuestion,
                    }}
                  />
                )),
              )}
            </div>
            {RIF(
              editingBlock === id,
              <div css={{paddingTop: pad.medium}}>
                <div
                  css={{
                    paddingLeft: pad.slightlyLarger,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    {RIF(
                      !['text', 'time', 'date', 'date_time'].includes(editingQuestion?.type || ''),
                      <ButtonText onClick={addOption} children='+ Add New Option' />,
                    )}
                  </div>
                </div>
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: pad.slightlyLarger,
                    width: '100%',
                    borderTop: `1px solid ${color.grey_160}`,
                    padding: `${pad.medium} 0`,
                    marginBottom: `-${pad.large}`,
                    height: '55px',
                  }}
                >
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ButtonText onClick={duplicateQuestion} css={{color: color.primary}} children='Duplicate' />
                    <ButtonText
                      css={{marginLeft: '30px'}}
                      onClick={deleteQuestion}
                      children={<span css={{color: color.warning}}>Delete</span>}
                    />
                  </div>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      css={{
                        marginRight: pad.mediumSmall,
                      }}
                    >
                      Required
                    </p>
                    <SlideCheckbox value={editingQuestion?.required || false} onChange={handleChangeOfRequiredStatus} />
                  </div>
                </div>
              </div>,
            )}
          </div>
          {RIF(
            editingBlock === id || (!editingBlock && isLast && !viewOnly),
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ButtonText
                onClick={() => addQuestion(id)}
                css={{color: color.primary, marginRight: pad.xl}}
                children='+New Question'
              />
              <ButtonText onClick={addSection} css={{color: color.primary}} children='+New Section' />
            </div>,
          )}
        </div>
      )}
    </Draggable>
  )
}
