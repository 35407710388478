import {Line, LineConfig, Area, AreaConfig, Column, ColumnConfig, Plot, Datum} from '@ant-design/charts'
import {LineOptions, ColumnOptions} from '@antv/g2plot/lib'
import {Chart} from '@antv/g2/lib'
import {AxisCfg, LineOption, RegionPositionBaseOption, SliderCfg, ScaleOption} from '@antv/g2/lib/interface'
import {Tooltip} from '@ant-design/plots'
import {TimeSeriesDataSource} from '../../../model/chart'
import {ChartCard} from './chart_card'
import {RIF, TimeConvertType, timeConvert} from '../../../lib'
import {ChangeEvent, ReactElement, useEffect, useMemo, useRef, useState} from 'react'
import {selectTheme} from '../../../store'
import _ from 'lodash'
import {Input, Select, SlideCheckbox} from '../..'

export enum TimeSeriesChartType {
  Line,
  Area,
  Column,
}

export interface GenericTimeSeriesDataChartConfig {
  dataConfig: TimeSeriesDataConfig
  chartConfig: ChartConfig
  chartCardConfig: ChartCardConfig
  sampleRatio?: number
}

export interface GenericTimeSeriesDataChartProps {
  config: GenericTimeSeriesDataChartConfig
  timeSeriesData: TimeSeriesDataSource[]
  plotDataRange?: [Date, Date]
  commonTaskDataMarkerList?: CommonTaskDataMarker[]
  chartReadyCallback?: (chart: Chart) => void
  beforeUpdateDataCallback?: (data: TimeSeriesDataSource[]) => void
}

export type DataConverter = (timeSeriesData: TimeSeriesDataSource[]) => TimeSeriesDataSource[]

export interface SubDataTypeOption {
  label: string
  value?: string //display default v, use undefined
}

export interface DataConverterOption {
  label: string
  value: string
  dataConverter?: DataConverter
  chartConfig?: ChartConfig
}

export interface ChartAnnotation {
  type: 'line' | 'region'
  content: any
}

export interface TimeSeriesDataConfig {
  defaultDataTypeOption?: SubDataTypeOption
  subDataTypeOptions?: SubDataTypeOption[]
  defaultDataConverterOption?: DataConverterOption
  dataConverterOptions?: DataConverterOption[]
  maxDataLength?: number
}

export interface CommonTaskDataMarker {
  type: 'line' | 'region'
  timestamp: number[]
  color: string
  text?: string
}

export interface ChartConfig {
  chartType: TimeSeriesChartType
  theme?: object
  tooltip?: false | Tooltip
  point?: any
  yAxisConfig?: any
  chartAnnotations?: ChartAnnotation[]
  scaleRange?: [number, number]
  scaleRecord?: Record<string, ScaleOption>
  lineStyle?: any
  enableManualSetYAxis?: boolean
  enableSlider?: boolean
}

export interface ChartCardConfig {
  height: string
  width: string
  title: string
  hintDescription: string
  extraTitleRightSideElements?: ReactElement
}

export const sliceTimeSeriesDataByPlotTimeRange = (
  dataSource: TimeSeriesDataSource[],
  plotTimeRange: [number, number],
): TimeSeriesDataSource[] => {
  const plotStartTime = plotTimeRange[0]
  const plotEndTime = plotTimeRange[1]
  const firstDataTime = dataSource[0].t
  const lastDataTime = dataSource[dataSource.length - 1].t
  const rangeStartMockData = {t: plotStartTime}
  const rangeStartEndData = {t: plotEndTime}

  if (plotStartTime >= lastDataTime || plotEndTime < firstDataTime || plotStartTime >= plotEndTime) {
    return [rangeStartMockData, rangeStartEndData]
  } else if (plotStartTime <= firstDataTime && plotEndTime >= lastDataTime) {
    const result = dataSource
    result.unshift(rangeStartMockData)
    result.push(rangeStartEndData)
    return result
  }

  let sliceIndexStart = -1
  let sliceIndexEnd = -1
  if (plotEndTime >= lastDataTime) {
    sliceIndexEnd = dataSource.length
  }

  for (let i = 0; i < dataSource.length; i++) {
    if (sliceIndexStart < 0) {
      if (dataSource[i].t >= plotStartTime) {
        sliceIndexStart = i
      }
    } else if (sliceIndexEnd < 0) {
      if (dataSource[i].t >= plotEndTime) {
        sliceIndexEnd = i
      }
    } else {
      break
    }
  }

  if (sliceIndexStart > -1 && sliceIndexEnd > -1) {
    const result = dataSource.slice(sliceIndexStart, sliceIndexEnd)
    result.unshift(rangeStartMockData)
    result.push(rangeStartEndData)
    return result
  } else {
    return [rangeStartMockData, rangeStartEndData]
  }
}

export const TimeSeriesDataChart = (props: GenericTimeSeriesDataChartProps) => {
  const {
    config,
    timeSeriesData,
    plotDataRange,
    commonTaskDataMarkerList,
    chartReadyCallback,
    beforeUpdateDataCallback,
  } = props
  const {dataConfig, chartConfig, chartCardConfig, sampleRatio} = config
  const {subDataTypeOptions, dataConverterOptions} = dataConfig
  const {color, fontWeight, fontSize} = selectTheme()
  const [currentSampleRatio, setCurrentSampleRatio] = useState<number>(sampleRatio ?? 1)
  const [chartInstance, setChartInstance] = useState<Chart | undefined>()
  const [showCommonTaskDataMarker, setShowCommonTaskDataMarker] = useState<boolean>(false)
  const showCommonTaskDataMarkerRef = useRef<boolean>(showCommonTaskDataMarker)
  const [enableSlider, setEnableSlider] = useState<boolean>(chartConfig.enableSlider ?? false)
  const [currentDataTypeOption, setCurrentDataTypeOption] = useState<SubDataTypeOption | undefined>(
    dataConfig.defaultDataTypeOption,
  )
  const [displayDataType, setDisplayDataType] = useState<string>()
  const [dataConverterOption, setDataConverterOption] = useState<DataConverterOption | undefined>(
    dataConfig.defaultDataConverterOption,
  )
  const [currentChartConfig, setCurrentChartConfig] = useState<ChartConfig>(chartConfig)
  const [grayOutMessage, setGrayOutMessage] = useState<string | null>(null)
  const [enableDataFilter, setEnableDataFilter] = useState<boolean>(false)
  const currentDataFilterRange = useRef<[number, number]>()

  // draw plot
  useEffect(() => {
    if (chartInstance) {
      let dataToPlot: TimeSeriesDataSource[] = timeSeriesData
      if (displayDataType) {
        dataToPlot = timeSeriesData.map((data) => {
          return {
            t: data.t,
            v: data[displayDataType],
          }
        })
      }

      if (dataConverterOption?.dataConverter) {
        dataToPlot = dataConverterOption?.dataConverter(dataToPlot)
      }

      // filter manual value range
      if (enableDataFilter && currentDataFilterRange.current) {
        const min = currentDataFilterRange.current[0]
        const max = currentDataFilterRange.current[1]
        dataToPlot = dataToPlot.filter((data) => {
          if (!data.v) return true
          return data.v >= min && data.v <= max
        })
      }

      if (currentSampleRatio != 1) {
        const filterIndex = Math.floor(1 / currentSampleRatio)
        dataToPlot = dataToPlot.filter((_, index) => index % filterIndex === 0)
      }

      if (dataConfig.maxDataLength && dataToPlot.length > dataConfig.maxDataLength) {
        setGrayOutMessage('Data in selected range out of support length')
      } else {
        if (beforeUpdateDataCallback) {
          beforeUpdateDataCallback(dataToPlot)
        }

        if (grayOutMessage) {
          setGrayOutMessage(null)
        }
        chartInstance.changeData(dataToPlot)
      }
    }
  }, [
    chartInstance,
    timeSeriesData,
    enableSlider,
    currentSampleRatio,
    displayDataType,
    dataConverterOption?.dataConverter,
    enableDataFilter,
    currentDataFilterRange.current,
  ])

  useEffect(() => {
    setUpChartScale()
    drawAnnotations()
  }, [chartInstance])

  const setUpChartScale = () => {
    if (chartInstance) {
      if (currentChartConfig.scaleRecord) {
        chartInstance.scale(currentChartConfig.scaleRecord)
        chartInstance.render()
      } else if (currentChartConfig.scaleRange) {
        chartInstance.scale('v', {
          min: currentChartConfig.scaleRange[0],
          max: currentChartConfig.scaleRange[1],
        })
      } else {
        chartInstance.scale('v', {nice: true})
        chartInstance.render()
      }
    }
  }

  const drawAnnotations = () => {
    if (chartInstance && currentChartConfig.chartAnnotations) {
      currentChartConfig.chartAnnotations.forEach((annotation) => {
        if (annotation.type === 'line') {
          chartInstance.annotation().line(annotation.content)
        } else if (annotation.type === 'region') {
          chartInstance.annotation().region(annotation.content)
        }
      })
      chartInstance.render()
    }
  }

  useEffect(() => {
    setUpChartScale()
  }, [currentChartConfig])

  const plotDataRangeTimestamp = useMemo<[number, number] | undefined>(() => {
    if (plotDataRange) {
      const plotRangeStart = Math.floor(plotDataRange[0].getTime() / 1000)
      const plotRangeEnd = plotDataRange[1].getTime() / 1000
      return [plotRangeStart, plotRangeEnd]
    }
  }, [plotDataRange])

  const plotDataRangeTimestampRef = useRef<[number, number] | undefined>(plotDataRangeTimestamp)

  useEffect(() => {
    if (chartInstance) {
      if (showCommonTaskDataMarker && commonTaskDataMarkerList) {
        plotCommomTaskDataMarker(commonTaskDataMarkerList)
        drawAnnotations()
        // 2 conditions need to call render()
        // (1) if plotDataRangeTimestamp didn't change, need to call render to plot the task data mark
        // (2) plotDataRangeTimestamp changed but call render only when showCommonTaskDataMarker state changed too,
        //     otherwise the chart will plot the marker first and plot the time series data, that will make the chart looks not synchronized
        if (
          plotDataRangeTimestampRef.current === plotDataRangeTimestamp ||
          showCommonTaskDataMarkerRef.current === false
        ) {
          chartInstance.render()
        }

        if (plotDataRangeTimestampRef.current !== plotDataRangeTimestamp) {
          plotDataRangeTimestampRef.current = plotDataRangeTimestamp
        }
      } else if (!showCommonTaskDataMarker) {
        chartInstance.annotation().clear(true)
        drawAnnotations()
      }
    }
    showCommonTaskDataMarkerRef.current = showCommonTaskDataMarker
  }, [chartInstance, commonTaskDataMarkerList, plotDataRangeTimestamp, showCommonTaskDataMarker, currentChartConfig])

  const calcPercentageInPlotTimeRangeByTimestamp = (timestamp: number) => {
    if (plotDataRangeTimestamp) {
      if (timestamp < plotDataRangeTimestamp[0]) {
        return -1
      } else if (timestamp > plotDataRangeTimestamp[1]) {
        return 200
      } else {
        const plotDuration = plotDataRangeTimestamp[1] - plotDataRangeTimestamp[0]
        const timeDiff = timestamp - plotDataRangeTimestamp[0]
        const percentage = Math.floor((timeDiff / plotDuration) * 100)
        return percentage
      }
    }
    return -1
  }

  const plotCommomTaskDataMarker = (markerList: CommonTaskDataMarker[]) => {
    if (chartInstance) {
      chartInstance.annotation().clear(true)
      markerList.forEach((dataMarker) => {
        if (dataMarker.type === 'line') {
          const lineData = convertCommonTaskDataMarkerToAnnotationLine(dataMarker)
          if (lineData) {
            chartInstance.annotation().line(lineData)
          }
        } else if (dataMarker.type === 'region') {
          const regionData = convertCommonTaskDataMarkerToAnnotationRegion(dataMarker)
          if (regionData) {
            chartInstance.annotation().region(regionData)
          }
        }
      })
    }
  }

  const convertCommonTaskDataMarkerToAnnotationLine = (dataMarker: CommonTaskDataMarker): any => {
    const percentage = calcPercentageInPlotTimeRangeByTimestamp(dataMarker.timestamp[0])
    if (percentage > 0 && percentage <= 100) {
      return {
        start: [`${percentage}%`, '0%'],
        end: [`${percentage}%`, '100%'],
        top: true,
        text: {
          content: dataMarker.text ?? 'test test',
          position: 'start',
          autoRotate: false,
          offsetX: 3,
          offsetY: 15,
        },
        style: {
          stroke: dataMarker.color,
          lineWidth: 2,
        },
      }
    }
  }

  const convertCommonTaskDataMarkerToAnnotationRegion = (dataMarker: CommonTaskDataMarker): any => {
    const startPercentage = calcPercentageInPlotTimeRangeByTimestamp(dataMarker.timestamp[0])
    const endPercentage = calcPercentageInPlotTimeRangeByTimestamp(dataMarker.timestamp[1])
    const startInRange = startPercentage >= 0 && startPercentage <= 100
    const endInRange = endPercentage >= 0 && endPercentage <= 100

    const finalStartPercent = startInRange ? startPercentage : 0
    const finalEndPercent = endInRange ? endPercentage : 100
    const included = startInRange || endInRange || (startPercentage < 0 && endPercentage > 100)

    if (included) {
      return {
        start: [`${finalStartPercent}%`, '0%'],
        end: [`${finalEndPercent}%`, '100%'],
        top: false,
        style: {
          fill: dataMarker.color,
          fillOpacity: 0.3,
        },
      }
    }
  }

  const defaultDailyChartXAxisConfig: AxisCfg = {
    // title: {text: 'Time'},
    grid: null,
    tickLine: {
      alignTick: true,
      length: 5,
    },
    label: {
      autoRotate: false,
      // formatter: (text: string) => {
      //   return timeConvert({time: +text * 1000, type: TimeConvertType.localizedUtcCustomFormat, utc: true, arg: 'H:mm'})
      // },
    },
  }

  const defaultTooltip: Tooltip = {
    title: 'Data time and value',
    formatter: (datum) => {
      const dataTimeStamp = timeConvert({
        time: +datum.t,
        type: TimeConvertType.localizedUtcCustomFormat,
        arg: 'LLL d, H:mm:ss',
      }) as string
      return {name: dataTimeStamp, value: datum.v}
    },
    itemTpl: `
      <li class="g2-tooltip-list-item">
        <span class="g2-tooltip-marker" style="background-color: {color};"></span>
        <span class="g2-tooltip-name">{name}</span>:
        <span class="g2-tooltip-value">{value}</span>
      </li>
    `,
  }

  const onChartReady: (chart: Plot<any>) => void = (chart) => {
    chart.chart.scale('t', {
      type: 'time',
      alias: 'time',
      tickInterval: 900000, // 15 minutes
      formatter: (text: string) => {
        return timeConvert({time: +text, type: TimeConvertType.localizedUtcCustomFormat, utc: true, arg: 'H:mm'})
      },
    })

    if (chartReadyCallback) {
      chartReadyCallback(chart.chart)
    }

    setChartInstance(chart.chart)
  }

  const sliderConfig: SliderCfg = {
    start: 0,
    end: 1,
    formatter: (text: string) => {
      return timeConvert({time: +text, type: TimeConvertType.localizedUtcCustomFormat, utc: true, arg: 'H:mm:ss'})
    },
  }

  const defaultConfig = {
    data: [], //default empty data
    xField: 't',
    yField: 'v',
    lineStyle: currentChartConfig.lineStyle,
    minColumnWidth: 4,
    maxColumnWidth: 30,
    smooth: false,
    animation: false,
    xAxis: defaultDailyChartXAxisConfig,
    yAxis: chartConfig.yAxisConfig,
    padding: [5, 25, 25, 55],
    tooltip: chartConfig.tooltip ?? defaultTooltip,
    theme: chartConfig.theme ?? 'light',
    point: chartConfig.point,
    onReady: onChartReady,
    slider: enableSlider ? sliderConfig : undefined,
    connectNulls: false,
    interactions: [
      {
        type: 'slider',
        enable: enableSlider,
      },
    ],
  }

  const lineConfig = useMemo<LineConfig>(() => {
    return defaultConfig
  }, [enableSlider])

  const columnConfig = useMemo<ColumnConfig>(() => {
    return defaultConfig
  }, [enableSlider])

  const getTypedChart = () => {
    if (chartConfig.chartType === TimeSeriesChartType.Line) return <Line {...lineConfig} />
    else if (chartConfig.chartType === TimeSeriesChartType.Area) return <Area {...lineConfig} />
    else if (chartConfig.chartType === TimeSeriesChartType.Column) return <Column {...columnConfig} />
    else return <Line {...lineConfig} />
  }

  const enableDataFilterClick = () => {
    setEnableDataFilter?.(!enableDataFilter)
  }
  const enableDataFilterClickCheckbox = (
    <div css={{height: '28px', padding: '5px 16px', display: 'flex', alignItems: 'center'}}>
      <SlideCheckbox value={enableDataFilter} onChange={enableDataFilterClick} />
      <p css={{marginLeft: '10px'}}>Manually set Y-Axis</p>
    </div>
  )

  const dataFilterInput = () => {
    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(0)

    if (enableDataFilter) {
      if (currentDataFilterRange.current != undefined) {
        if (currentDataFilterRange.current[0] !== minValue) {
          setMinValue(currentDataFilterRange.current[0])
        }
        if (currentDataFilterRange.current[1] != maxValue) {
          setMaxValue(currentDataFilterRange.current[1])
        }
      } else if (chartInstance != undefined) {
        try {
          const axisYMin = chartInstance?.getYScales()?.[0].min
          const axisYMax = chartInstance?.getYScales()?.[0].max

          if (axisYMin !== minValue) {
            setMaxValue(axisYMin)
          }

          if (axisYMax !== maxValue) {
            setMaxValue(axisYMax)
          }
          currentDataFilterRange.current = [axisYMin, axisYMax]
        } catch (e) {
          console.log(e)
        }
      }
    }

    const handleMinInput = (event: ChangeEvent<HTMLInputElement>) => {
      const min = +event.target.value
      if (typeof min === 'number') {
        setMinValue(min)
        currentDataFilterRange.current = [min, maxValue]
      }
    }

    const handleMaxInput = (event: ChangeEvent<HTMLInputElement>) => {
      const max = +event.target.value
      if (typeof max === 'number') {
        setMaxValue(max)
        currentDataFilterRange.current = [minValue, max]
      }
    }

    const valueInput = (value: number, onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void) => {
      return (
        <div
          css={{
            width: '70px',
            height: '26px',
            border: `1px solid ${color.grey_160}`,
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            background: color.white,
            ':hover': {
              border: `1px solid ${color.grey_400}`,
              cursor: 'pointer',
            },
            marginLeft: '10px',
            marginRight: '10px',
          }}
        >
          <Input
            value={`${value}`}
            onChange={onChangeHandler}
            style={{
              border: 'none',
              background: 'inherit',
              height: '100%',
            }}
          />
        </div>
      )
    }

    return (
      <div
        css={{
          width: 'max-content',
          height: '26px',
          borderRadius: '5px',
          display: 'flex',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Min:
          {valueInput(minValue, handleMinInput)}
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Max:
          {valueInput(maxValue, handleMaxInput)}
        </div>
      </div>
    )
  }

  const manualYAxisRangeSetting = (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {enableDataFilterClickCheckbox}
      {RIF(enableDataFilter, dataFilterInput())}
    </div>
  )

  const showCommonTaskMarkerCheckboxClick = () => {
    setShowCommonTaskDataMarker?.(!showCommonTaskDataMarker)
  }

  const showCommonTaskMarkerCheckbox = (
    <div css={{height: '28px', padding: '5px 16px', display: 'flex', alignItems: 'center'}}>
      <SlideCheckbox value={showCommonTaskDataMarker} onChange={showCommonTaskMarkerCheckboxClick} />
      <p css={{marginLeft: '10px'}}>Show task marker</p>
    </div>
  )

  const handleChangeDisplayDataType = (e: SubDataTypeOption) => {
    setCurrentDataTypeOption(e)
    setDisplayDataType(e.value)
  }
  const displayDataTypeSelect = (
    <Select
      value={currentDataTypeOption}
      onChange={handleChangeDisplayDataType}
      options={subDataTypeOptions}
      css={{
        margin: '0 8px',
        width: '160px',
        fontSize: fontSize.h7,
      }}
    />
  )

  const dataConverterSelect = (
    <Select
      value={dataConverterOption}
      onChange={(e: DataConverterOption) => {
        setDataConverterOption(e)
        if (e.chartConfig) {
          setCurrentChartConfig(e.chartConfig)
        }
      }}
      options={dataConverterOptions}
      css={{
        margin: '0 8px',
        width: '160px',
        fontSize: fontSize.h7,
      }}
    />
  )

  const titleRightSideElements = (
    <>
      <div
        css={{
          width: 'max-content',
          position: 'relative',
          justifyContent: 'flex-start',
          display: 'flex',
          alignItems: 'center',
          fontSize: '18px',
          marginLeft: '10px',
        }}
      >
        {RIF(subDataTypeOptions, displayDataTypeSelect)}
        {RIF(dataConverterOptions, dataConverterSelect)}
      </div>

      <div
        css={{
          width: '100%',
          position: 'relative',
          justifyContent: 'flex-end',
          display: 'flex',
          alignItems: 'center',
          fontSize: '18px',
        }}
      >
        {RIF(chartConfig.enableManualSetYAxis, manualYAxisRangeSetting)}
        {RIF(chartCardConfig.extraTitleRightSideElements, chartCardConfig.extraTitleRightSideElements)}
        {showCommonTaskMarkerCheckbox}
      </div>
    </>
  )

  // const titleRightSideElementsHeight = dataConverterOptions ? '50px' : subDataTypeOptions ? '50px' : undefined
  const titleRightSideElementsHeight = '50px'

  const chartCardProps = {
    height: chartCardConfig.height,
    width: chartCardConfig.width,
    chartTitle: `${chartCardConfig.title}`,
    hintDescription: chartCardConfig.hintDescription,
    chart: getTypedChart(),
    titleRightSideElements,
    contentPadding: '0px',
    titleRightSideElementsHeight,
    grayOutMessage,
  }

  const downSample = () => {
    if (currentSampleRatio > 0.1) {
      const newRatio = currentSampleRatio / 2
      setCurrentSampleRatio(newRatio)
    }
  }

  const upSample = () => {
    if (currentSampleRatio < 1) {
      const newRatio = currentSampleRatio * 2
      setCurrentSampleRatio(newRatio)
    }
  }

  const sliderCheckBoxClick = () => {
    setEnableSlider(!enableSlider)
  }

  const sampelSelector = (
    <div
      css={{
        width: '100%',
        border: `none`,
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        css={{
          width: '200px',
          textAlign: 'start',
          margin: '10px',
        }}
      >
        Sample ratio:{currentSampleRatio}
      </div>

      <button
        onClick={() => upSample()}
        css={{
          width: '100px',
          height: '28px',
          padding: '5px 16px',
          borderRadius: '5px',
          backgroundColor: 'transparent',
          border: `1px solid #000000`,
          cursor: 'pointer',
          textAlign: 'center',
          margin: '10px',
        }}
      >
        UP
      </button>
      <button
        onClick={() => downSample()}
        css={{
          width: '100px',
          height: '28px',
          padding: '5px 16px',
          borderRadius: '5px',
          backgroundColor: 'transparent',
          border: `1px solid #000000`,
          cursor: 'pointer',
          textAlign: 'center',
          margin: '10px',
        }}
      >
        Down
      </button>

      <div css={{height: '28px', padding: '5px 16px'}}>
        <input type='checkbox' id='sliderEneabl' name='slider' checked={enableSlider} onChange={sliderCheckBoxClick} />
        <label htmlFor='slider'>Slider</label>
      </div>
    </div>
  )

  return (
    <>
      <div>
        <ChartCard {...chartCardProps} />
        {/* {sampelSelector} */}
      </div>
    </>
  )
}
