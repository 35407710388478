import {useState, useEffect, Dispatch, SetStateAction, CSSProperties} from 'react'
import {RIF, timeConvert, TimeConvertType, handleEsc, sortBy, _, t} from '../../lib'
import {selectTheme, selectMethod} from '../../store'
import {CancelBlackIcon, GarminConnectIcon, GarminGIcon, TickBlackIcon, GarminLIcon} from '../../asset/image'
import LabfrontIcon from '../../asset/image/labfront_icon.png'
import DexcomIcon from '../../asset/image/dexcom_icon.png'
import {Tooltip, convertMovesenseDescription, convertGarminStreamDescription} from '..'
import {
  TaskStateType,
  SidebarContent,
  AdherenceGarminConnect,
  AdherenceGarminTask,
  AdherenceNormalTask,
  TaskType,
  getTaskContentName,
} from '../../model'
import {IGarminDevice} from '../../shared/db'
export interface AdherenceSidebarProps {
  displaySidebar: boolean
  setDisplaySidebar: Dispatch<SetStateAction<boolean>>
  sidebarRef: any
  sidebarContent: SidebarContent
  setHighLightBox: Dispatch<SetStateAction<string>>
  hasGarminDirect: boolean
  hasDexcom: boolean
}

export const AdherenceSidebar = (props: AdherenceSidebarProps) => {
  const {color, fontSize, fontWeight} = selectTheme()

  const {displaySidebar, setDisplaySidebar, sidebarRef, sidebarContent, hasGarminDirect, hasDexcom, setHighLightBox} =
    props

  const {garminConnect, status, task, participantName, dexcomEgvDataCount} = sidebarContent

  const method = selectMethod()
  const hasGarminConnect: boolean = method?.garminConnectEnable
  const methodTaskListWithoutGarmin: TaskStateType[] = method?.taskList
    ?.filter((item: TaskStateType) => item.type !== TaskType.GarminDevice && item.enabled)
    ?.sort(sortBy('index'))

  const [taskGarminData, setTaskGarminData] = useState<AdherenceGarminTask>({
    taskId: '',
    taskType: 'garmin_device',
  })
  useEffect(() => {
    if (!task) return
    if (!Object.keys(task).length) return
    const findResult = _.find(task, ['taskType', TaskType.GarminDevice]) as AdherenceGarminTask
    if (!findResult) return
    setTaskGarminData(findResult)
  })

  const taskGarminDeviceFromMethod: IGarminDevice | null | undefined = method?.taskList?.find(
    (item: TaskStateType) => item.type === TaskType.GarminDevice,
  )?.garminDevice
  const [displayDate, setDisplayDate] = useState('')

  useEffect(() => {
    if (sidebarContent?.yymmddIndex) {
      setDisplayDate(
        timeConvert({time: sidebarContent?.yymmddIndex, type: TimeConvertType.adherenceSidebarTitle}) as string,
      )
    }
    // scroll to top when click diff field
    const y = sidebarRef?.current?.getBoundingClientRect()?.y
    sidebarRef?.current?.scrollTo(y, 0)
  }, [sidebarContent])

  const handleClose = () => {
    if (setDisplaySidebar) {
      setDisplaySidebar(false)
      setHighLightBox('')
    }
  }
  // press esc to cancel
  handleEsc(handleClose)

  return (
    <div
      ref={sidebarRef}
      css={{
        position: 'fixed',
        right: 0,
        top: '95px',
        zIndex: '104',
        // width: '30%',
        height: 'calc(100% - 95px)',
        overflowY: 'scroll',
        width: displaySidebar ? '30%' : '0',
        transition: '.3s',
        background: color.white,
        boxShadow: '0px 4px 10px 1px rgba(104, 104, 104, 0.25)',
      }}
    >
      {/* top section */}
      <section css={{background: color.background, padding: '25px'}}>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <img onClick={handleClose} src={CancelBlackIcon} width='14' css={{cursor: 'pointer'}} />
          {/* <img src={NotificationDefaultIcon} width='20' css={{cursor: 'pointer'}}/> */}
        </div>
        <div
          css={{
            fontWeight: fontWeight.thin,
            fontSize: fontSize.h4,
            margin: '15px 0',
          }}
        >
          {/* {status?.participantInsignia} */}
          {/* TODO: wait BE, should update name from BE */}
          {participantName}
        </div>
        <div css={{display: 'flex'}}>
          <div css={{paddingRight: '20px'}}>
            <div
              css={{
                fontSize: fontSize.h7,
                color: color.grey_400,
                fontWeight: fontWeight.medium,
              }}
            >
              Last seen
            </div>
            <div
              css={{
                marginTop: '6px',
                fontSize: fontSize.h7,
                fontWeight: fontWeight.medium,
              }}
            >
              {
                timeConvert({
                  time: status?.unixTimestampLastAppOpen || status?.unixTimestampLastGarminDeviceSync,
                  type: TimeConvertType.lastSeen,
                }) as string
              }
            </div>
          </div>
          {RIF(
            status?.mobileType,
            <div css={{padding: '0 20px', borderLeft: `1px solid ${color.grey_160}`}}>
              <div
                css={{
                  fontSize: fontSize.h7,
                  color: color.grey_400,
                  fontWeight: fontWeight.medium,
                }}
              >
                Mobile Type
              </div>
              <div
                css={{
                  marginTop: '6px',
                  fontSize: fontSize.h7,
                  fontWeight: fontWeight.medium,
                }}
              >
                {status?.mobileType?.toUpperCase()}
              </div>
            </div>,
          )}
          {RIF(
            status?.appVersion,
            <div css={{paddingLeft: '20px', borderLeft: `1px solid ${color.grey_160}`}}>
              <div
                css={{
                  fontSize: fontSize.h7,
                  color: color.grey_400,
                  fontWeight: fontWeight.medium,
                }}
              >
                App Version
              </div>
              <div
                css={{
                  marginTop: '6px',
                  fontSize: fontSize.h7,
                  fontWeight: fontWeight.medium,
                }}
              >
                {status?.appVersion}
              </div>
            </div>,
          )}
        </div>

        <div css={{display: 'flex'}}>
          {RIF(
            status?.deviceType,
            <div css={{paddingRight: '20px', marginTop: '20px'}}>
              <div
                css={{
                  fontSize: fontSize.h7,
                  color: color.grey_400,
                  fontWeight: fontWeight.medium,
                }}
              >
                Device
              </div>
              <div
                css={{
                  marginTop: '6px',
                  fontSize: fontSize.h7,
                  fontWeight: fontWeight.medium,
                }}
              >
                {status?.deviceType}
              </div>
            </div>,
          )}
          {RIF(
            status?.deviceFirmware,
            <div css={{marginTop: '20px', paddingLeft: '20px', borderLeft: `1px solid ${color.grey_160}`}}>
              <div
                css={{
                  fontSize: fontSize.h7,
                  color: color.grey_400,
                  fontWeight: fontWeight.medium,
                }}
              >
                Device Firmware
              </div>
              <div
                css={{
                  marginTop: '6px',
                  fontSize: fontSize.h7,
                  fontWeight: fontWeight.medium,
                }}
              >
                {status?.deviceFirmware}
              </div>
            </div>,
          )}
        </div>
      </section>

      <main css={{padding: '20px 25px'}}>
        <div
          css={{
            marginBottom: '20px',
            fontWeight: fontWeight.thin,
            fontSize: fontSize.h5,
          }}
        >
          {displayDate}
        </div>
        {RIF(
          hasGarminConnect,
          <GarminConnectBlock
            {...{
              deviceType: status?.deviceType,
              garminConnectData: garminConnect,
            }}
          />,
        )}
        {RIF(
          hasGarminDirect,
          <GarminDirectBlock
            {...{
              taskGarminData,
              deviceType: status?.deviceType,
              taskGarminDeviceFromMethod,
            }}
          />,
        )}
        {RIF(hasDexcom, <DexcomBlock {...{egvCounts: dexcomEgvDataCount || 0}} />)}
        {methodTaskListWithoutGarmin
          ?.filter((task: TaskStateType) => !!task.type)
          .map((item: TaskStateType, index: number) => (
            <TaskBlock
              {...{
                key: index,
                name: item?.[getTaskContentName(item.type)]?.name,
                IconIndex: item.index || index,
                IconColor: item.color ? `#${item.color}` : '',
                done: !!task?.[item?.id],
                adherenceTask: (task?.[item?.id] as AdherenceNormalTask) ?? undefined,
              }}
            />
          ))}
      </main>
    </div>
  )
}

const GarminConnectBlock = (props: {deviceType: string; garminConnectData?: AdherenceGarminConnect}) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {deviceType, garminConnectData} = props
  // const [enableAmount, setEnableAmount] = useState(0)
  const [collectedAmount, setCollectedAmount] = useState(0)

  const displayingDataTypeList = [
    'healthDailiesCollected',
    'healthEpochsCollected',
    'healthPulseOxCollected',
    'healthRespirationCollected',
    'healthSleepsCollected',
    'healthStressCollected',
    'healthBodyCompositionsCollected',
    'womenHealthMCTCollected',
  ]

  useEffect(() => {
    setCollectedAmount(0)
    if (!garminConnectData) return
    Object.entries(garminConnectData).forEach(([key, value]) => {
      if (displayingDataTypeList.includes(key) && value) {
        if (key === 'healthDailiesCollected') {
          // healthDailiesCollected includes three types of data
          return setCollectedAmount((prev) => prev + 3)
        }
        setCollectedAmount((prev) => prev + 1)
      }
    })
  }, [garminConnectData])

  return (
    <div
      css={{
        border: `1px solid ${color.grey_160}`,
        borderRadius: '5px',
        width: '100%',
        padding: '20px',
        paddingBottom: '5px',
      }}
    >
      <div css={{display: 'flex', paddingBottom: '15px'}}>
        <img src={GarminConnectIcon} width='24' height='24' css={{marginRight: '12px'}} />
        <div>
          <div css={{display: 'flex', alignItems: 'center', marginBottom: '6px'}}>
            <div
              css={{
                width: '13px',
                height: '13px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={GarminGIcon} width='13' />
            </div>
            <div css={{fontWeight: fontWeight.thin, margin: '0 6px'}}>Garmin Connect App</div>
            <Tooltip content='Data collected from Garmin Connect app' />
          </div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div css={{background: color.task.green, width: '7px', height: '7px', marginRight: '6px'}} />
            <div css={{fontWeight: fontWeight.medium, fontSize: fontSize.h7}}>
              {collectedAmount}/{displayingDataTypeList.length + 2} Metric(s) Collected
            </div>
          </div>
        </div>
      </div>

      <GarminCollectItem
        {...{
          name: 'Daily Summary',
          display: true,
          deviceSupport: true,
          collected: !!garminConnectData?.healthDailiesCollected,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Activity Summary',
          display: true,
          deviceSupport: true,
          collected: !!garminConnectData?.healthEpochsCollected,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Sleep',
          display: true,
          deviceSupport: true,
          collected: !!garminConnectData?.healthSleepsCollected,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Heart Rate',
          display: true,
          deviceSupport: true,
          collected: !!garminConnectData?.healthDailiesCollected,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Stress',
          display: true,
          deviceSupport: true,
          collected: !!garminConnectData?.healthStressCollected,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Steps',
          display: true,
          deviceSupport: true,
          collected: !!garminConnectData?.healthDailiesCollected,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Respiration',
          display: true,
          deviceSupport: devicesSupportTable(deviceType, 'Respiration'),
          collected: !!garminConnectData?.healthRespirationCollected,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Oxygen Saturation',
          display: true,
          deviceSupport: devicesSupportTable(deviceType, 'Oxygen Saturation'),
          collected: !!garminConnectData?.healthPulseOxCollected,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Body Composition',
          display: true,
          deviceSupport: true,
          collected: !!garminConnectData?.healthBodyCompositionsCollected,
        }}
      />
      <GarminCollectItem
        {...{
          name: `Women's Health Tracking`,
          display: true,
          deviceSupport: true,
          collected: !!garminConnectData?.womenHealthMCTCollected,
        }}
      />
    </div>
  )
}

interface GarminDirectBlockProps {
  taskGarminData: AdherenceGarminTask | undefined
  deviceType: string
  taskGarminDeviceFromMethod: any
}

const GarminDirectBlock = (props: GarminDirectBlockProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {taskGarminData, deviceType, taskGarminDeviceFromMethod} = props
  const [collectedAmount, setCollectedAmount] = useState(0)
  const [enableAmount, setEnableAmount] = useState(0)
  const [wearingDevice, setWearingDevice] = useState(true)

  useEffect(() => {
    if (!taskGarminDeviceFromMethod) return
    setEnableAmount(
      Object.entries(taskGarminDeviceFromMethod).filter(
        ([key, value]) =>
          !['acceleraometerEnable', 'enhancedBbiEnable', 'batteryPercentageSupport'].includes(key) &&
          key.includes('Enable') &&
          value,
      ).length,
    )
  }, [taskGarminDeviceFromMethod])

  useEffect(() => {
    setCollectedAmount(0)
    setWearingDevice(true)
    if (taskGarminData) setCollectedAmount(Object.values(taskGarminData).filter((item) => item === true).length)
    // only zero crossing & step will keep collect when not wearing
    if (
      !taskGarminData?.bbi &&
      !taskGarminData?.hr &&
      !taskGarminData?.pulseOx &&
      !taskGarminData?.stress &&
      !taskGarminData?.respiration &&
      !taskGarminData?.actigraphy &&
      !taskGarminData?.temperature
    )
      setWearingDevice(false)
  }, [taskGarminData])

  // BE bug, collectedAmount should not higher than enableAmount
  useEffect(() => {
    if (collectedAmount > enableAmount) setCollectedAmount(enableAmount)
  }, [enableAmount, collectedAmount])

  return (
    <div
      css={{
        border: `1px solid ${color.grey_160}`,
        borderRadius: '5px',
        width: '100%',
        marginTop: '10px',
        padding: '20px',
        paddingBottom: '5px',
      }}
    >
      <div css={{display: 'flex', paddingBottom: '15px'}}>
        <img src={LabfrontIcon} width='24' height='24' css={{marginRight: '12px'}} />
        <div>
          <div css={{display: 'flex', alignItems: 'center', marginBottom: '6px'}}>
            <div
              css={{
                width: '13px',
                height: '13px',
                borderRadius: '50%',
                paddingBottom: '1px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={GarminLIcon} width='13' />
            </div>
            <div css={{fontWeight: fontWeight.thin, margin: '0 6px'}}>Labfront Companion App</div>
            <Tooltip content='Data collected from Labfront Companion app' />
          </div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div css={{background: color.task.green, width: '7px', height: '7px', marginRight: '6px'}} />
            <div css={{fontWeight: fontWeight.medium, fontSize: fontSize.h7}}>
              {wearingDevice ? collectedAmount : '0'}/{enableAmount} Metric(s) Collected
            </div>
          </div>
        </div>
      </div>
      <GarminCollectItem
        {...{
          name: 'Heart Rate',
          collected: wearingDevice && !!taskGarminData?.hr,
          deviceSupport: true,
          display: taskGarminDeviceFromMethod?.heartRateEnable,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Stress',
          collected: wearingDevice && !!taskGarminData?.stress,
          deviceSupport: true,
          display: taskGarminDeviceFromMethod?.stressEnable,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Steps',
          collected: wearingDevice && !!taskGarminData?.steps,
          deviceSupport: true,
          display: taskGarminDeviceFromMethod?.stepsEnable,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Respiration',
          collected: wearingDevice && !!taskGarminData?.respiration,
          deviceSupport: devicesSupportTable(deviceType, 'Respiration'),
          display: taskGarminDeviceFromMethod?.respirationEnable,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Oxygen Saturation',
          collected: wearingDevice && !!taskGarminData?.pulseOx,
          deviceSupport: devicesSupportTable(deviceType, 'Oxygen Saturation'),
          display: taskGarminDeviceFromMethod?.pulseOxEnable,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Actigraphy',
          collected: wearingDevice && !!taskGarminData?.actigraphy,
          // deviceSupport: devicesSupportTable(deviceType, 'Actigraphy'),
          deviceSupport: true,
          display: taskGarminDeviceFromMethod?.actigraphyEnable,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Heart Rate Interval',
          collected: wearingDevice && !!taskGarminData?.bbi,
          deviceSupport: true,
          taskGarminData,
          display: taskGarminDeviceFromMethod?.bbiEnable,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Zero Crossings',
          collected: wearingDevice && !!taskGarminData?.zeroCrossing,
          deviceSupport: true,
          display: taskGarminDeviceFromMethod?.zeroCrossingEnable,
        }}
      />
      <GarminCollectItem
        {...{
          name: 'Skin Temperature',
          collected: wearingDevice && !!taskGarminData?.temperature,
          deviceSupport: true,
          display: taskGarminDeviceFromMethod?.temperatureEnable,
        }}
      />
    </div>
  )
}

interface GarminCollectItemProps {
  name: string
  collected: boolean
  deviceSupport: boolean
  taskGarminData?: AdherenceGarminTask
  display: boolean
}

const GarminCollectItem = (props: GarminCollectItemProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {name, collected, taskGarminData, deviceSupport, display} = props
  const unavailable = !deviceSupport
  return (
    <div
      css={{
        display: display ? 'flex' : 'none',
        flexDirection: 'column',
      }}
    >
      <div
        css={{
          borderTop: `1px solid ${color.grey_100}`,
          padding: '12px 0 8px 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div css={{display: 'flex', alignItems: 'center'}}>
          <div
            css={{
              background: collected ? color.task.green : color.disabled,
              width: '7px',
              height: '7px',
              marginRight: '6px',
            }}
          />
          <div
            css={{
              color: collected ? '' : color.grey_400,
              fontWeight: fontWeight.medium,
              fontSize: fontSize.h7,
            }}
          >
            {name}
          </div>
        </div>
        <div
          css={{
            display: 'flex',
            fontWeight: fontWeight.medium,
            fontSize: fontSize.h7,
            color: collected ? color.task.green : color.grey_400,
          }}
        >
          {unavailable ? 'Not available' : collected ? 'Collected' : '--'}
          {RIF(
            unavailable,
            <Tooltip
              css={{marginLeft: '6px'}}
              pseudoPosition='90%'
              content="This participant's Garmin device doesn't collect this type of data."
            />,
          )}
        </div>
      </div>
      {RIF(
        name === 'Heart Rate Interval' && collected,
        <div
          css={{
            paddingBottom: '12px',
            paddingLeft: '13px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div css={{fontSize: fontSize.h7, display: 'flex'}}>
            Total Accumulated BBI
            <Tooltip
              css={{marginLeft: '6px'}}
              content='Total accumulated BBI refers to the total amount of time BBI has been collected so far today. A lower than average total could signal the participant is not wearing their smartwatch as often.'
            />
          </div>
          <div css={{color: color.primary, fontWeight: fontWeight.thick, fontSize: fontSize.h7}}>
            {taskGarminData?.bbiMSAccumulated &&
              (timeConvert({time: taskGarminData?.bbiMSAccumulated, type: TimeConvertType.bbiAccumulated}) as string)}
          </div>
        </div>,
      )}
    </div>
  )
}

interface TaskBlockProps {
  name: string
  done: boolean
  IconIndex: number
  IconColor: string
  adherenceTask?: AdherenceNormalTask
}

const TaskBlock = (props: TaskBlockProps) => {
  const {color, fontSize, fontWeight, colorTranslator} = selectTheme()
  const {name, done, IconIndex, IconColor, adherenceTask} = props
  const method = selectMethod()
  const taskType = adherenceTask?.taskType
  const repetition = adherenceTask?.timestampList?.length
  const taskColor = IconColor ? IconColor : colorTranslator(IconIndex - 1)
  const movesenseTask = _.find(method.taskList, (item) => item.id === adherenceTask?.taskId)?.stopwatchMovesenseStream
  const movesenseWording = movesenseTask && convertMovesenseDescription(movesenseTask)
  const garminStreamTask = _.find(method.taskList, (item) => item.id === adherenceTask?.taskId)?.stopwatchGarminStream
  const garminStreamWording = garminStreamTask && convertGarminStreamDescription(garminStreamTask)

  return (
    <>
      <div
        css={{
          border: `1px ${done ? 'solid' : 'dashed'} ${color.grey_160}`,
          borderRadius: '5px',
          width: '100%',
          marginTop: '10px',
          padding: '15px 20px',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={
              {
                border: !done && `1px dashed ${color.grey_160}`,
                background: done && taskColor,
                borderRadius: '50%',
                width: '18px',
                height: '18px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: fontSize.h7,
                fontWeight: fontWeight.thin,
                color: done ? color.white : color.grey_160,
              } as CSSProperties
            }
          >
            {IconIndex + 1}
          </div>
          <div css={{fontSize: fontSize.h7, color: done ? '' : color.grey_400}}>
            {done ? `${repetition} Time(s)` : 'Not Completed'}
          </div>
        </div>
        <div css={{fontWeight: fontWeight.thin, marginTop: '8px', color: done ? '' : color.grey_400}}>{name}</div>
        {RIF(
          taskType === TaskType.StopwatchMovesenseStream || taskType === TaskType.StopwatchMovesenseLogData,
          <div css={{color: color.grey_600, fontSize: '12px', whiteSpace: 'pre-line', marginTop: '8px'}}>
            {movesenseWording}
          </div>,
        )}
        {RIF(
          taskType === 'stopwatch_garmin_stream',
          <div css={{color: color.grey_600, fontSize: '12px', whiteSpace: 'pre-line', marginTop: '8px'}}>
            {garminStreamWording}
          </div>,
        )}
        {RIF(
          done && (taskType === 'todo' || taskType === 'questionnaire'),
          <div css={{marginTop: '8px'}}>
            {adherenceTask?.timestampList?.map((item: any, index: number) => (
              <li
                key={index}
                css={{
                  paddingTop: '8px',
                  marginTop: '8px',
                  fontSize: fontSize.h7,
                  borderTop: `1px solid ${color.grey_100}`,
                }}
              >
                Task Completed at&nbsp;
                <span css={{fontSize: fontSize.h7, color: color.primary}}>
                  {
                    timeConvert({
                      time: item?.unixTimestampComplete + item.timeOffset,
                      type: TimeConvertType.localizedUtcCustomFormat,
                      arg: 'h:mm aa',
                    }) as string
                  }
                  {/* ({item.timezone}) */}
                </span>
              </li>
            ))}
          </div>,
        )}
        {RIF(
          (done && taskType === 'timer') ||
            taskType === TaskType.StopwatchMovesenseStream ||
            taskType === TaskType.StopwatchMovesenseLogData ||
            taskType === TaskType.StopwatchGarminStream,
          <div css={{marginTop: '8px'}}>
            {adherenceTask?.timestampList?.map((item: any, index: number) => (
              <li
                key={index}
                css={{
                  paddingTop: '8px',
                  marginTop: '8px',
                  fontSize: fontSize.h7,
                  borderTop: `1px solid ${color.grey_100}`,
                }}
              >
                Task Duration:&nbsp;
                <span css={{fontSize: '12px', color: color.primary}}>
                  {
                    timeConvert({
                      time: item?.unixTimestampStart,
                      type: TimeConvertType.timerDuration,
                      arg: item?.unixTimestampComplete,
                    }) as string
                  }
                </span>
                {` (${timeConvert({
                  time: item?.unixTimestampStart + item.timeOffset,
                  type: TimeConvertType.localizedUtcCustomFormat,
                  arg: 'h:mm aa',
                })} - ${timeConvert({
                  time: item?.unixTimestampComplete + item.timeOffset,
                  type: TimeConvertType.localizedUtcCustomFormat,
                  arg: 'h:mm aa',
                })})`}
                {/* ({item.timezone}) */}
              </li>
            ))}
          </div>,
        )}
      </div>
    </>
  )
}

// TODO: update when new launch ,& implement acc & battery percentage
const devicesSupportTable = (deviceType: string, collectData: string) => {
  switch (deviceType) {
    case 'Vivosmart4':
      if (collectData === 'Respiration') return false
      break
    case 'Instinct':
      if (collectData === 'Oxygen Saturation') return false
      if (collectData === 'Respiration') return false
      break
    case 'InstinctSolar':
      if (collectData === 'Respiration') return false
      break
    case 'Forerunner45':
      if (collectData === 'Oxygen Saturation') return false
      if (collectData === 'Respiration') return false
      break
    case 'Forerunner55':
      if (collectData === 'Oxygen Saturation') return false
      break
  }
  return true
}

interface DexcomBlockProps {
  egvCounts: number
  deviceType?: string
}

const DexcomBlock = (props: DexcomBlockProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {egvCounts} = props
  const [collectedAmount, setCollectedAmount] = useState(0)
  const [dataCollected, setDataCollected] = useState(false)

  useEffect(() => {
    setCollectedAmount(egvCounts > 0 ? 1 : 0)
    setDataCollected(egvCounts > 0)
  }, [egvCounts])

  return (
    <div
      css={{
        border: `1px solid ${color.grey_160}`,
        borderRadius: '5px',
        width: '100%',
        marginTop: '10px',
        padding: '20px',
        paddingBottom: '5px',
      }}
    >
      <div css={{display: 'flex', paddingBottom: '15px'}}>
        <img src={DexcomIcon} width='24' height='24' css={{marginRight: '12px'}} />
        <div>
          <div css={{display: 'flex', alignItems: 'center', marginBottom: '6px'}}>
            <div
              css={{
                width: '13px',
                height: '13px',
                borderRadius: '50%',
                paddingBottom: '1px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {/* <img src={GarminLIcon} width='13' />*/}
              <div
                style={
                  {
                    border: `1px ${color.dexcomGreen}`,
                    background: color.dexcomGreen,
                    borderRadius: '50%',
                    width: '13px',
                    height: '13x',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: fontSize.h7,
                    fontWeight: fontWeight.thin,
                    color: color.white,
                  } as CSSProperties
                }
              >
                D
              </div>
            </div>
            <div css={{fontWeight: fontWeight.thin, margin: '0 6px'}}>Dexcom</div>
            <Tooltip content='Data collected from Dexcom app' />
          </div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div css={{background: color.task.green, width: '7px', height: '7px', marginRight: '6px'}} />
            <div css={{fontWeight: fontWeight.medium, fontSize: fontSize.h7}}>
              {collectedAmount}/{1} Metric(s) Collected
            </div>
          </div>
        </div>
      </div>
      <DexcomCollectItem
        {...{
          name: 'Continuous Glucose Reading',
          collected: collectedAmount > 0,
          egvDataCount: egvCounts,
          display: true,
        }}
      />
    </div>
  )
}

interface DexcomCollectItemProps {
  name: string
  collected: boolean
  egvDataCount: number
  display: boolean
}

const DexcomCollectItem = (props: DexcomCollectItemProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {name, collected, egvDataCount, display} = props
  return (
    <div
      css={{
        display: display ? 'flex' : 'none',
        flexDirection: 'column',
      }}
    >
      <div
        css={{
          borderTop: `1px solid ${color.grey_100}`,
          padding: '12px 0 8px 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div css={{display: 'flex', alignItems: 'center'}}>
          <div
            css={{
              background: collected ? color.task.green : color.disabled,
              width: '7px',
              height: '7px',
              marginRight: '6px',
            }}
          />
          <div
            css={{
              color: collected ? '' : color.grey_400,
              fontWeight: fontWeight.medium,
              fontSize: fontSize.h7,
            }}
          >
            {name}

            {RIF(
              collected,
              <div>
                <div css={{fontSize: fontSize.h7, display: 'inline'}}>Estimate collected duration: </div>
                <div
                  css={{
                    color: color.primary,
                    fontWeight: fontWeight.thick,
                    fontSize: fontSize.h7,
                    display: 'inline-block',
                  }}
                >
                  {
                    timeConvert({
                      time: egvDataCount * 300000,
                      type: TimeConvertType.bbiAccumulated,
                    }) as string
                  }
                </div>
              </div>,
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
