import {useState, MouseEventHandler} from 'react'
import {selectTheme} from '../../store'
import {RIF, _} from '../../lib'
import {
  NotificationDefaultIcon,
  NotificationActivatedIcon,
  NotificationWarningDefaultIcon,
  NotificationWarningActivatedIcon,
} from '../../asset/image'

export interface NotificationButtonPropsT {
  enable: boolean
  onClick: MouseEventHandler<HTMLDivElement>
  index?: number
}

export const NotificationButton = (props: NotificationButtonPropsT) => {
  const {fontWeight, color} = selectTheme()
  const [tipPop, setTipPop] = useState(false)
  const {enable, index, ...rest} = props
  const handleOver = _.debounce(() => {
    setTipPop(true)
  }, 100)
  const handleOut = _.debounce(() => {
    setTipPop(false)
  }, 100)

  return (
    <div
      {...rest}
      onMouseEnter={handleOver}
      onMouseLeave={handleOut}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        zIndex: 1,
        position: 'relative',
      }}
    >
      {RIF(!tipPop, <img src={enable ? NotificationDefaultIcon : NotificationWarningDefaultIcon} width='20' />)}
      {RIF(tipPop, <img src={enable ? NotificationActivatedIcon : NotificationWarningActivatedIcon} width='20' />)}
      {RIF(
        tipPop,
        <div
          css={{
            width: 'max-content',
            background: 'rgba(0, 0, 0, 0.88)',
            color: color.white,
            borderRadius: '5px',
            fontWeight: fontWeight.bold,
            padding: '10px 8px',
            textAlign: 'start',
            position: 'absolute',
            top: index === 0 ? '130%' : '-230%',
            right: '50%',
            transform: 'translateX(50%)',
            zIndex: 99,
            ':after': {
              position: 'absolute',
              top: index !== 0 ? '38px' : '-8px',
              right: '50%',
              transform: 'translateX(50%)',
              content: '""',
              borderTop: index !== 0 ? '10px solid rgba(0, 0, 0, 0.88)' : '',
              borderBottom: index !== 0 ? '' : '10px solid rgba(0, 0, 0, 0.88)',
              borderRight: '5px solid transparent',
              borderLeft: '5px solid transparent',
            },
          }}
        >
          Send Message
        </div>,
      )}
    </div>
  )
}
