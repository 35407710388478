import React from 'react'
import {handleEsc} from '../../lib'
import {PopupSimple} from '..'

export interface ParticipantNotificationDiscardPopPropsT {
  setDisplayNotificationDiscardPop: (arg: boolean) => void
  setDisplayNotificationPop: (arg: boolean) => void
}

export const ParticipantNotificationDiscardPop = (props: ParticipantNotificationDiscardPopPropsT) => {
  const {setDisplayNotificationDiscardPop, setDisplayNotificationPop} = props

  handleEsc(setDisplayNotificationDiscardPop)

  const handleDiscardConfirm = () => {
    setDisplayNotificationDiscardPop(false)
    setDisplayNotificationPop(false)
  }

  return (
    <>
      <div
        css={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100vw',
          height: '100vh',
          overflowY: 'scroll',
          background: '#00000040',
          zIndex: 102,
        }}
      >
        <PopupSimple
          {...{
            closeAction: setDisplayNotificationDiscardPop,
            doAction: handleDiscardConfirm,
            name: 'Discard this message',
            description: 'Do you want to discard this message?',
            buttonColor: 'warning',
            buttonText: 'Discard',
          }}
        />
        ,
      </div>
      ,
    </>
  )
}
