import {groupBy, omit, values} from 'lodash'

import {componentsFromString, componentsToMap, ExtendRecordType} from '../../shared/tabulator'

import {t} from '../t'
import {DataDownloaderItem} from '../data_downloader/model'
import {TABULAR_FILE_PATH_COMPONENT_SEP, TimeSeriesTypes} from './model'
import { AnalysisDataTypeValues } from '../../shared/mongo'

export class ItemGrouper {
  group(items: DataDownloaderItem[]): DataDownloaderItem[][] {
    return values(
      groupBy(items, ({key}) => {
        const components = componentsFromString(key, TABULAR_FILE_PATH_COMPONENT_SEP)
        const shouldNotGroup = TimeSeriesTypes.some(
          ({taskType, dataType}) => components.taskType === taskType && components.dataType === dataType,
        ) || ( components.taskType === ExtendRecordType.Analysis)
        
        return shouldNotGroup
          ? t.uuid() // use unique identifier to skip grouping
          : Array.from(componentsToMap(omit(components, 'localDateIndex')).values())
      }),
    )
  }
}
