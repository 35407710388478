import path from 'path'

import { TabularDataType, TabularTaskType } from './type'

export interface TabularFilePathComponents {
  workspace: string
  project: string
  participant: string
  taskType: TabularTaskType
  dataType?: TabularDataType
  task?: string
  completion?: string
  unixTimestampStartInMs?: string
  localDateIndex: string
  sourceDataType?: string
}

export function componentsFromString(
  pathString: string,
  sep: string,
): TabularFilePathComponents {
  const parsed = path.parse(pathString)
  return componentsFromMap(
    new Map(
      parsed.dir
        .split(path.sep)
        .concat(parsed.name)
        .map((path) => {
          const [key, ...values] = path.split(sep)
          return [key as keyof TabularFilePathComponents, values.join(sep)]
        }),
    ),
  )
}

export function componentsToString(
  components: Partial<TabularFilePathComponents>,
  sep: string,
  ext: string,
): string {
  const dirs = Array.from(componentsToMap(components).entries()).map((entry) =>
    entry.join(sep),
  )
  const name = dirs.pop()

  return path.format({
    dir: dirs.length ? path.join(...dirs) : undefined,
    name,
    ext,
  })
}

export function componentsFromMap(
  map: Map<keyof TabularFilePathComponents, string>,
): TabularFilePathComponents {
  function getForced(key: keyof TabularFilePathComponents) {
    const value = map.get(key)
    if (!value) throw new Error(`key ${key} does not exist`)
    return value
  }

  return {
    workspace: getForced('workspace'),
    project: getForced('project'),
    participant: getForced('participant'),
    taskType: getForced('taskType') as TabularTaskType,
    dataType: map.get('dataType') as TabularDataType | undefined,
    task: map.get('task'),
    completion: map.get('completion'),
    unixTimestampStartInMs: map.get('unixTimestampStartInMs'),
    localDateIndex: getForced('localDateIndex'),
  }
}

export function componentsToMap(
  components: Partial<TabularFilePathComponents>,
): Map<keyof TabularFilePathComponents, string> {
  const {
    workspace,
    project,
    participant,
    taskType,
    dataType,
    task,
    completion,
    unixTimestampStartInMs,
    localDateIndex,
    sourceDataType,
  } = components

  const entries: [keyof TabularFilePathComponents, string][] = []
  if (workspace) {
    entries.push(['workspace', workspace])
  }
  if (project) {
    entries.push(['project', project])
  }
  if (participant) {
    entries.push(['participant', participant])
  }
  if (taskType) {
    entries.push(['taskType', taskType])
  }
  if (dataType) {
    if (sourceDataType) {
      entries.push(['dataType', `${dataType}_of_${sourceDataType}`])
    } else {
      entries.push(['dataType', dataType])
    }
  }
  if (task) {
    entries.push(['task', task])
  }
  if (completion) {
    entries.push(['completion', completion])
  }
  if (unixTimestampStartInMs) {
    entries.push(['unixTimestampStartInMs', unixTimestampStartInMs])
  }
  if (localDateIndex) {
    entries.push(['localDateIndex', localDateIndex])
  }

  return new Map(entries)
}
