import {Dispatch, SetStateAction} from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import {getToken} from './token_fetcher'
import {AnalysisDataType} from '../../shared/mongo'

export const REQUEST_PROJECT_ANALYSIS_DATA_REQUEST = 'REQUEST_PROJECT_ANALYSIS_DATA_REQUEST'
export const doREQUEST_PROJECT_ANALYSIS_DATA_REQUEST = createDoRequestAction(REQUEST_PROJECT_ANALYSIS_DATA_REQUEST)

export const requestProjectAnalysisDataRequestActionCreators = {
  doREQUEST_PROJECT_ANALYSIS_DATA_REQUEST,
}

interface RequestProjectAnalysisDataRequestPayload {
  payload: {
    requestId: string
    projectId: string
    analysisDataTypeList: AnalysisDataType[]
    yymmddRange?: [number, number]
  }
  type: string
  setRequestResult?: Dispatch<SetStateAction<RequestResult>>
}

export function* requestProjectAnalysisDataRequest({
  payload,
  setRequestResult,
}: RequestProjectAnalysisDataRequestPayload): SagaIterator {
  debug('saga*requestProjectAnalysisDataRequest')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
        analysisDataTypeList: v.array().items(v.string()).required(),
        yymmddRange: v.array().items(v.number()).optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_PROJECT_ANALYSIS_DATA_REQUEST,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const {projectId, analysisDataTypeList, yymmddRange} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/project-analysis-data-request`,
    accessToken,
    data: {
      projectId,
      analysisDataTypeList,
      yymmddRange,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_PROJECT_ANALYSIS_DATA_REQUEST,
          fromPayload: payload,
          requestId: payload.requestId,
          url: '/v1/web/project-analysis-data-request',
          ...result,
          error,
        },
        setRequestResult,
      ),
    )
  }

  // const {duplicated, job} = result.payload as Pick<DataDownloadStatusData, 'job'> & {duplicated: boolean}

  // const dataDownloadStatus: DataDownloadStatusData = {
  //   projectId,
  //   lastUpdate: null,
  //   job,
  //   duplicated,
  // }
  // yield put(doDATA_DOWNLOAD_STATUS_SET(dataDownloadStatus))

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: REQUEST_PROJECT_ANALYSIS_DATA_REQUEST,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/project-analysis-data-request',
        ...result,
      },
      setRequestResult,
    ),
  )
}

export function* ProjectAnalysisDataRequestSaga() {
  yield takeEvery(REQUEST_PROJECT_ANALYSIS_DATA_REQUEST, requestProjectAnalysisDataRequest)
}
