import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import CronParser from 'cron-parser'

import {createDispatchActions, selectMethod, selectQuestionnaires, selectTheme} from '../../store'
import {
  _,
  collaboratorTypeHasRole,
  RIF,
  sortBy,
  t,
  useCollaboratorTypeInProject,
  useCurrentProjectState,
  useCurrentWorkspaceState,
} from '../../lib'
import {
  convertGarminDescription,
  convertGarminStreamDescription,
  convertMovesenseDescription,
  ProjectEditPop,
  QuestionnairePage,
  SaveBar,
  Tooltip,
} from '..'

import {
  AboutProjectIcon,
  BellIcon,
  ContactsIcon,
  DeviceIcon,
  DexcomImg,
  EditWhiteIcon,
  InstructionIcon,
  ParticipantGreyIcon,
  ParticipantTaskIcon,
  RepeatIcon,
  SurveyBlackIcon,
  TimerBlackIcon,
  WarningIcon,
} from '../../asset/image'

import GarminDeviceImg from '../../asset/image/garmin_device.png'
import MovesenseImg from '../../asset/image/movesense.png'
import {
  ContentListItem,
  ContentListItemCategory,
  DexcomIntegration,
  getTaskContentName,
  Question,
  Questionnaire,
  Section,
  TaskReminder,
  TaskStateType,
  TaskType,
} from '../../model'
import {ProjectCollaboratorRole, WorkspaceCollaboratorRole} from '../../shared/db'

export const ProjectSummaryPage = (props: {setDisplaySummaryPage: (b: boolean) => void}) => {
  const {pad, color, fontWeight, colorTranslator, fontSize} = selectTheme()

  const {
    doREQUEST_METHOD_FETCH,
    doQUESTIONNAIRES_EDITING_STATUS_SET,
    doQUESTIONNAIRES_SET,
    doQUESTIONNAIRES_QUESTION_SET,
    doQUESTIONNAIRES_SECTION_SET,
    doREQUEST_DEXCOM_INTEGRATION_LIST_FETCH,
  }: any = createDispatchActions()

  /* ------------------ Basic ------------------ */
  const methodState = selectMethod()
  const {projectId, project} = useCurrentProjectState()
  const methodId = project?.methodList?.[0].id
  const questionnairesState = selectQuestionnaires()
  const editingQuestionnaireId = questionnairesState.editing.editingQuestionnaireId
  const {workspaceId, workspace: currentWorkspace} = useCurrentWorkspaceState()
  const collaboratorType = useCollaboratorTypeInProject()
  const canEditProjectTasks = collaboratorTypeHasRole({
    collaboratorType,
    projectRole: ProjectCollaboratorRole.TaskCreate,
    workspaceRole: WorkspaceCollaboratorRole.TaskCreate,
  })

  const garminConnectEnable = methodState?.garminConnectEnable
  const garminDeviceTask = _.find(methodState?.taskList, {type: TaskType.GarminDevice})
  const garminDeviceEnable =
    methodState?.garminDeviceEnable ||
    _.filter(garminDeviceTask?.garminDevice, (item) => {
      return typeof item === 'boolean' && item === true
    }).length > 0
  const garminStreamEnable = methodState?.garminStreamEnable
  // const garminDeviceEnable = _.filter(garminDeviceTask?.garminDevice, (item) => {
  //   return typeof item === 'boolean' && item === true
  // }).length > 0
  const movesenseDeviceEnable = methodState?.movesenseDeviceEnable

  const {dexcomIntegrationId} = methodState
  const collection: DexcomIntegration[] = currentWorkspace?.dexcomIntegrationList ?? []
  const dexcomIntegration = dexcomIntegrationId ? _.find(collection, {id: dexcomIntegrationId}) : undefined
  const TOOLTIP_CONTENT_IF_CLIENT_ID_NOT_APPROVED =
    'Your application is waiting for Dexcom approval. Please check your email for update information. Contact us at hello@labfront.com if you have any other questions.'
  const deviceEnable =
    garminConnectEnable || garminDeviceEnable || garminStreamEnable || movesenseDeviceEnable || dexcomIntegrationId

  const taskList: TaskStateType[] = methodState?.taskList
    ?.filter((item: TaskStateType) => item.type !== TaskType.GarminDevice && item.enabled)
    ?.sort(sortBy('index'))
  const hiddenTaskList: TaskStateType[] = methodState?.taskList
    ?.filter((item: TaskStateType) => item.type !== TaskType.GarminDevice && !item.enabled)
    ?.sort(sortBy('index'))

  const [showHiddenTask, setShowHiddenTask] = useState(false)
  const [displayEditPopup, setDisplayEditPopup] = useState(false)
  const garminDescription = convertGarminDescription(
    garminDeviceTask?.garminDevice,
    garminDeviceEnable,
    garminStreamEnable,
  )
  /* ------------------ Default Effect ------------------ */
  useEffect(() => {
    if (methodId) {
      doREQUEST_METHOD_FETCH({
        payload: {
          methodId,
        },
      })
    }
  }, [methodId])

  useEffect(() => {
    if (workspaceId) {
      doREQUEST_DEXCOM_INTEGRATION_LIST_FETCH({
        payload: {
          workspaceId,
        },
      })
    }
  }, [workspaceId])

  // QN
  const viewQuestionnaire = (questionnaire: Questionnaire) => {
    const findQuestionnaireResult = _.find(questionnairesState.questionnaireList, ['id', questionnaire.id])
    if (!findQuestionnaireResult || !findQuestionnaireResult.contentList) {
      const questionnaireCopy = JSON.parse(JSON.stringify(questionnaire))
      const contentList: ContentListItem[] = []
      questionnaireCopy.sectionList.map((section: Section) => {
        section.id = t.uuid()
        section.questionnaireId = questionnaire.id
        contentList.push({
          id: section.id,
          category: ContentListItemCategory.Section,
        })
        section.questionList?.map((question: Question) => {
          question.sectionId = section.id
          question.id = t.uuid()
          contentList.push({
            id: question.id,
            sectionId: section.id,
            category: ContentListItemCategory.Question,
          })
          doQUESTIONNAIRES_QUESTION_SET(question)
        })
        doQUESTIONNAIRES_SECTION_SET(section)
      })
      doQUESTIONNAIRES_SET({
        ...questionnaireCopy,
        methodId: methodId,
        contentList: contentList,
      })
    }
    doQUESTIONNAIRES_EDITING_STATUS_SET({editingQuestionnaireId: questionnaire.id, viewOnly: true})
  }

  const generateReminderString = (reminder: TaskReminder) => {
    if (reminder.type === 'cron') {
      const cronInterval = CronParser.parseExpression(reminder.value)
      return formatCronString(cronInterval)
    } else if (reminder.type === 'date') {
      return formatISOString(reminder.value)
    }
  }

  const formatISOString = (dateString: string) => {
    const utcDate = new Date(dateString)
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
    const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000
    return new Date(utcDate.getTime() + timezoneOffset).toLocaleString(undefined, options)
  }

  const formatCronString = (cronParseObj: any) => {
    const {dayOfWeek, hour, minute} = cronParseObj.fields

    const timeString = `${hour[0].toString().padStart(2, '0')}:${minute[0].toString().padStart(2, '0')}`
    const AMPM = hour[0] < 12 ? 'AM' : 'PM'

    // (0 or 7 is Sun) from 'cron-parser'
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const formattedDaysOfWeek = dayOfWeek.map((index: number) => daysOfWeek[index])

    let formattedString = 'Repeats'

    if (dayOfWeek.length >= 7) {
      formattedString += ` daily at ${timeString} ${AMPM}`
    } else if (dayOfWeek.length === 1) {
      formattedString += ` every ${formattedDaysOfWeek[0]} at ${timeString} ${AMPM}`
    } else {
      const lastDayOfWeek = formattedDaysOfWeek.pop()
      formattedString += ` every ${formattedDaysOfWeek.join(', ')} and ${lastDayOfWeek} at ${timeString} ${AMPM}`
    }
    return formattedString
  }

  return (
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
        zIndex: 102,
        background: color.background,
        marginTop: '30px',
        padding: '60px 20px',
      }}
    >
      <SaveBar
        closeAction={() => props.setDisplaySummaryPage(false)}
        hideButton={!canEditProjectTasks}
        handleClickSave={() => setDisplayEditPopup(true)}
        buttonText={
          <>
            <img src={EditWhiteIcon} width='13' css={{marginRight: '6px'}} />
            <div css={{color: color.white}}>Edit Live Project (beta)</div>
          </>
        }
      />

      {RIF(displayEditPopup, <ProjectEditPop closeAction={setDisplayEditPopup} />)}

      <main
        css={{
          margin: '0 auto 100px',
          width: '60%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div css={{fontSize: '20px', fontWeight: fontWeight.thick}}>Project Summary</div>

        {/* about project block */}
        <SummarySection
          {...{
            icon: AboutProjectIcon,
            title: 'About Project',
            html: (
              <div css={{padding: '24px 32px', display: 'flex', justifyContent: 'space-between'}}>
                <div css={{width: '40%'}}>
                  <div css={{fontWeight: fontWeight.bold, color: color.grey_400}}>Name of this Study</div>
                  <div css={{margin: '8px auto 32px', wordBreak: 'break-word'}}>{project?.name}</div>
                  <div css={{fontWeight: fontWeight.bold, color: color.grey_400}}>Principal Investigator</div>
                  <div css={{margin: '8px auto 32px', wordBreak: 'break-word'}}>{project?.investigator}</div>
                  <div css={{fontWeight: fontWeight.bold, color: color.grey_400}}>Organization or Institution</div>
                  <div css={{marginTop: '8px', wordBreak: 'break-word'}}>{project?.organization}</div>
                </div>
                <div css={{width: 'calc(60% - 80px)'}}>
                  <div css={{fontWeight: fontWeight.bold, color: color.grey_400}}>Introduction</div>
                  <div css={{margin: '8px auto 0', wordBreak: 'break-word', whiteSpace: 'pre-wrap'}}>
                    {project?.description}
                  </div>
                </div>
              </div>
            ),
          }}
        />

        {/* device block */}
        {RIF(
          deviceEnable,
          <SummarySection
            {...{
              icon: DeviceIcon,
              title: 'Devices and Data',
              html: (
                <>
                  {/* garmin device */}
                  {RIF(
                    garminDeviceEnable || garminConnectEnable || garminStreamEnable,
                    <div
                      css={{
                        margin: '0px 32px 24px',
                        borderRadius: '5px',
                        border: `1px solid ${color.grey_160}`,
                      }}
                    >
                      <div
                        css={{
                          height: '70px',
                          borderBottom: `1px solid ${color.grey_160}`,
                          padding: '18px 26px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <img src={GarminDeviceImg} width='65' height='65' />
                        <div css={{marginLeft: '16px', fontSize: '16px', fontWeight: fontWeight.thick}}>
                          Garmin Devices
                        </div>
                      </div>
                      <div css={{padding: '20px 32px'}}>
                        {RIF(
                          garminConnectEnable,
                          <div css={{marginBottom: '24px'}}>
                            <div css={{color: color.grey_400}}>Collect data from Garmin Connect app</div>
                            <div css={{marginTop: '8px'}}>
                              Daily Summary (Daily), Activity Summary (15 min), Sleep (Auto-Detect), Heart Rate (1 min),
                              Stress (3 min), Steps (3 min), Respiration (3 min), Oxygen Saturation (3 min)
                            </div>
                          </div>,
                        )}

                        {RIF(
                          garminDescription.trim().length !== 0,
                          <div>
                            <div css={{color: color.grey_400}}>Collect data from Labfront Companion app</div>
                            <div css={{marginTop: '8px'}}>
                              {garminDescription.trim().slice(0, -1).replace(/\s+/g, ' ')}
                            </div>
                          </div>,
                        )}
                      </div>
                    </div>,
                  )}

                  {/* movesense device */}
                  {RIF(
                    movesenseDeviceEnable,
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '0px 32px 24px',
                        padding: '18px 26px',
                        borderRadius: '5px',
                        border: `1px solid ${color.grey_160}`,
                      }}
                    >
                      <img src={MovesenseImg} width='44' height='44' css={{transform: 'scale(1.2)'}} />
                      <span css={{marginLeft: '18px', fontSize: '16px', fontWeight: '700'}}>Movesense</span>
                    </div>,
                  )}

                  {/* dexcom */}
                  {RIF(
                    dexcomIntegrationId,
                    <div
                      css={{
                        borderRadius: '5px',
                        border: `1px solid ${color.grey_160}`,
                        minHeight: '90px',
                        margin: '0px 32px 24px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '16px',
                        outline: 'none',
                        background: '#fff',
                      }}
                    >
                      <div css={{display: 'flex', alignItems: 'center'}}>
                        <img src={DexcomImg} width='64' />
                        <div css={{marginLeft: '8px'}}>
                          <p
                            css={{
                              fontWeight: fontWeight.bold,
                              fontSize: '16px',
                              textAlign: 'start',
                            }}
                          >
                            Dexcom
                          </p>
                          <p
                            css={{
                              fontWeight: fontWeight.medium,
                              fontSize: fontSize.h7,
                              color: color.grey_400,
                              textAlign: 'start',
                              margin: '6px 0',
                            }}
                          >
                            {dexcomIntegration?.name}
                          </p>
                          <div
                            css={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {RIF(
                              dexcomIntegration?.dexcomApp,
                              <p
                                css={{
                                  fontWeight: fontWeight.medium,
                                  color: color.grey_600,
                                  textAlign: 'start',
                                }}
                              >
                                <span css={{whiteSpace: 'nowrap', color: color.highlightGreen}}>Approved -&nbsp;</span>
                                Client ID: {dexcomIntegration?.dexcomApp?.dexcomIntegrationId}
                              </p>,
                            )}
                            {RIF(
                              !dexcomIntegration?.dexcomApp,
                              <div
                                css={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <img src={WarningIcon} width={16} />
                                <p
                                  css={{
                                    marginLeft: '4px',
                                    fontWeight: fontWeight.medium,
                                    color: color.warning,
                                  }}
                                >
                                  Awaiting approval from Dexcom
                                </p>
                              </div>,
                            )}
                            <div
                              css={{
                                marginLeft: '4px',
                              }}
                            >
                              <Tooltip
                                {...{
                                  content: TOOLTIP_CONTENT_IF_CLIENT_ID_NOT_APPROVED,
                                }}
                              />
                            </div>
                          </div>
                          <div
                            css={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <div
                              css={{
                                marginTop: '16px',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                display: 'flex',
                                alignItems: 'center',
                                background: color.background,
                                width: '181px',
                              }}
                            >
                              <img src={ParticipantGreyIcon} width={20} />
                              <p
                                css={{
                                  marginLeft: '8px',
                                  fontWeight: fontWeight.medium,
                                  color: color.grey_400,
                                }}
                              >
                                {dexcomIntegration?.dexcomApp?.totalAccounts} Participant Joined
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>,
                  )}
                </>
              ),
            }}
          />,
        )}

        {/* participant Tasks block */}
        <SummarySection
          {...{
            icon: ParticipantTaskIcon,
            title: 'Participant Tasks',
            html: (
              <div css={{padding: '24px 32px'}}>
                <div css={{marginBottom: pad.slightlyLarger}}>
                  {taskList?.map((item, index) => {
                    const taskContentName = getTaskContentName(item.type)
                    return (
                      <div
                        key={item?.id}
                        css={{
                          border: `1px solid ${color.grey_160}`,
                          borderRadius: '5px',
                          borderLeft: item.color ? `6px solid #${item.color}` : `6px solid ${colorTranslator(index)}`,
                          padding: '18px 16px 18px 10px',
                          marginBottom: '16px',
                          display: 'flex',
                          width: '100%',
                        }}
                      >
                        <img
                          width='20'
                          height='20'
                          src={
                            item.type === TaskType.Todo
                              ? ParticipantTaskIcon
                              : item.type === TaskType.Timer
                              ? TimerBlackIcon
                              : item.type === TaskType.Questionnaire
                              ? SurveyBlackIcon
                              : item.type === TaskType.StopwatchGarminStream
                              ? DeviceIcon
                              : MovesenseImg
                          }
                          css={{
                            transform:
                              item.type === TaskType.StopwatchMovesenseStream ||
                              item.type === TaskType.StopwatchMovesenseLogData
                                ? 'scale(1.2)'
                                : 'scale(1)',
                          }}
                        />
                        <div css={{marginLeft: '16px', width: '100%'}}>
                          <div css={{fontWeight: fontWeight.thick}}>{item[taskContentName]?.name}</div>
                          <div css={{margin: '5px auto 8px', display: 'flex', alignItems: 'center'}}>
                            {RIF(item.scheduleRepeat, <img src={RepeatIcon} width='14' css={{marginRight: '3px'}} />)}
                            <span css={{fontSize: '12px', color: color.grey_400}}>{item.scheduleDescription}</span>
                          </div>
                          <div css={{fontWeight: fontWeight.bold, wordBreak: 'break-word', whiteSpace: 'pre-wrap'}}>
                            {item[taskContentName]?.description}
                          </div>
                          {RIF(
                            item.type === TaskType.Questionnaire,
                            <button
                              type='button'
                              onClick={() => {
                                viewQuestionnaire(item.questionnaire)
                              }}
                              css={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                                height: '48px',
                                borderRadius: '5px',
                                padding: pad.medium,
                                marginTop: pad.slightlyLarger,
                                backgroundColor: color.white,
                                border: '1px solid',
                                borderColor: color.disabled,
                                cursor: 'pointer',
                                ':hover': {
                                  borderColor: color.grey_400,
                                },
                              }}
                            >
                              <p
                                css={{
                                  fontWeight: fontWeight.medium,
                                }}
                              >
                                {item.questionnaire?.name}
                              </p>
                              <p
                                css={{
                                  fontWeight: fontWeight.thick,
                                  color: color.primary,
                                }}
                              >
                                View
                              </p>
                            </button>,
                          )}
                          {RIF(
                            item.type === TaskType.StopwatchMovesenseStream ||
                              item.type === TaskType.StopwatchMovesenseLogData ||
                              item.type === TaskType.StopwatchGarminStream,
                            <div
                              css={{
                                background: color.background,
                                borderRadius: '8px',
                                padding: '8px 12px',
                                marginTop: '16px',
                                color: color.grey_600,
                                fontSize: '12px',
                              }}
                            >
                              {(item.type === TaskType.StopwatchMovesenseStream ||
                                item.type === TaskType.StopwatchMovesenseLogData) &&
                                convertMovesenseDescription(item[taskContentName])}
                              {item.type === TaskType.StopwatchGarminStream &&
                                convertGarminStreamDescription(item[taskContentName])}
                            </div>,
                          )}
                          {item.reminderList?.map((reminder) => (
                            <div
                              css={{
                                background: color.background,
                                borderRadius: '8px',
                                padding: '8px 12px',
                                marginTop: '8px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <img src={BellIcon} width='18' css={{marginRight: '5px'}} />
                              <p
                                css={{
                                  color: color.grey_600,
                                  fontSize: '12px',
                                }}
                              >
                                {generateReminderString(reminder)}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  })}
                </div>
                {RIF(
                  hiddenTaskList.length > 0,
                  <div
                    css={{
                      width: '100%',
                      textAlign: 'end',
                    }}
                  >
                    <a
                      css={{
                        color: color.grey_600,
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        textDecorationColor: color.grey_600,
                        ':hover': {
                          color: color.black,
                          textDecorationColor: color.black,
                        },
                      }}
                      onClick={() => {
                        setShowHiddenTask((prev) => !prev)
                      }}
                    >
                      {showHiddenTask ? 'Hide' : 'See'} Hidden tasks
                    </a>
                  </div>,
                )}
                {RIF(
                  showHiddenTask,
                  <div
                    css={{
                      width: '100%',
                      borderRadius: '5px',
                      border: `1px dashed ${color.grey_300}`,
                      padding: '32px',
                      marginTop: '24px',
                    }}
                  >
                    <p
                      css={{
                        fontWeight: fontWeight.medium,
                        marginBottom: '24px',
                      }}
                    >
                      The following tasks are hidden from participant app:
                    </p>
                    {hiddenTaskList.map((item) => {
                      const taskContentName = getTaskContentName(item.type)
                      return (
                        <div
                          key={item?.id}
                          css={{
                            border: `1px solid ${color.disabled}`,
                            borderRadius: '5px',
                            borderLeft: `6px solid ${color.disabled}`,
                            padding: '18px 16px 18px 10px',
                            marginBottom: '16px',
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          <img
                            width='20'
                            height='20'
                            src={
                              item.type === TaskType.Todo
                                ? ParticipantTaskIcon
                                : item.type === TaskType.Timer
                                ? TimerBlackIcon
                                : item.type === TaskType.Questionnaire
                                ? SurveyBlackIcon
                                : item.type === TaskType.StopwatchGarminStream
                                ? DeviceIcon
                                : MovesenseImg
                            }
                          />
                          <div css={{marginLeft: '16px', width: '100%'}}>
                            <div css={{fontWeight: fontWeight.thick}}>{item[taskContentName]?.name}</div>
                            <div css={{margin: '5px auto 8px', display: 'flex', alignItems: 'center'}}>
                              {RIF(item.scheduleRepeat, <img src={RepeatIcon} width='14' css={{marginRight: '3px'}} />)}
                              <span css={{fontSize: '12px', color: color.grey_400}}>{item.scheduleDescription}</span>
                            </div>
                            <div css={{fontWeight: fontWeight.bold, wordBreak: 'break-word', whiteSpace: 'pre-wrap'}}>
                              {item[taskContentName]?.description}
                            </div>
                            {RIF(
                              item.type === TaskType.Questionnaire,
                              <button
                                type='button'
                                onClick={() => {
                                  viewQuestionnaire(item.questionnaire)
                                }}
                                css={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  height: '48px',
                                  borderRadius: '5px',
                                  padding: pad.medium,
                                  marginTop: pad.slightlyLarger,
                                  backgroundColor: color.white,
                                  border: '1px solid',
                                  borderColor: color.disabled,
                                  cursor: 'pointer',
                                  ':hover': {
                                    borderColor: color.grey_400,
                                  },
                                }}
                              >
                                <p
                                  css={{
                                    fontWeight: fontWeight.medium,
                                  }}
                                >
                                  {item.questionnaire?.name}
                                </p>
                                <p
                                  css={{
                                    fontWeight: fontWeight.thick,
                                    color: color.primary,
                                  }}
                                >
                                  View
                                </p>
                              </button>,
                            )}
                            {RIF(
                              item.type === TaskType.StopwatchMovesenseStream ||
                                item.type === TaskType.StopwatchMovesenseLogData ||
                                item.type === TaskType.StopwatchGarminStream,
                              <div
                                css={{
                                  background: color.background,
                                  borderRadius: '8px',
                                  padding: '8px 12px',
                                  marginTop: '16px',
                                  color: color.grey_600,
                                  fontSize: '12px',
                                }}
                              >
                                {(item.type === TaskType.StopwatchMovesenseStream ||
                                  item.type === TaskType.StopwatchMovesenseLogData) &&
                                  convertMovesenseDescription(item[taskContentName])}
                                {item.type === TaskType.StopwatchGarminStream &&
                                  convertGarminStreamDescription(item[taskContentName])}
                              </div>,
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>,
                )}
              </div>
            ),
          }}
        />
        {/* Instructions block */}
        <SummarySection
          {...{
            icon: InstructionIcon,
            title: 'Instructions',
            html: <div css={{padding: '24px 32px', display: 'flex'}}>{project?.participantInstructions}</div>,
          }}
        />

        {/* Contacts block */}
        <SummarySection
          {...{
            icon: ContactsIcon,
            title: 'Contacts',
            html: <div css={{padding: '24px 32px', display: 'flex'}}>{project?.contactDescription}</div>,
          }}
        />
      </main>
      {RIF(editingQuestionnaireId, <QuestionnairePage />)}
    </div>
  )
}

interface SummarySectionPropsT {
  icon: any
  title: string
  html: any
}

const SummarySection = (props: SummarySectionPropsT) => {
  const {color, fontWeight} = selectTheme()
  const {icon, title, html} = props
  return (
    <section
      css={{
        marginTop: '24px',
        borderRadius: '5px',
        width: '100%',
        background: color.white,
        boxShadow: '0px 4px 12px 0px #D4D4D440',
      }}
    >
      <div
        css={{
          height: '90px',
          display: 'flex',
          alignItems: 'center',
          borderBottom: `1px solid ${color.grey_100}`,
          marginBottom: '24px',
          padding: '24px 32px',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: color.background,
            marginRight: '16px',
            width: '40px',
            height: '40px',
            borderRadius: '5px',
          }}
        >
          <img src={icon} width='24' />
        </div>
        <div css={{fontSize: '18px', fontWeight: fontWeight.thick}}>{title}</div>
      </div>
      {html}
    </section>
  )
}
