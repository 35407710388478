import {useState, useEffect, createRef, useMemo, Dispatch, SetStateAction, ChangeEvent, MouseEvent, useRef} from 'react'
import {Link} from 'react-router-dom'
import {
  RIF,
  timeConvert,
  useClickOutside,
  TimeConvertType,
  sortBy,
  useSearch,
  _,
  t,
  useCurrentProjectState,
} from '../../lib'
import {Scrollbars} from 'react-custom-scrollbars-2'

import {
  TaskStateType,
  Adherence,
  SidebarContent,
  GarminDirect,
  ParticipantSelected,
  TaskType,
  getTaskContentName,
  ThirdPartyTaskType,
} from '../../model'
import {
  selectTheme,
  selectMethod,
  selectBatchData,
  selectAdherenceData,
  createDispatchActions,
  selectLoading,
  SidebarContentIndex,
  ParticipantDataDigestMap,
} from '../../store'
import {
  Button,
  ProjectSwitchBar,
  Input,
  AdherenceTableEntry,
  ViewMenu,
  AdherenceSidebar,
  ButtonReverse,
  ParticipantNotificationPop,
  ParticipantNotificationDiscardPop,
  ParticipantNotificationInfoPop,
  DataDownloadPage,
  AdherenceType,
} from '..'
import {
  SearchIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  GarminGIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '../../asset/image'
import AdherenceBackground from '../../asset/image/adherence_background.png'
import {AttemptRequestParticipantStatus} from './request_project_data_hook'
import {IParticipant} from '../../shared/db'

export const AdherencePage = () => {
  const {color, fontWeight, fontSize, colorTranslator} = selectTheme()

  /* ------------------ req ------------------ */
  const {doREQUEST_PROJECT_DATA_DIGEST, doADHERENCE_CURRENT_SIDEBAR_CONTENT_INDEX_SET}: any = createDispatchActions()

  /* ------------------ reducer & store basic state ------------------ */
  const {projectId, project} = useCurrentProjectState()
  const projectCreateAt = project?.createdAt
  const batchId = project?.batchList?.[0]?.id ?? ''
  const batch = selectBatchData()?.[batchId]
  const participantList = batch?.participantList
  const method = selectMethod()
  // const devState = selectDevState()
  const loadingState = selectLoading()

  /* ------------------ adherence state ------------------ */
  const projectAdherence = selectAdherenceData()?.[projectId ?? '']
  const currentAdherenceList: Adherence[] = selectAdherenceData()?.[projectId ?? '']?.adherenceList || []
  const participantDataDigestMap: ParticipantDataDigestMap =
    selectAdherenceData()?.[projectId ?? '']?.projectDataDigest?.participantDataDigestMap || {}
  const currentSidebarContentIndex: SidebarContentIndex | undefined =
    selectAdherenceData()?.[projectId ?? '']?.currentSidebarContentIndex
  const lastDigestDayDetailUpdatedTime = selectAdherenceData()?.[projectId ?? '']?.lastDigestDayDetailUpdatedTime
  const [displayAdherence, setDisplayAdherence] = useState<Adherence[]>([])
  const [displayYYMMDDRange, setDisplayYYMMDDRange] = useState<number[]>()
  const {
    searchValue,
    searchResult: searchedAdherenceList,
    setSearchValue,
  } = useSearch('status.participantInsignia', currentAdherenceList)
  const requstGarminBbiDigestRange = useMemo(() => {
    if (displayYYMMDDRange) {
      return {yymmddIndexStart: displayYYMMDDRange[0], yymmddIndexEnd: displayYYMMDDRange[1]}
    }
  }, [displayYYMMDDRange])

  const garminConnect: boolean = method?.garminConnectEnable
  // const garminDeviceTask: any = method?.taskList?.find((item: TaskStateType) => item.type === 'garmin_device') // TODO: check type
  const garminDeviceTask: TaskStateType | undefined = _.find(method?.taskList, {type: TaskType.GarminDevice})
  const dexcomIntegrationId = method?.dexcomIntegrationId
  const taskList: TaskStateType[] = method?.taskList?.filter(
    (item: TaskStateType) =>
      item.type !== TaskType.GarminDevice && item.type !== ThirdPartyTaskType.Dexcom && item.enabled,
  )
  const [hasGarminDirect, setHasGarminDirect] = useState(false)

  // sorted participants and tasks
  const sortedParticipantList: IParticipant[] = useMemo(
    () => participantList?.sort(sortBy('createdAt')) ?? [],
    [participantList],
  )
  const sortedTaskListList: TaskStateType[] = useMemo(() => taskList?.sort(sortBy('index')), [taskList])

  // UI
  const [displayViewMenu, setDisplayViewMenu] = useState(false)
  const [viewBtnPosition, setViewBtnPosition] = useState({xPos: 0, yPos: 0})
  const [displaySidebar, setDisplaySidebar] = useState(false)
  const [renderDataDownloadPage, setRenderDataDownloadPage] = useState(false)
  const [highLightBox, setHighLightBox] = useState('')
  const [selectedAdherenceType, setSelectedAdherenceType] = useState<AdherenceType>(AdherenceType.TaskCompletion)
  const sidebarRef: any = createRef()
  const viewMenuRef: any = createRef()
  useClickOutside(viewMenuRef, () => setDisplayViewMenu(false))
  useClickOutside(sidebarRef, () => setDisplaySidebar(false))
  const [isViewMenuHover, setIsViewMenuHover] = useState(false)

  // viewTag container and sidebar state
  const [viewBy, setViewBy] = useState<'DeviceDataType' | 'Tasks'>('Tasks')
  const [viewTagList, setViewTagList] = useState<any[]>([])
  const [viewDatatype, setViewDatatype] = useState<GarminDirect>('')
  const [sidebarContent, setSidebarContent] = useState<SidebarContent | any>({})
  const setCurrentSidebarContentIndex = (participantId: string, yymmddIndex: number) => {
    doADHERENCE_CURRENT_SIDEBAR_CONTENT_INDEX_SET({
      projectId,
      sidebarContentIndex: {participantId, yymmddIndex},
    })
  }
  // swap week
  const currentFirstDayOfWeek = timeConvert({time: new Date(), type: TimeConvertType.firstDayOfWeekLocal}) as Date

  const [firstDayOfWeek, setFirstDayOfWeek] = useState(currentFirstDayOfWeek)
  const [swapWeek, setSwapWeek] = useState('')

  const [selectedList, setSelectedList] = useState<ParticipantSelected[]>([])
  const [isHeaderSelectBtnClicked, setIsHeaderSelectBtnClicked] = useState(false)

  // notification state
  const [notificationList, setNotificationList] = useState<ParticipantSelected[]>([])
  const [notificationInfo, setNotificationInfo] = useState<{[key: string]: any}>({})
  const [displayNotificationPop, setDisplayNotificationPop] = useState(false)
  const [displayNotificationDiscardPop, setDisplayNotificationDiscardPop] = useState(false)
  const [displayNotificationInfoPop, setDisplayNotificationInfoPop] = useState(false)

  AttemptRequestParticipantStatus({
    // clearCache: true,
    requestAdherence: {durationBeforeToday: 6},
  })

  AttemptRequestParticipantStatus({
    requestGarminDirectBbiDigest:
      selectedAdherenceType === AdherenceType.GarminDirectBbiDigest ? requstGarminBbiDigestRange : undefined,
  })

  AttemptRequestParticipantStatus({
    requestGarminConnectWearTime:
      selectedAdherenceType === AdherenceType.GarminConnectWearTime ? requstGarminBbiDigestRange : undefined,
  })

  // view tag
  const [viewTagsOverflow, setViewTagsOverflow] = useState<boolean>(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [viewTagsWidthList, setViewTagsWidthList] = useState<any[]>([])
  const [expandingViewTagsList, setExpandingViewTagsList] = useState<boolean>(false)

  useEffect(() => {
    const totalViewTagsWidth = _.sumBy(viewTagsWidthList, (item) => {
      return item.width
    })
    setViewTagsOverflow(totalViewTagsWidth > screenWidth - 202.5)
  }, [viewTagsWidthList, screenWidth])

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  /* ------------------ default effect ------------------ */
  // default hasGarminDirect state
  useEffect(() => {
    setHasGarminDirect(false)
    if (garminDeviceTask) {
      const enabledDataTypes = _.filter(garminDeviceTask?.garminDevice, (item) => {
        return typeof item === 'boolean' && item === true
      }).length
      if (enabledDataTypes > 0) setHasGarminDirect(true)
    }
  }, [garminDeviceTask])

  // sorted display adherence with participant insignia
  useEffect(() => {
    if (sortedParticipantList) {
      const filteredAdherenceList: Adherence[] = searchedAdherenceList
        .filter((c) => !!_.find(sortedParticipantList, ['id', c.participantId]))
        .reverse()
      setDisplayAdherence(filteredAdherenceList)
    }
  }, [searchedAdherenceList, sortedParticipantList, displayYYMMDDRange])

  // viewTagList default setting
  useEffect(() => {
    const tempViewTagList: {id?: string; name: string; color: string; index?: number}[] = []
    if (garminConnect) tempViewTagList.push({name: 'Garmin Connect App', color: color.black})
    if (hasGarminDirect) {
      tempViewTagList.push({name: 'Labfront Companion App', color: color.primary, id: garminDeviceTask?.id})
    }
    if (dexcomIntegrationId) {
      tempViewTagList.push({name: 'Dexcom App', color: color.dexcomGreen, id: dexcomIntegrationId})
    }
    if (taskList?.length) {
      const tempArr: any[] = []
      sortedTaskListList
        ?.filter((task) => !!task.type)
        .map((item, index) => {
          tempArr.push({
            id: item.id,
            name: item[getTaskContentName(item.type)].name,
            color: item.color ? `#${item.color}` : colorTranslator(index),
            index: item.index || index,
          })
        })
      tempViewTagList.push(...tempArr)
    }
    return setViewTagList([...tempViewTagList])
  }, [garminConnect, hasGarminDirect, dexcomIntegrationId, method])

  useEffect(() => {
    const convertDateToYYMMDDIndex = (date: Date): number => {
      const year = date.getFullYear() % 100 // take 2 digits
      const month = date.getMonth() + 1
      const day = date.getDate()
      return year * 10000 + month * 100 + day
    }

    const endDateOfWeek = new Date(firstDayOfWeek)
    endDateOfWeek.setDate(endDateOfWeek.getDate() + 6)
    const yymmddIndexStart = convertDateToYYMMDDIndex(firstDayOfWeek as Date)
    const yymmddIndexEnd = convertDateToYYMMDDIndex(endDateOfWeek)
    setDisplayYYMMDDRange([yymmddIndexStart, yymmddIndexEnd])
  }, [firstDayOfWeek])

  const convertDateToYYMMIndex = (date: Date): number => {
    const year = date.getUTCFullYear() % 100 // take 2 digits
    const month = date.getUTCMonth() + 1
    return year * 100 + month
  }

  useEffect(() => {
    if (!currentSidebarContentIndex) return
    const participantId = currentSidebarContentIndex.participantId
    const participantDataDigest = currentAdherenceList.find((adherence) => adherence.participantId === participantId)
    if (!participantDataDigest) return
    const digestDay = participantDataDigest.digestDayList.find(
      (digestDay) => digestDay.yymmddIndex === currentSidebarContentIndex.yymmddIndex,
    )
    if (!digestDay) return
    if (!digestDay.detailInfo) return
    const content = digestDay.detailInfo
    const participantName = participantDataDigest?.status?.participantInsignia ?? 'Unknown'
    setSidebarContent({
      yymmddIndex: digestDay.yymmddIndex,
      garminConnect: content.garminConnect,
      garminConnectSynced: content.garminConnect !== undefined,
      participantId: participantId,
      participantName: participantName,
      projectId: projectId,
      status: participantDataDigest?.status,
      task: content.task,
      dexcomEgvDataCount: content.dexcomDataCount,
      // _id: string
    })
    // for (const adherence of currentAdherenceList) {
    //   if (adherence.participantId === participantId) {
    //     for (const digestDay of adherence.digestDayList) {
    //       if (digestDay.yymmddIndex === currentSidebarContentIndex.yymmddIndex) {
    //         if (digestDay.detailInfo) {
    //           const content = digestDay.detailInfo
    //           const participantName = adherence?.status?.participantInsignia
    //           setSidebarContent({
    //             yymmddIndex: digestDay.yymmddIndex,
    //             garminConnect: content.garminConnect,
    //             garminConnectSynced: content.garminConnect !== undefined,
    //             participantId: participantId,
    //             participantName: participantName,
    //             projectId: projectId,
    //             status: adherence?.status,
    //             task: content.task,
    //             dexcomEgvDataCount: content.dexcomDataCount,
    //             // _id: string
    //           })
    //         }
    //         break
    //       }
    //     }
    //     break
    //   }
    // }
  }, [lastDigestDayDetailUpdatedTime, currentSidebarContentIndex])

  /* ------------------ other effect ------------------ */
  const handleChangeWeek = (arg: number) => {
    const changeWeek = timeConvert({time: firstDayOfWeek, type: TimeConvertType.adherenceSwitchWeek, arg}) as Date
    setFirstDayOfWeek(changeWeek)
  }

  useEffect(() => {
    //swap week display
    const displayWeek = timeConvert({time: firstDayOfWeek, type: TimeConvertType.adherenceDisplaySwitchWeek}) as string
    setSwapWeek(displayWeek)

    //attemp fetch data digest
    if (!method.id) return
    const projectMethodList = project?.methodList
    if (!projectMethodList || projectMethodList.length === 0) {
      return
    }
    const projectMethod = projectMethodList[0]

    const yymmIndexStart = convertDateToYYMMIndex(firstDayOfWeek)
    if (!projectAdherence?.projectDataDigest?.coveredYYMMIndex?.includes(yymmIndexStart)) {
      // task data fetch will inlcude all task digest so only need to do once
      // use coveredYYMMIndex to detemine whether task data has been fetched or not
      const requestTaskData = !projectAdherence?.projectDataDigest?.coveredYYMMIndex

      doREQUEST_PROJECT_DATA_DIGEST({
        payload: {
          projectId,
          yymmIndexList: [yymmIndexStart],
          requestTaskData,
          requestGarminData: garminConnect || garminDeviceTask !== undefined,
          requestDexcomData: projectMethod.dexcomIntegrationId !== null,
          garminTaskId: garminDeviceTask?.id,
        },
      })
    }
  }, [firstDayOfWeek])

  // notification
  useEffect(() => {
    let tempState = [...selectedList]
    sortedParticipantList?.map((item) => {
      if (!item.activated) {
        tempState = tempState.filter((el) => el.participantId !== item.id)
      }
    })
    setNotificationList(tempState)
  }, [selectedList])

  const displayTable = () => {
    if (!project?.batchList) {
      return false
    }
    return project.batchList[0].participantList?.length !== 0
  }

  const displayNoParticipantContent = () => {
    if (!project?.batchList) {
      return false
    }
    return loadingState?.length === 0 && project.batchList[0].participantList?.length === 0
  }

  const headerSection = (
    <div
      css={{
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '12px',
        fontWeight: fontWeight.bold,
        fontSize: '20px',
      }}
    >
      Adherence
      <div css={{color: color.textIcon.light, marginLeft: '5px'}}>
        — All data times based on participant's local date time{' '}
      </div>
      {RIF(
        Object.keys(selectedList).length > 0,
        <div
          style={{
            marginLeft: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{marginRight: '8px'}}>{Object.keys(selectedList).length} Selected</div>

          <ButtonReverse
            onClick={() => setDisplayNotificationPop(true)}
            disabled={Object.keys(notificationList)?.length === 0}
            btnPadding='medium'
          >
            <div css={{color: Object.keys(notificationList)?.length === 0 ? color.disabled : color.primary}}>
              Send message to active participants ({Object.keys(notificationList).length})
            </div>
          </ButtonReverse>
        </div>,
      )}
    </div>
  )

  const adherenceTypeSelcetions = () => {
    const selectAdherenceTypeButton = (adherenceType: AdherenceType, buttonName: string) => {
      return (
        <button
          onClick={() => setSelectedAdherenceType(adherenceType)}
          css={{
            padding: '4px 16px',
            backgroundColor: selectedAdherenceType === adherenceType ? color.surface.blue.light : 'transparent',
            border: selectedAdherenceType === adherenceType ? `1px solid ${color.border.blueDark}` : 'none',
            borderRadius: '999px',
            cursor: 'pointer',
            margin: '4px',
            color: selectedAdherenceType === adherenceType ? color.primary : color.textIcon.secondary,
            ':hover': {
              backgroundColor:
                selectedAdherenceType === adherenceType ? color.surface.blue.light : color.surface.grey.light,
            },
          }}
        >
          {buttonName}
        </button>
      )
    }

    if (garminConnect || garminDeviceTask) {
      return (
        <div
          css={{
            padding: '4px',
            background: color.white,
            borderRadius: '999px',
            boxShadow: '0px 4px 12px 0px #D4D4D440',
          }}
        >
          {selectAdherenceTypeButton(AdherenceType.TaskCompletion, 'Task Completion')}
          {RIF(garminConnect, selectAdherenceTypeButton(AdherenceType.GarminConnectWearTime, 'Garmin Wear Time'))}
          {RIF(
            garminDeviceTask,
            selectAdherenceTypeButton(AdherenceType.GarminDirectBbiDigest, 'BBI Collection Time'),
          )}
        </div>
      )
    } else {
      return <div></div>
    }
  }

  const dateController = (
    <div css={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <p css={{color: color.textIcon.secondary}}>Week</p>
      <div
        css={{
          marginLeft: '8px',
          height: '32px',
          width: '184px',
          padding: '6px 8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: color.white,
          border: `1px solid ${color.grey_160}`,
          borderRadius: '5px',
        }}
      >
        <button
          onClick={() => {
            // if display week before project create time, not allow user to click prev week
            if (!projectCreateAt) {
              return
            }
            const projectCreateAtFirstDayOfWeek = timeConvert({
              time: new Date(projectCreateAt),
              type: TimeConvertType.firstDayOfWeekUtc,
            })
            const pcdYYMMDD = timeConvert({
              time: projectCreateAtFirstDayOfWeek as Date,
              type: TimeConvertType.formatToYYMMDD,
            })
            const fdYYMMDD = timeConvert({time: firstDayOfWeek as Date, type: TimeConvertType.formatToYYMMDD})

            if (pcdYYMMDD === fdYYMMDD) return
            else handleChangeWeek(-1)
          }}
          css={{
            cursor: 'pointer',
            width: '20px',
            height: '20px',
            border: 'none',
            padding: '0',
            backgroundImage: `url(${ChevronLeftIcon})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundColor: 'transparent',
          }}
        />
        <p>{swapWeek}</p>
        <button
          onClick={() => {
            // if display week is latest, not allow user to click next week
            if ((firstDayOfWeek as Date).getTime() === (currentFirstDayOfWeek as Date).getTime()) return
            handleChangeWeek(1)
          }}
          css={{
            cursor: 'pointer',
            width: '20px',
            height: '20px',
            border: 'none',
            padding: '0',
            backgroundImage: `url(${ChevronRightIcon})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundColor: 'transparent',
          }}
        />
      </div>
    </div>
  )

  const taskCompletionContentMananeBar = (
    <div
      css={{
        marginBottom: '8px',
        backgroundColor: color.surface.grey.dark,
        borderRadius: '5px',
        overflow: 'hidden',
      }}
    >
      <div
        css={{
          width: '100%',
          display: 'flex',
          padding: '8px 8px 3px',
        }}
      >
        <div
          css={{
            height: '100%',
            paddingRight: '16px',
            borderRight: `1px solid ${color.border._160}`,
          }}
        >
          <div
            onClick={(e: MouseEvent<HTMLInputElement>) => {
              const target = e.target as HTMLButtonElement
              if (target.id !== 'viewMenuBrick') return e.preventDefault
              const btn = document.getElementById('viewMenuBrick')
              const boundingRect = btn?.getBoundingClientRect()
              const xPos = boundingRect?.left || 0
              const yPos = boundingRect?.top || 0
              setViewBtnPosition({xPos, yPos})
              setDisplayViewMenu(!displayViewMenu)
            }}
            onMouseOver={() => setIsViewMenuHover(true)}
            onMouseOut={() => setIsViewMenuHover(false)}
            ref={viewMenuRef}
            id='viewMenuBrick'
            css={{
              minWidth: '80px',
              height: '32px',
              border: `1px solid ${displayViewMenu ? color.primary : color.border._160}`,
              backgroundColor: 'white',
              borderRadius: '5px',
              padding: '0 12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
              cursor: 'pointer',
              ':hover': {
                border: `1px solid ${displayViewMenu ? color.primary : color.black}`,
              },
            }}
          >
            <span id='viewMenuBrick' css={{color: displayViewMenu ? color.primary : ''}}>
              View
            </span>
            <div
              id='viewMenuBrick'
              css={{
                width: '10px',
                height: '10px',
                borderTop: `2px solid`,
                borderRight: `2px solid`,
                marginTop: displayViewMenu ? '5px' : '-5px',
                borderColor: displayViewMenu ? color.primary : isViewMenuHover ? color.black : color.grey_400,
                transform: displayViewMenu ? 'rotate(-45deg)' : 'rotate(135deg)',
              }}
            />
            {RIF(
              displayViewMenu,
              <ViewMenu
                {...{
                  viewBy,
                  setViewBy,
                  viewTagList,
                  setViewTagList,
                  viewDatatype,
                  setViewDatatype,
                  hasGarminDirect,
                  viewBtnPosition,
                }}
              />,
            )}
          </div>
        </div>
        {/* view tags container */}
        <div
          css={{
            marginLeft: '16px',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            overflow: expandingViewTagsList ? 'auto' : 'hidden',
            maxHeight: expandingViewTagsList ? '130px' : '37px',
          }}
        >
          {RIF(
            viewBy === 'Tasks' && viewTagList,
            viewTagList.map((obj) => (
              <ViewTags
                {...{
                  key: t.uuid(),
                  task: obj,
                  viewTagList,
                  setViewTagList,
                  viewTagsWidthList,
                  setViewTagsWidthList,
                  expandingViewTagsList,
                }}
              />
            )),
          )}
          {RIF(viewBy === 'DeviceDataType', <div css={{display: 'flex'}}>{viewDatatype}</div>)}
        </div>
      </div>
      {RIF(
        viewTagsOverflow,
        <button
          onClick={() => setExpandingViewTagsList((prev) => !prev)}
          css={{
            width: '100%',
            height: '20px',
            backgroundColor: color.surface.hover.white,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <p
            css={{
              color: color.textIcon.light,
              fontSize: fontSize.h7,
              marginRight: '4px',
            }}
          >
            Show {expandingViewTagsList ? 'fewer' : 'more'} tasks
          </p>
          <img src={expandingViewTagsList ? ChevronUpIcon : ChevronDownIcon} width='20' />
        </button>,
      )}
    </div>
  )

  return (
    <>
      <ProjectSwitchBar {...{setRenderDataDownloadPage}} projectPanel='Adherence' />

      <AdherenceSidebar
        {...{
          displaySidebar,
          sidebarRef,
          setDisplaySidebar,
          sidebarContent,
          hasGarminDirect,
          hasDexcom: !!dexcomIntegrationId,
          setHighLightBox,
        }}
      />

      {RIF(
        renderDataDownloadPage,
        <DataDownloadPage
          {...{
            closeAction: () => {
              setRenderDataDownloadPage(false)
            },
          }}
        />,
      )}

      {RIF(
        displayNotificationPop,
        <ParticipantNotificationPop
          {...{
            notificationList,
            displayNotificationDiscardPop,
            setNotificationInfo,
            setDisplayNotificationPop,
            setDisplayNotificationDiscardPop,
            setDisplayNotificationInfoPop,
          }}
        />,
      )}

      {RIF(
        displayNotificationDiscardPop,
        <ParticipantNotificationDiscardPop
          {...{
            setDisplayNotificationDiscardPop,
            setDisplayNotificationPop,
          }}
        />,
      )}

      {RIF(
        displayNotificationInfoPop,
        <ParticipantNotificationInfoPop
          {...{
            notificationList,
            notificationInfo,
            closeAction: setDisplayNotificationInfoPop,
            setSelectedList,
          }}
        />,
      )}

      {/* page container */}
      <div
        css={{
          width: '100%',
          height: 'calc(100vh - 106px)',
          backgroundColor: color.background,
          padding: '30px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {headerSection}

        {RIF(
          displayTable(),
          <>
            {/* status bar */}
            <div css={{marginBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              {adherenceTypeSelcetions()}
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  css={{
                    marginRight: '32px',
                    position: 'relative',
                    width: '180px',
                    height: '32px',
                    border: `1px solid ${color.grey_160}`,
                    borderRadius: '5px',
                    background: color.white,
                    ':hover': {
                      border: `1px solid ${color.grey_400}`,
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Input
                    value={searchValue}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                    placeholder='Search Participants'
                    style={{
                      border: 'none',
                      background: 'inherit',
                      height: '100%',
                      width: '100%',
                      padding: '7px 38px 7px 12px',
                    }}
                  />
                  <img
                    src={SearchIcon}
                    width='16'
                    height='16'
                    css={{
                      position: 'absolute',
                      top: '7px',
                      right: '8px',
                    }}
                  />
                </div>
                {dateController}
              </div>
            </div>

            {/* table status bar */}
            {RIF(selectedAdherenceType === AdherenceType.TaskCompletion, taskCompletionContentMananeBar)}

            <div
              css={{
                background: color.white,
                border: `1px solid ${color.grey_100}`,
                borderRadius: '5px',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <AdherenceTableEntry
                {...{
                  tableHeader: true,
                  startDate: firstDayOfWeek,
                  selectedList,
                  setSelectedList,
                  isHeaderSelectBtnClicked,
                  selectedAdherenceType,
                  setIsHeaderSelectBtnClicked,
                  viewTagList,
                }}
              />
              <Scrollbars
                autoHide={false}
                style={{
                  flex: 1,
                }}
              >
                {RIF(
                  displayAdherence?.length > 0,
                  <>
                    {displayAdherence?.map((item, index) => (
                      <AdherenceTableEntry
                        {...{
                          index,
                          setDisplaySidebar,
                          adherence: item,
                          key: item.participantId,
                          viewTagList,
                          viewDatatype,
                          viewBy,
                          setSidebarContent,
                          setCurrentSidebarContentIndex,
                          startDate: firstDayOfWeek,
                          highLightBox,
                          setHighLightBox,
                          selectedList,
                          setSelectedList,
                          isHeaderSelectBtnClicked,
                          selectedAdherenceType,
                          setDisplayNotificationPop,
                          sidebarContent,
                          bbiDataDigestDateMap: participantDataDigestMap[item.participantId]?.garminDirectBbiDataDigest,
                          garminConnectWearTimeDateMap:
                            participantDataDigestMap[item.participantId]?.garminConnectWearTimeDateMap,
                        }}
                      />
                    ))}
                  </>,
                )}
              </Scrollbars>
            </div>
          </>,
        )}

        {RIF(
          displayNoParticipantContent(),
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              paddingTop: '100px',
            }}
          >
            <img src={AdherenceBackground} width='20%' />
            <div
              css={{
                color: color.grey_400,
                fontWeight: fontWeight.medium,
                fontSize: fontSize.h5,
                width: '52%',
                margin: '40px auto 20px',
                textAlign: 'center',
              }}
            >
              With the adherence dashboard, you can view participants' collected data and completed tasks in real-time.
              Save yourself from missing data before it's too late.
            </div>
            <Link to={`/participants/${projectId}`}>
              <Button>Add participants to start</Button>
            </Link>
          </div>,
        )}
      </div>
    </>
  )
}

interface ViewTagsProps {
  task: any
  viewTagList: any[]
  setViewTagList: Dispatch<SetStateAction<any[]>>
  viewTagsWidthList: any[]
  setViewTagsWidthList: Dispatch<SetStateAction<any[]>>
}

export const ViewTags = (props: ViewTagsProps) => {
  const {color, fontSize} = selectTheme()

  const tagRef = useRef<HTMLDivElement>(null)
  const {task, viewTagList, setViewTagList, viewTagsWidthList, setViewTagsWidthList} = props

  useEffect(() => {
    if (tagRef.current) {
      const width = tagRef.current.getBoundingClientRect().width + 5
      if (!_.find(viewTagsWidthList, ['id', task.id])) {
        setViewTagsWidthList((prev) => {
          const result = [...prev]
          result.push({id: task.id, name: task.name, width})
          return result
        })
      }
    }
  }, [])

  const IconContent =
    task?.name === 'Garmin Connect App' ? (
      <img src={GarminGIcon} width='18' />
    ) : task?.name === 'Labfront Companion App' ? (
      'L'
    ) : task?.name === 'Dexcom App' ? (
      'D'
    ) : (
      task?.index + 1
    )

  const handleClickCancel = () => {
    if (viewTagList?.length === 1) return // not allow to cancel when last remains
    // cancel garmin by name, cause garmin name will not repeat
    if (task?.name === 'Garmin Connect App' || task?.name === 'Labfront Companion App') {
      return setViewTagList(viewTagList.filter((obj) => obj.name !== task.name))
    }
    setViewTagList(viewTagList.filter((obj) => obj.id !== task.id))
  }

  return (
    <div
      ref={tagRef}
      css={{
        height: '32px',
        border: `1px solid ${color.grey_160}`,
        borderRadius: '30px',
        padding: '0 8px',
        background: 'transparent',
        display: 'flex',
        alignItems: 'center',
        marginRight: '5px',
        marginBottom: '5px',
        justifyContent: 'space-between',
      }}
    >
      <div
        css={{
          background: task?.color,
          borderRadius: '50%',
          width: '18px',
          height: '18px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: color.white,
          fontSize: '12px',
        }}
      >
        {IconContent}
      </div>
      <span
        css={{
          margin: '0 8px',
          cursor: 'default',
          fontSize: fontSize.h7,
        }}
      >
        {task?.name}
      </span>
      <button
        onClick={handleClickCancel}
        css={{
          width: '24px',
          height: '24px',
          backgroundImage: `url(${CloseIcon})`,
          backgroundSize: '10px',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
        }}
      />
    </div>
  )
}
