import {useMemo} from 'react'
import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {VisualizerGraphDataType, VisualizerGraphResolution, TaskType, IGarminDevice} from '../../shared/db'
import {selectMethod, selectProjectSettings} from '../../store'
import {RIF, t, _} from '../../lib'
import {CommonTaskDataMarker} from './template/time_series_data_chart'
import {
  GarminStreamAccChart,
  MovesenseEcgChart,
  MovesenseImuChart,
  MovesenseStreamBbiChart,
  TimeSeriesActigraphyChart,
  TimeSeriesBbiChart,
  TimeSeriesGlucoseChart,
  TimeSeriesHrChart,
  TimeSeriesPulseOxChart,
  TimeSeriesStepChart,
  TimeSeriesStressChart,
  TimeSeriesTemperatureChart,
} from './time_series_chart'
import {GarminConnectTimeSeriesStressChart} from './time_series_chart/time_series_garmin_connect_stress_chart'
import {GarminConnectTimeSeriesBodyBatteryChart} from './time_series_chart/time_series_garmin_connect_body_battery_chart'
import {GarminConnectTimeSeriesHrChart} from './time_series_chart/time_series_garmin_connect_hr_chart'
import {GarminConnectTimeSeriesStepChart} from './time_series_chart/time_series_garmin_connect_step_chart'
import {TaskStateType} from '../../model'

export interface TimeSeriesChartBlockProps {
  height: string
  width: string
  participantId: string
  plotDataRange?: [Date, Date]
  completionIdList: string[]
  commonTaskDataMarkerList?: CommonTaskDataMarker[]
}

export const TimeSeriesChartsSection = (props: TimeSeriesChartBlockProps) => {
  const {participantId, completionIdList, ...restPropData} = props
  const {projectId} = useParams()
  const projectSettings = projectId ? (selectProjectSettings()[projectId] as any) : undefined // FIXME: temporary cast to any to avoid invalid key type warning
  const methodState = selectMethod()
  const garminDeviceTask: TaskStateType | undefined = _.find(methodState?.taskList, {type: TaskType.GarminDevice}) as
    | TaskStateType
    | undefined
  const garminDeviceTaskDetail: IGarminDevice | null = garminDeviceTask?.garminDevice || null

  const defaultVisualizerSidebarSetting = useMemo(() => {
    const result = []
    if (garminDeviceTaskDetail?.bbiEnable) {
      result.push({
        type: VisualizerGraphDataType.GarminDirectBBI,
        index: result.length,
        id: t.uuid(),
      })
    }
    if (garminDeviceTaskDetail?.stepsEnable) {
      result.push({
        type: VisualizerGraphDataType.GarminDirectSteps,
        index: result.length,
        id: t.uuid(),
      })
    }
    if (garminDeviceTaskDetail?.heartRateEnable) {
      result.push({
        type: VisualizerGraphDataType.GarminDirectHeartRate,
        index: result.length,
        id: t.uuid(),
      })
    }
    if (garminDeviceTaskDetail?.pulseOxEnable) {
      result.push({
        type: VisualizerGraphDataType.GarminDirectSpO2,
        index: result.length,
        id: t.uuid(),
      })
    }
    if (garminDeviceTaskDetail?.stressEnable) {
      result.push({
        type: VisualizerGraphDataType.GarminDirectStress,
        index: result.length,
        id: t.uuid(),
      })
    }
    if (garminDeviceTaskDetail?.actigraphyEnable) {
      result.push({
        type: VisualizerGraphDataType.GarminDirectActigraphyRaw,
        index: result.length,
        id: t.uuid(),
      })
    }
    if (garminDeviceTaskDetail?.temperatureEnable) {
      result.push({
        type: VisualizerGraphDataType.GarminDirectTemperature,
        index: result.length,
        id: t.uuid(),
      })
    }
    return result
  }, [garminDeviceTaskDetail])

  const visualizerSidebarSetting = useMemo<{type: string; index: number; id: string}[]>(() => {
    if (projectSettings?.visualizerSidebarSetting[VisualizerGraphResolution.TimeSeries]?.length) {
      return projectSettings.visualizerSidebarSetting[VisualizerGraphResolution.TimeSeries]?.map(
        (item: {type: string; index: number}) => ({
          ...item,
          id: t.uuid(),
        }),
      )
    }
    return defaultVisualizerSidebarSetting
  }, [
    projectSettings?.visualizerSidebarSetting?.[VisualizerGraphResolution.TimeSeries],
    defaultVisualizerSidebarSetting,
  ])

  const dailyTimeSeriesChartProps = {
    participantId,
    ...restPropData,
  }

  const streamTimeSeriesChartProps = {
    completionIdList,
    ...restPropData,
  }

  const getTimeSeriesChartByDataType = (dataType: string, index: number) => {
    const key = dataType + index
    switch (dataType) {
      case VisualizerGraphDataType.GarminDirectBBI:
        return <TimeSeriesBbiChart key={key} {...dailyTimeSeriesChartProps} />
      case VisualizerGraphDataType.GarminDirectSteps:
        return <TimeSeriesStepChart key={key} {...dailyTimeSeriesChartProps} />
      case VisualizerGraphDataType.GarminDirectHeartRate:
        return <TimeSeriesHrChart key={key} {...dailyTimeSeriesChartProps} />
      case VisualizerGraphDataType.GarminDirectSpO2:
        return <TimeSeriesPulseOxChart key={key} {...dailyTimeSeriesChartProps} />
      case VisualizerGraphDataType.GarminDirectStress:
        return <TimeSeriesStressChart key={key} {...dailyTimeSeriesChartProps} />
      // case VisualizerGraphDataType.GarminRespiration:
      //   return <TimeSeriesRespirationChart {...timeSeriesChartProps} />
      case VisualizerGraphDataType.GarminDirectActigraphyRaw:
        return <TimeSeriesActigraphyChart key={key} {...dailyTimeSeriesChartProps} />
      case VisualizerGraphDataType.GarminDirectAccelerometerRaw:
        return <GarminStreamAccChart key={key} {...streamTimeSeriesChartProps} />
      case VisualizerGraphDataType.GarminDirectTemperature:
        return <TimeSeriesTemperatureChart key={key} {...dailyTimeSeriesChartProps} />
      case VisualizerGraphDataType.MovesenseECGRR:
        return <MovesenseStreamBbiChart key={key} {...streamTimeSeriesChartProps} />
      case VisualizerGraphDataType.MovesenseIMU:
        return <MovesenseImuChart key={key} {...streamTimeSeriesChartProps} />
      case VisualizerGraphDataType.MovesenseECGWaveform:
        return <MovesenseEcgChart key={key} {...streamTimeSeriesChartProps} />
      case VisualizerGraphDataType.DexcomBloodGlucose:
        return <TimeSeriesGlucoseChart key={key} {...dailyTimeSeriesChartProps} />
      case VisualizerGraphDataType.GarminConnectStress:
        return <GarminConnectTimeSeriesStressChart key={key} {...dailyTimeSeriesChartProps} />
      case VisualizerGraphDataType.GarminConnectBodyBattery:
        return <GarminConnectTimeSeriesBodyBatteryChart key={key} {...dailyTimeSeriesChartProps} />
      case VisualizerGraphDataType.GarminConnectHeartRate:
        return <GarminConnectTimeSeriesHrChart key={key} {...dailyTimeSeriesChartProps} />
      case VisualizerGraphDataType.GarminConnectSteps:
        return <GarminConnectTimeSeriesStepChart key={key} {...dailyTimeSeriesChartProps} />
    }
  }
  // console.log('render timeSeiresChartsSection')
  return (
    <div
      css={{
        height: '100%',
        width: '100%',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {RIF(
        visualizerSidebarSetting?.length,
        visualizerSidebarSetting?.map((item, index) => getTimeSeriesChartByDataType(item.type, index)),
      )}
    </div>
  )
}
