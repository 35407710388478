import {ReactElement} from 'react'
import {selectTheme} from '../../../store'
import {QuestionMarkIcon} from '../../../asset/image'
import {Tooltip} from '../..'
import {RIF} from '../../../lib'

export interface ChartCardProps {
  chartTitle: string
  hintDescription: string
  height: string
  width: string
  chart: ReactElement
  grayOutMessage?: string | null
  contentPadding?: string
  titleRightSideElements?: ReactElement
  titleRightSideElementsHeight?: string
}

export const ChartCard = (props: ChartCardProps) => {
  const {
    chartTitle,
    hintDescription,
    titleRightSideElements,
    contentPadding,
    titleRightSideElementsHeight,
    grayOutMessage,
  } = props
  const {color, fontWeight} = selectTheme()

  const chartHight = titleRightSideElementsHeight ? `calc(100% - ${titleRightSideElementsHeight})` : 'calc(100% - 35px)'

  const titleSection = (
    <div
      css={{
        width: '100%',
        padding: '16px 16px 0px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        fontSize: '18px',
      }}
    >
      <p
        css={{
          fontWeight: fontWeight.thick,
          marginRight: '6px',
          whiteSpace: 'nowrap',
        }}
      >
        {chartTitle}
      </p>
      {/* <Tooltip content={''} /> */}

      {/* title right end side optional components */}
      <div
        css={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          fontSize: '18px',
          zIndex: 5,
        }}
      >
        {RIF(titleRightSideElements, titleRightSideElements)}
      </div>
    </div>
  )

  const chartSection = (
    <div
      css={{
        padding: contentPadding ?? '16px',
        height: chartHight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative',
        top: contentPadding ? '-15px' : '0px', // move top for timeSerires chart
        zIndex: 0,
      }}
    >
      {props.chart}
    </div>
  )

  const messageLayer = (message?: string | null) => {
    return (
      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 20,
          backgroundColor: '#EAEAEA',
          opacity: 0.6,
        }}
      >
        <div
          css={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            color: '#000000',
          }}
        >
          {message}
        </div>
      </div>
    )
  }

  return (
    <div
      css={{
        position: 'relative',
        background: color.white,
        height: props.height,
        width: props.width,
        borderRadius: '5px',
        boxShadow: '0px 4px 12px 0px rgba(212, 212, 212, 0.25)',
        marginBottom: '24px',
      }}
    >
      {titleSection}
      {chartSection}
      {RIF(grayOutMessage, messageLayer(grayOutMessage))}
    </div>
  )
}
