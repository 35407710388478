import {useSelector} from 'react-redux'
import {
  create_theme_init_state,
  Pad,
  Color,
  FontSize,
  FontWeight,
  TaskColorList,
  TagColor,
  ColorTranslator,
} from './theme_init_state'
import {createAction} from '@reduxjs/toolkit'

export enum ThemeActionType {
  THEME_SET = 'THEME_SET',
  THEME_RESET = 'THEME_RESET',
  THEME_UPDATE = 'THEME_UPDATE',
}

export const doTHEME_SET = createAction(ThemeActionType.THEME_SET)
export const doTHEME_RESET = createAction(ThemeActionType.THEME_RESET)
export const doTHEME_UPDATE = createAction(ThemeActionType.THEME_UPDATE)

// TODO: completely replace theme with typed theme
export interface ThemeState {
  pad: Pad
  color: Color
  fontSize: FontSize
  fontWeight: FontWeight
  taskColorList: TaskColorList
  tagColor: TagColor
  tagBackgroundColorOpacity: number
  colorTranslator: ColorTranslator
}

export const ThemeDefaultState: ThemeState = create_theme_init_state()

export const themeActionCreators = {
  doTHEME_SET,
  doTHEME_UPDATE,
  doTHEME_RESET,
}

type Action =
  | {
      type: ThemeActionType.THEME_SET
      payload: ThemeState
    }
  | {
      type: ThemeActionType.THEME_RESET
      payload?: ThemeState
    }
  | {
      type: ThemeActionType.THEME_UPDATE
      payload: {
        pad?: Pad
        color?: Color
        fontSize?: FontSize
        fontWeight?: FontWeight
        taskColorList?: TaskColorList
        tagColor?: TagColor
        tagBackgroundColorOpacity?: number
        colorTranslator?: ColorTranslator
      }
    }

/* store.theme reducer */
export const themeReducer = (state = ThemeDefaultState, {type, payload}: Action) => {
  switch (type) {
    case ThemeActionType.THEME_SET:
      return payload
    case ThemeActionType.THEME_RESET:
      return ThemeDefaultState
    case ThemeActionType.THEME_UPDATE:
      return {...state, ...payload}
    default:
      return state
  }
}

/* selector */
export const selectTheme = () => {
  return useSelector((state: {theme: ThemeState}) => state.theme)
}

export {colorTranslator} from './theme_init_state'
