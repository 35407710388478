import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {selectTheme, selectProjectData, selectBatchData, createDispatchActions} from '../../store'
import {ButtonReverse, DataDownloadPage} from '..'
import {DownloadIcon, DownloadGreyIcon} from '../../asset/image'
import {RIF} from '../../lib'
import {IParticipant} from '../../shared/db'

export interface ArchiveStatus {
  jobStatus: 'never' | 'running' | 'succeeded' | 'failed'
  latestDataTimestamp: number | null
  latestBuildTimestamp: number | null
  downloadAvailable: boolean
}

export const DataDownloadSection = () => {
  // extract data from store
  const {color, fontWeight, pad} = selectTheme()
  const {doREQUEST_BATCH_FETCH}: any = createDispatchActions()

  const projectId = useParams()?.projectId || ''
  const project = selectProjectData()?.[projectId]
  const batchId: string = project?.batchList?.[0]?.id
  const batchData = selectBatchData()
  const participantStateList: IParticipant[] = batchData?.[batchId]?.participantList || []
  const noParticipants = participantStateList.length === 0

  useEffect(() => {
    if ((batchData && Object.keys(batchData).length !== 0) || !batchId) return
    doREQUEST_BATCH_FETCH({
      payload: {
        batchId,
      },
    })
  }, [batchData, batchId])

  // create state hook
  const [renderDataDownloadPage, setRenderDataDownloadPage] = useState(false)

  return (
    <div css={{display: 'flex', alignItems: 'center'}}>
      {RIF(
        renderDataDownloadPage,
        <DataDownloadPage
          closeAction={() => {
            setRenderDataDownloadPage(false)
          }}
        />,
      )}

      <div>
        <ButtonReverse
          onClick={() => setRenderDataDownloadPage(true)}
          disabled={noParticipants}
          btnPadding='medium'
          btnColor='primary'
          css={{position: 'relative'}}
          children={
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                color: noParticipants ? color.disabled : color.primary,
                fontWeight: fontWeight.bold,
              }}
            >
              <img width='20' css={{marginRight: pad.small}} src={noParticipants ? DownloadGreyIcon : DownloadIcon} />
              Download Data
            </div>
          }
        />
      </div>
    </div>
  )
}
