export const dailyGarminTemperatureTheme = {
  background: '#ffffff',
  subColor: 'rgba(0,0,0,0.05)',
  semanticRed: '#F4664A',
  semanticGreen: '#30BF78',
  padding: 'auto',
  fontFamily:
    '"Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
  columnWidthRatio: 0.5,
  maxColumnWidth: null,
  minColumnWidth: null,
  roseWidthRatio: 0.9999999,
  multiplePieWidthRatio: 0.7692307692307692,
  sequenceColors: [
    '#e1ebff',
    '#c2d8ff',
    '#a0c5ff',
    '#8ab5fe',
    '#7aa7fc',
    '#6898fa',
    '#558af4',
    '#427de4',
    '#2b6fd5',
    '#0362c5',
  ],
  shapes: {
    point: [
      'hollow-circle',
      'hollow-square',
      'hollow-bowtie',
      'hollow-diamond',
      'hollow-hexagon',
      'hollow-triangle',
      'hollow-triangle-down',
      'circle',
      'square',
      'bowtie',
      'diamond',
      'hexagon',
      'triangle',
      'triangle-down',
      'cross',
      'tick',
      'plus',
      'hyphen',
      'line',
    ],
    line: ['line', 'dash', 'dot', 'smooth'],
    area: ['area', 'smooth', 'line', 'smooth-line'],
    interval: ['rect', 'hollow-rect', 'line', 'tick'],
  },
  sizes: [1, 10],
  components: {
    axis: {
      common: {
        title: {
          autoRotate: true,
          position: 'center',
          spacing: 12,
          style: {
            fill: '#595959',
            fontSize: 12,
            lineHeight: 12,
            textBaseline: 'middle',
            fontFamily:
              '"Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
          },
        },
        label: {
          autoRotate: false,
          autoEllipsis: false,
          autoHide: {type: 'equidistance', cfg: {minGap: 6}},
          offset: 8,
          style: {
            fill: 'rgba(179, 180, 185, 1)',
            fontSize: 12,
            lineHeight: 12,
            fontFamily:
              '"Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            fontWeight: 'normal',
          },
        },
        line: {style: {lineWidth: 1, stroke: 'rgba(230, 230, 232, 1)'}},
        grid: {
          line: {type: 'line', style: {stroke: 'rgba(242, 243, 243, 1)', lineWidth: 1, lineDash: null}},
          alignTick: true,
          animate: true,
        },
        tickLine: {style: {lineWidth: 1, stroke: 'rgba(242, 243, 243, 1)'}, alignTick: true, length: 4},
        subTickLine: {style: {stroke: 'rgba(230, 230, 232, 1)', lineWidth: 1, length: 1}},
        animate: true,
      },
      top: {position: 'top', grid: null, title: {style: {fill: 'rgba(103, 105, 115, 1)'}}, verticalLimitLength: 0.5},
      bottom: {
        position: 'bottom',
        grid: null,
        title: {style: {fill: 'rgba(103, 105, 115, 1)', fontSize: 0}},
        verticalLimitLength: 0.5,
      },
      left: {
        position: 'left',
        title: {style: {fill: 'rgba(103, 105, 115, 1)'}},
        line: null,
        tickLine: null,
        verticalLimitLength: 0.3333333333333333,
      },
      right: {
        position: 'right',
        title: {style: {fill: 'rgba(103, 105, 115, 1)'}},
        line: null,
        tickLine: null,
        verticalLimitLength: 0.3333333333333333,
      },
      circle: {
        title: null,
        grid: {
          line: {type: 'line', style: {stroke: '#D9D9D9', lineWidth: 1, lineDash: null}},
          alignTick: true,
          animate: true,
        },
      },
      radius: {
        title: null,
        grid: {
          line: {type: 'circle', style: {stroke: '#D9D9D9', lineWidth: 1, lineDash: null}},
          alignTick: true,
          animate: true,
        },
      },
    },
    legend: {
      common: {
        title: null,
        marker: {symbol: 'circle', spacing: 8, style: {r: 4, fill: '#5B8FF9'}},
        itemName: {
          spacing: 5,
          style: {
            fill: '#595959',
            fontFamily:
              '"Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            fontSize: 12,
            lineHeight: 12,
            fontWeight: 'normal',
            textAlign: 'start',
            textBaseline: 'middle',
          },
        },
        itemStates: {
          active: {nameStyle: {opacity: 0.8}},
          unchecked: {nameStyle: {fill: '#D8D8D8'}, markerStyle: {fill: '#D8D8D8', stroke: '#D8D8D8'}},
          inactive: {nameStyle: {fill: '#D8D8D8'}, markerStyle: {opacity: 0.2}},
        },
        flipPage: true,
        pageNavigator: {
          marker: {
            style: {size: 12, inactiveFill: '#000', inactiveOpacity: 0.45, fill: 'rgba(179, 180, 185, 1)', opacity: 1},
          },
          text: {style: {fill: 'rgba(179, 180, 185, 1)', fontSize: 12}},
        },
        animate: false,
        maxItemWidth: 200,
        itemSpacing: 24,
        itemMarginBottom: 12,
        padding: [8, 8, 8, 8],
      },
      right: {layout: 'vertical', padding: [0, 8, 0, 8]},
      left: {layout: 'vertical', padding: [0, 8, 0, 8]},
      top: {layout: 'horizontal', padding: [8, 0, 8, 0]},
      bottom: {layout: 'horizontal', padding: [8, 0, 8, 0]},
      continuous: {
        title: null,
        background: null,
        track: {},
        rail: {type: 'color', size: 12, defaultLength: 100, style: {fill: '#D9D9D9', stroke: null, lineWidth: 0}},
        label: {
          align: 'rail',
          spacing: 4,
          formatter: null,
          style: {
            fill: 'rgba(179, 180, 185, 1)',
            fontSize: 12,
            lineHeight: 12,
            textBaseline: 'middle',
            fontFamily:
              '"Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
          },
        },
        handler: {size: 10, style: {fill: 'rgba(224, 224, 224, 1)', stroke: '#BFBFBF'}},
        slidable: true,
        padding: [8, 8, 8, 8],
      },
    },
    tooltip: {
      showContent: true,
      follow: true,
      showCrosshairs: false,
      showMarkers: true,
      shared: false,
      enterable: false,
      position: 'auto',
      marker: {
        symbol: 'circle',
        stroke: 'rgba(255, 255, 255, 1)',
        shadowBlur: 'rgba(255, 255, 255, 1)',
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowColor: 'rgba(24, 24, 24, 0.09)',
        lineWidth: 2,
        r: 5,
      },
      crosshairs: {
        line: {style: {stroke: 'rgba(101, 181, 255, 1)', lineWidth: 1}},
        text: null,
        textBackground: {padding: 2, style: {fill: 'rgba(0, 0, 0, 0.25)', lineWidth: 0, stroke: null}},
        follow: false,
      },
      domStyles: {
        'g2-tooltip': {
          position: 'absolute',
          visibility: 'hidden',
          zIndex: 8,
          transition: 'left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s',
          backgroundColor: 'rgb(255, 255, 255)',
          opacity: 0.95,
          boxShadow: '0px 0px 10px #aeaeae',
          borderRadius: 8,
          color: 'rgba(31, 31, 31, 1)',
          fontSize: '12px',
          fontFamily:
            '"Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
          lineHeight: '12px',
          padding: '0 12px 0 12px',
        },
        'g2-tooltip-title': {marginBottom: '12px', marginTop: '12px'},
        'g2-tooltip-list': {margin: 0, listStyleType: 'none', padding: 0},
        'g2-tooltip-list-item': {
          listStyleType: 'none',
          padding: 0,
          marginBottom: '12px',
          marginTop: '12px',
          marginLeft: 0,
          marginRight: 0,
        },
        'g2-tooltip-marker': {
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          display: 'inline-block',
          marginRight: '8px',
        },
        'g2-tooltip-value': {display: 'inline-block', float: 'right', marginLeft: '30px'},
      },
    },
    annotation: {
      arc: {style: {stroke: '#D9D9D9', lineWidth: 1}, animate: true},
      line: {
        style: {stroke: '#BFBFBF', lineDash: null, lineWidth: 1},
        text: {
          position: 'start',
          autoRotate: true,
          style: {
            fill: '#595959',
            stroke: null,
            lineWidth: 0,
            fontSize: 12,
            textAlign: 'start',
            fontFamily:
              '"Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
            textBaseline: 'bottom',
          },
        },
        animate: true,
      },
      text: {
        style: {
          fill: '#595959',
          stroke: null,
          lineWidth: 0,
          fontSize: 12,
          textBaseline: 'middle',
          textAlign: 'start',
          fontFamily:
            '"Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
        },
        animate: true,
      },
      region: {top: false, style: {lineWidth: 0, stroke: null, fill: '#000', fillOpacity: 0.06}, animate: true},
      image: {top: false, animate: true},
      dataMarker: {
        top: true,
        point: {style: {r: 3, stroke: '#5B8FF9', lineWidth: 2}},
        line: {style: {stroke: '#BFBFBF', lineWidth: 1}, length: 16},
        text: {
          style: {
            textAlign: 'start',
            fill: '#595959',
            stroke: null,
            lineWidth: 0,
            fontSize: 12,
            fontFamily:
              '"Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
          },
        },
        direction: 'upward',
        autoAdjust: true,
        animate: true,
      },
      dataRegion: {
        style: {
          region: {fill: '#000', fillOpacity: 0.06},
          text: {
            textAlign: 'center',
            textBaseline: 'bottom',
            fill: '#595959',
            stroke: null,
            lineWidth: 0,
            fontSize: 12,
            fontFamily:
              '"Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
          },
        },
        animate: true,
      },
    },
    slider: {
      common: {
        padding: [8, 8, 8, 8],
        backgroundStyle: {fill: '#416180', opacity: 0.05},
        foregroundStyle: {fill: '#5B8FF9', opacity: 0.15},
        handlerStyle: {
          width: 10,
          height: 24,
          fill: '#F7F7F7',
          opacity: 1,
          stroke: '#BFBFBF',
          lineWidth: 1,
          radius: 2,
          highLightFill: '#FFF',
        },
        textStyle: {
          fill: '#000',
          opacity: 0.45,
          fontSize: 12,
          lineHeight: 12,
          fontWeight: 'normal',
          stroke: null,
          lineWidth: 0,
        },
      },
    },
    scrollbar: {
      common: {padding: [8, 8, 8, 8]},
      default: {style: {trackColor: 'rgba(0,0,0,0)', thumbColor: 'rgba(0,0,0,0.15)'}},
      hover: {style: {thumbColor: 'rgba(0,0,0,0.2)'}},
    },
  },
  labels: {
    offset: 12,
    style: {
      fill: 'rgba(31, 31, 31, 1)',
      fontSize: 12,
      fontFamily:
        '"Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
      stroke: 'rgba(31, 31, 31, 1)',
      lineWidth: 0,
    },
    fillColorDark: '#2c3542',
    fillColorLight: '#ffffff',
    autoRotate: true,
  },
  innerLabels: {
    style: {
      fill: '#FFFFFF',
      fontSize: 12,
      fontFamily:
        '"Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
      stroke: null,
      lineWidth: 0,
    },
    autoRotate: true,
  },
  overflowLabels: {
    style: {
      fill: '#595959',
      fontSize: 12,
      fontFamily:
        '"Segoe UI", Roboto, "Helvetica Neue", Arial,\n    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",\n    "Noto Color Emoji"',
      stroke: '#FFFFFF',
      lineWidth: 1,
    },
  },
  pieLabels: {
    labelHeight: 14,
    offset: 14,
    labelLine: {style: {lineWidth: null, stroke: 'rgba(48, 191, 120, 1)'}},
    autoRotate: true,
  },
  'bottom-axis-subTick-line': false,
  styleSheet: {
    brandColor: 'rgba(241, 144, 110, 1)',
    paletteQualitative10: [
      'rgba(241, 144, 110, 1)',
      'rgba(255, 202, 66, 1)',
      'rgba(255, 222, 157, 1)',
      'rgba(142, 199, 252, 1)',
      '#7262fd',
      '#78D3F8',
      '#9661BC',
      '#F6903D',
      '#008685',
      '#F08BB4',
    ],
    paletteQualitative20: [
      '#5B8FF9',
      '#CDDDFD',
      '#61DDAA',
      '#CDF3E4',
      '#65789B',
      '#CED4DE',
      '#F6BD16',
      '#FCEBB9',
      '#7262fd',
      '#D3CEFD',
      '#78D3F8',
      '#D3EEF9',
      '#9661BC',
      '#DECFEA',
      '#F6903D',
      '#FFE0C7',
      '#008685',
      '#BBDEDE',
      '#F08BB4',
      '#FFE0ED',
    ],
  },
}
