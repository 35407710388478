import {Nullish} from 'utility-types'
import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'

export enum DataDownloadStatusActionType {
  DATA_DOWNLOAD_STATUS_SET = 'DATA_DOWNLOAD_STATUS_SET',
  DATA_DOWNLOAD_STATUS_RESET = 'DATA_DOWNLOAD_STATUS_RESET',
}

export const doDATA_DOWNLOAD_STATUS_SET = createAction(DataDownloadStatusActionType.DATA_DOWNLOAD_STATUS_SET)
export const doDATA_DOWNLOAD_STATUS_RESET = createAction(DataDownloadStatusActionType.DATA_DOWNLOAD_STATUS_RESET)

export enum DataDownloadState {
  Unknown = 'unknown',
  Started = 'started',
  Completed = 'completed',
  OutdatedCompleted = 'outdatedCompleted',
  Failed = 'failed',
}

export interface DataDownloadStatusData {
  projectId: string
  lastUpdate: {
    createdAt: number
    executionStart: number
    error?: {
      name?: string
      message?: string
      stack?: string[]
    }
  } | null
  job: {
    id: string
    createdAt: number
    state: string
    progress?: {
      completed: number
      total: number
    }
  } | null
  duplicated: boolean | null
}

export type DataDownloadStatusState = Record<string, DataDownloadStatusData & {state: DataDownloadState}>

interface RootState {
  dataDownloadStatus: DataDownloadStatusState
}

export const selectDataDownloadStatus = () => {
  return useSelector((state: RootState) => state.dataDownloadStatus)
}

export const dataDownloadStatusActionCreators = {
  doDATA_DOWNLOAD_STATUS_SET,
  doDATA_DOWNLOAD_STATUS_RESET,
}

export const dataDownloadStatusDefaultState: DataDownloadStatusState = {}

type Action =
  | {
      type: DataDownloadStatusActionType.DATA_DOWNLOAD_STATUS_SET
      payload: DataDownloadStatusData
    }
  | {
      type: DataDownloadStatusActionType.DATA_DOWNLOAD_STATUS_RESET
      payload: never
    }

export const dataDownloadStatusReducer = (
  state: DataDownloadStatusState = dataDownloadStatusDefaultState,
  {type, payload}: Action,
): DataDownloadStatusState => {
  const newState = {...state}
  switch (type) {
    case DataDownloadStatusActionType.DATA_DOWNLOAD_STATUS_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          projectId: v.string().uuid().exist(),
          lastUpdate: v
            .object({
              createdAt: v.number().exist(),
              executionStart: v.number().exist(),
              error: v
                .object({
                  name: v.string().optional(),
                  message: v.string().optional(),
                  stack: v.array().items(v.string()).optional(),
                })
                .optional(),
            })
            .allow(null),
          job: v
            .object({
              id: v.string().uuid().exist(),
              createdAt: v.number().exist(),
              state: v.string().exist(),
              progress: v
                .object({
                  completed: v.number().exist(),
                  total: v.number().exist(),
                })
                .optional(),
            })
            .exist()
            .allow(null),
          duplicated: v.bool().exist().allow(null),
        }),
      })

      const {projectId} = payload
      const oldProjectStatus = state[projectId] as DataDownloadStatusData | Nullish
      // preserve old state when new state having null data
      const job = payload.job ?? oldProjectStatus?.job ?? null
      const lastUpdate = payload.lastUpdate ?? oldProjectStatus?.lastUpdate ?? null
      const duplicated = payload.duplicated ?? oldProjectStatus?.duplicated ?? null

      const resolveState = (): DataDownloadState => {
        // having job means project_data_update_request is called, i.e. update started or failed to start
        if (job) {
          if (job.state === 'failed') {
            return DataDownloadState.Failed
          }
          // having lastUpdate and lastUpdate created later than job means job has completed or failed
          if (lastUpdate && lastUpdate.createdAt > job.createdAt) {
            if (lastUpdate.error) {
              return DataDownloadState.Failed
            }
            // duplicated meaning project_data_update_request was ignored because a job was already running, making output data outdated
            if (duplicated) {
              return DataDownloadState.OutdatedCompleted
            }
            return DataDownloadState.Completed
          }
          return DataDownloadState.Started
        }
        // no job meaning project_data_update_metadata_fetch is called without calling project_data_update_request
        return DataDownloadState.Unknown
      }

      newState[projectId] = {
        projectId,
        job,
        lastUpdate,
        duplicated,
        state: resolveState(),
      }
      return newState
    }

    case DataDownloadStatusActionType.DATA_DOWNLOAD_STATUS_RESET:
      return dataDownloadStatusDefaultState

    default:
      return {...state}
  }
}
