import {ChartConfig, TimeSeriesChartType} from '../template/time_series_data_chart'
import {DailyTimeSeriesDataChart, DailyTimeSeriesChartProps} from '../template/daily_time_series_chart'
import {GenericTimeSeriesDataChartConfig} from '../template/time_series_data_chart'
import {dailyGarminStressTheme} from '../theme'
import {VisualizerGraphDataType} from '../../../shared/db'

export const GarminConnectTimeSeriesBodyBatteryChart = (props: DailyTimeSeriesChartProps) => {
  const {height, width, participantId, plotDataRange, commonTaskDataMarkerList} = props

  const dataConfig = {}

  const chartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Line,
    theme: dailyGarminStressTheme,
  }

  const chartCardConfig = {
    height,
    width,
    title: 'Garmin Connect Body Battery',
    hintDescription: '',
  }

  const config: GenericTimeSeriesDataChartConfig = {
    dataConfig,
    chartConfig,
    chartCardConfig,
  }

  const timeSeriesChartProp = {
    dataType: VisualizerGraphDataType.GarminConnectBodyBattery,
    config,
    participantId,
    plotDataRange,
    commonTaskDataMarkerList,
  }

  return <DailyTimeSeriesDataChart {...timeSeriesChartProp} />
}
