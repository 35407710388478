import {useEffect, useState, Dispatch, SetStateAction} from 'react'
import {selectTheme, createDispatchActions, selectCollaborators, selectWorkspaces, selectProjectData} from '../../store'
import {
  v,
  useForm,
  RIF,
  handleEsc,
  CreateDoRequestActionInput,
  _,
  sortBy,
  useCurrentWorkspaceState,
  RequestResult,
} from '../../lib'
import {Input, TextLink, Button, Select, Checkbox} from '..'
import {CheckmarkIcon, EmptyRadioIcon, SelectedRadioIcon, WarningIcon} from '../../asset/image'
import {Collaborator} from '../../model'
import {components, OptionProps, MenuProps} from 'react-select'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {Controller} from 'react-hook-form'

interface ManageCollaboratorPopupProps {
  workspaceId: string
  setShowing: (arg: boolean) => void
  setFetchCollaboratorList: (arg: boolean) => void
  editingCollaborator: Collaborator | null
  setEditingCollaborator?: Dispatch<SetStateAction<Collaborator | null>>
}

export const ManageCollaboratorPopup = (props: ManageCollaboratorPopupProps) => {
  const {color, fontSize, fontWeight} = selectTheme()

  const {workspaceId, setShowing, setFetchCollaboratorList, editingCollaborator, setEditingCollaborator} = props
  const {
    doREQUEST_WORKSPACE_COLLABORATOR_CREATE,
    doREQUEST_WORKSPACE_COLLABORATOR_UPDATE,
  }: Record<string, Dispatch<CreateDoRequestActionInput>> = createDispatchActions()

  const projectData = selectProjectData()
  const {workspace: currentWorkspace} = useCurrentWorkspaceState()
  // const { workspace:currentWorkspace } = useCurrentWorkspaceState()
  // const userPermission = workspacesState.roleInWorkspaces[currentWorkspace.id] || 'owner'

  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)
  const collaboratorsState = selectCollaborators()
  const [projectList, setProjectList] = useState<any[]>([])
  const [currentWorkspaceCollaboratorProfiles, setCurrentWorkspaceCollaboratorProfiles] = useState<
    {
      id?: string
      email?: string
      firstName?: string
      lastName?: string
      title?: string | null
      imageUrl?: string | null
    }[]
  >([])
  const [selectedPermission, setSelectedPermission] = useState({value: 'viewer', label: 'Viewer'})
  const permissionOptions = [
    {value: 'admin', label: 'Admin'},
    {value: 'editor', label: 'Editor'},
    {value: 'viewer', label: 'Viewer'},
  ]
  const projectPermissionOptions = [
    {value: 'projectAdmin', label: 'Admin'},
    {value: 'projectEditor', label: 'Editor'},
    {value: 'projectViewer', label: 'Viewer'},
  ]
  const handleChangePermission = (e: {value: string; label: string; isDisabled: boolean}) => {
    setSelectedPermission(e)
  }

  // push owner profile to collaborator list
  useEffect(() => {
    if (workspaceId && collaboratorsState?.[workspaceId]) {
      const collaboratorProfileList = Object.values(collaboratorsState?.[workspaceId]).map(
        (collaborator) => collaborator.profile,
      )
      if (currentWorkspace?.ownerProfile) {
        collaboratorProfileList.push(currentWorkspace?.ownerProfile)
      }
      setCurrentWorkspaceCollaboratorProfiles(collaboratorProfileList)
    }
  }, [workspaceId])

  useEffect(() => {
    if (!editingCollaborator) return
    if (editingCollaborator.type === 'project_collaborator') {
      if (editingCollaborator.projectCollaboratorList) {
        setProjectCollaboratorList(editingCollaborator.projectCollaboratorList)
      }
      setRole('projectContributor')
    } else {
      setSelectedPermission(
        _.find(permissionOptions, ['value', editingCollaborator.type]) || {value: 'viewer', label: 'Viewer'},
      )
      setRole('workspaceMember')
    }
  }, [editingCollaborator])

  const projectCollaboratorLabelTranslator: Record<string, string> = {
    projectViewer: 'Viewer',
    projectEditor: 'Editor',
    projectAdmin: 'Admin',
  }
  const [projectCollaboratorList, setProjectCollaboratorList] = useState<{projectId: string; type: string}[]>([])
  const getPermissionForProjectCollaborator = (projectId: string) => {
    const findResult = _.find(projectCollaboratorList, ['projectId', projectId])
    if (findResult) {
      return {value: findResult.type, label: projectCollaboratorLabelTranslator[findResult.type]}
    }
    return {value: 'projectViewer', label: 'Viewer'}
  }
  const handleToggleProjectPermission = (checked: boolean, projectId: string) => {
    if (checked) {
      setProjectCollaboratorList((prev) => {
        return [...prev, {projectId, type: 'projectViewer'}]
      })
    } else {
      setProjectCollaboratorList((prev) => {
        return prev.filter((i) => i.projectId !== projectId)
      })
    }
  }

  const handleChangeProjectPermission = (option: Record<string, string>, projectId: string) => {
    setProjectCollaboratorList((prev) => {
      return prev.map((i) => {
        if (i.projectId === projectId) {
          i.type = option.value
        }
        return i
      })
    })
  }
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(true)
  const [mailWarningMsg, setMailWarningMsg] = useState<string | null>(null)
  const {register, handleSubmit, watch, control} = useForm({mode: 'onChange'})
  const [role, setRole] = useState('workspaceMember')

  useEffect(() => {
    if (workspaceId && !!Object.values(projectData)?.length) {
      const listWithoutDeletedProject = Object.values(projectData).filter((item: any) => item.status !== 'deleted')
      const listOfProjectInCurrentWorkspace = listWithoutDeletedProject
        .filter((item: any) => item.workspaceId === workspaceId)
        .sort(sortBy('createdAt'))
        .reverse()
      setProjectList([...listOfProjectInCurrentWorkspace])
    }
  }, [projectData, workspaceId, location])

  useEffect(() => {
    if (editingCollaborator) return setSubmitButtonDisabled(false)
    setSubmitButtonDisabled(!watch('mail'))
    // reset warning msg on input
    setMailWarningMsg(null)

    if (_.find(currentWorkspaceCollaboratorProfiles, (profile) => profile?.email === watch('mail')))
      setMailWarningMsg('This collaborator is already exist.')
  }, [watch('mail')])

  const emailSchema = v.string().email({tlds: {allow: false}})

  const onSubmit = (data: Record<string, any>) => {
    if (submitButtonDisabled || !!mailWarningMsg) return
    const {error} = emailSchema.validate(data.mail)
    if (error) setMailWarningMsg('Invalid email address. Please check it for typos or mistakes.')
    if (!editingCollaborator) {
      doREQUEST_WORKSPACE_COLLABORATOR_CREATE({
        setRequestResult,
        payload: {
          email: data.mail,
          workspaceId,
          type: role === 'projectContributor' ? 'project_collaborator' : selectedPermission.value,
          projectCollaboratorList: role === 'projectContributor' ? projectCollaboratorList : [],
        },
      })
    } else {
      const upsertArray: {projectId: string; type: string}[] = []
      const deleteArray: {projectId: string}[] = []
      if (editingCollaborator.projectCollaboratorList?.length) {
        editingCollaborator.projectCollaboratorList.forEach((o) => {
          const matching = _.find(projectCollaboratorList, ['projectId', o.projectId])
          if (matching) {
            if (matching.type !== o.type) {
              upsertArray.push(matching)
            }
          } else {
            deleteArray.push({projectId: o.projectId})
          }
        })
      }
      projectCollaboratorList.forEach((o) => {
        if (!o) return
        const matching = _.find(editingCollaborator.projectCollaboratorList, ['projectId', o.projectId])
        if (!matching) {
          upsertArray.push(o)
        }
      })
      const projectCollaboratorAction: {
        upsert?: {projectId: string; type: string}[]
        delete?: {projectId: string}[]
      } = {}
      if (upsertArray.length) projectCollaboratorAction.upsert = upsertArray
      if (deleteArray.length) projectCollaboratorAction.delete = deleteArray
      doREQUEST_WORKSPACE_COLLABORATOR_UPDATE({
        setRequestResult,
        payload: {
          workspaceCollaboratorId: editingCollaborator.id,
          type: role === 'projectContributor' ? 'project_collaborator' : selectedPermission.value,
          projectCollaboratorAction: role === 'projectContributor' ? projectCollaboratorAction : null,
        },
      })
    }
  }

  useEffect(() => {
    if (!requestResult) return
    if (requestResult?.result?.statusCode === 400)
      return setMailWarningMsg('Invalid email address. Please check it for typos or mistakes.')
    if (requestResult?.result?.statusCode === 410)
      return setMailWarningMsg(
        'This user has not joined Labfront yet. Please ask them to create an account, then re-add them as a collaborator.',
      )

    if (requestResult?.success) {
      setFetchCollaboratorList(true)
      setShowing(false)
    }
  }, [requestResult])

  const onError = (error: unknown) => {
    return
  }

  const handleClose = () => {
    if (editingCollaborator && setEditingCollaborator) setEditingCollaborator(null)
    setShowing(false)
  }

  // press esc to cancel
  handleEsc(handleClose)

  return (
    <div
      css={{
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
        position: 'fixed',
        top: 0,
        left: 0,
        background: '#00000040',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 99999,
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        css={{
          borderRadius: '5px',
          backgroundColor: color.white,
          width: '560px',
        }}
      >
        <p
          css={{
            fontWeight: fontWeight.thick,
            width: '100%',
            padding: '16px 24px',
            borderBottom: `1px solid ${color.border._80}`,
          }}
        >
          {editingCollaborator ? 'Edit Permission' : 'Invite Collaborator'}
        </p>
        <div
          css={{
            width: '100%',
            borderBottom: `1px solid ${color.border._80}`,
            padding: '24px',
          }}
        >
          <p
            css={{
              fontWeight: fontWeight.thick,
              marginBottom: '4px',
            }}
          >
            Email
          </p>
          {RIF(
            !editingCollaborator,
            <div css={{marginBottom: '32px'}}>
              <Input
                {...register('mail')}
                placeholder='example@labfront.com'
                type='email'
                hasError={!!mailWarningMsg}
                css={{
                  width: '100%',
                  height: '40px',
                }}
              />
              {RIF(
                !!mailWarningMsg,
                <div css={{display: 'flex', alignItems: 'center', marginTop: '8px'}}>
                  <img src={WarningIcon} width='20' />
                  <span css={{marginLeft: '8px', color: color.warning}}>{mailWarningMsg}</span>
                </div>,
              )}
            </div>,
          )}
          {RIF(
            editingCollaborator,
            <div
              css={{
                marginBottom: '32px',
                width: '100%',
                height: '40px',
                padding: '11px 20px',
                display: 'flex',
                alignItems: 'center',
                border: `1px solid ${color.border._160}`,
                borderRadius: '5px',
                backgroundColor: color.surface.grey.background,
                color: color.textIcon.light,
              }}
            >
              {editingCollaborator?.profile.email}
            </div>,
          )}
          <p
            css={{
              fontWeight: fontWeight.thick,
              marginBottom: '4px',
            }}
          >
            Role
          </p>
          <div
            css={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '32px',
            }}
          >
            <button
              type='button'
              onClick={() => setRole('workspaceMember')}
              css={{
                width: '252px',
                padding: '16px',
                cursor: 'pointer',
                border: '1px solid',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                borderColor: role === 'workspaceMember' ? color.border.blue : color.border._80,
                backgroundColor: role === 'workspaceMember' ? color.surface.blue.light : color.white,
              }}
            >
              <img width='16px' src={role === 'workspaceMember' ? SelectedRadioIcon : EmptyRadioIcon} />
              <p css={{margin: '8px 0'}}>Workspace Member</p>
              <p css={{textAlign: 'start', fontSize: fontSize.h7, color: color.textIcon.secondary}}>
                Can access to all projects in the workspace. Typically used for lab members.
              </p>
            </button>
            <button
              type='button'
              onClick={() => setRole('projectContributor')}
              css={{
                width: '252px',
                padding: '16px',
                cursor: 'pointer',
                border: '1px solid',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                borderColor: role === 'projectContributor' ? color.border.blue : color.border._80,
                backgroundColor: role === 'projectContributor' ? color.surface.blue.light : color.white,
              }}
            >
              <img width='16px' src={role === 'projectContributor' ? SelectedRadioIcon : EmptyRadioIcon} />
              <p css={{margin: '8px 0'}}>Project Contributor</p>
              <p css={{textAlign: 'start', fontSize: fontSize.h7, color: color.textIcon.secondary}}>
                Can only access projects that you specifically choose. Typically used for external collaborators such as
                sponsors, or project-specific research assistants.
              </p>
            </button>
          </div>
          {RIF(
            role === 'workspaceMember',
            <>
              <p
                css={{
                  marginBottom: '8px',
                }}
              >
                Permission
              </p>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Select
                  css={{width: '120px', marginRight: '8px'}}
                  value={selectedPermission}
                  onChange={handleChangePermission}
                  options={permissionOptions}
                  components={{Option, Menu}}
                />
                <p
                  css={{
                    color: color.textIcon.light,
                    fontSize: fontSize.h7,
                  }}
                >
                  {selectedPermission.value === 'viewer'
                    ? 'Viewers can only view the project and download data.'
                    : selectedPermission.value === 'editor'
                    ? 'Editors can create, duplicate and edit projects.'
                    : 'Admins can manage billing and workspace upgrades.'}
                </p>
              </div>
            </>,
          )}
          {RIF(
            role === 'projectContributor',
            <>
              <p
                css={{
                  marginBottom: '8px',
                }}
              >
                Choose at least one project for this contributor to collaborate on:
              </p>
              <p
                css={{
                  marginBottom: '8px',
                  fontSize: fontSize.h7,
                  color: color.textIcon.light,
                }}
              >
                Total {projectList.length} project(s)
              </p>
              <Scrollbars
                autoHide
                autoHideTimeout={200}
                autoHeight
                autoHeightMax={156}
                autoHeightMin={156}
                css={{
                  width: '100%',
                  borderRadius: '5px',
                  border: `1px solid ${color.border._80}`,
                  // height: projectListHeight,
                  // minHeight: '156px',
                  // maxHeight: '156px',
                  overflowY: 'scroll',
                  overflowX: 'visible',
                }}
              >
                {projectList.map((project: any, i: number) => (
                  <div
                    key={project.id}
                    css={{
                      width: 'calc(100% - 32px)',
                      marginLeft: '16px',
                      height: '52px',
                      boxSizing: 'border-box',
                      borderBottom: i === projectList.length - 1 ? 'none' : `1px solid ${color.border._80}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Controller
                        control={control}
                        defaultValue={!!_.find(projectCollaboratorList, ['projectId', project.id])}
                        name={`${project.id}-toggle`}
                        render={({field: {name, value, onChange}}) => (
                          <Checkbox
                            {...{
                              name,
                              value,
                              onChange,
                            }}
                            onChange={(e: boolean) => handleToggleProjectPermission(e, project.id)}
                            value={!!_.find(projectCollaboratorList, ['projectId', project.id])}
                          />
                        )}
                      />
                      <p
                        css={{
                          marginLeft: '8px',
                        }}
                      >
                        {project.name}
                      </p>
                    </div>
                    {RIF(
                      !!_.find(projectCollaboratorList, ['projectId', project.id]),
                      // watch(`${project.id}-toggle`),
                      <Select
                        css={{width: '120px'}}
                        options={projectPermissionOptions}
                        onChange={(e: Record<string, string>) => handleChangeProjectPermission(e, project.id)}
                        value={{
                          value: _.find(projectCollaboratorList, ['projectId', project.id])?.type ?? 'projectViewer',
                          label:
                            _.find(projectCollaboratorList, ['projectId', project.id])?.type.replace('project', '') ??
                            'Viewer',
                        }}
                      />,
                    )}
                    {RIF(
                      !_.find(projectCollaboratorList, ['projectId', project.id]),
                      // !watch(`${project.id}-toggle`),
                      <p
                        css={{
                          color: color.textIcon.secondary,
                        }}
                      >
                        No Access
                      </p>,
                    )}
                  </div>
                ))}
              </Scrollbars>
            </>,
          )}
        </div>
        <div
          css={{
            padding: '16px 24px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <TextLink onClick={handleClose}>Cancel</TextLink>
          <Button disabled={submitButtonDisabled || !!mailWarningMsg}>
            {editingCollaborator ? 'Save' : 'Send Invitation'}
          </Button>
        </div>
      </form>
    </div>
  )
}

const Menu = (props: MenuProps) => {
  const {color} = selectTheme()
  return (
    <components.Menu
      {...props}
      css={{
        width: '320px',
        borderRadius: '5px',
        border: `1px solid ${color.border._160}`,
        backgroundColor: color.white,
        overflow: 'hidden',
        padding: 0,
      }}
    >
      {props.children}
    </components.Menu>
  )
}

const Option = (props: OptionProps) => {
  const {fontSize, fontWeight, color} = selectTheme()
  return (
    <components.Option
      {...props}
      css={{
        display: 'flex',
        alignItems: 'flex-start',
        cursor: 'pointer',
        width: '100%',
        height: '86px',
        boxSizing: 'border-box',
        backgroundColor: color.white,
        padding: '16px',
        borderBottom: props.label === 'Editor' ? `1px solid ${color.border._160}` : 'none',
        borderTop: props.label === 'Editor' ? `1px solid ${color.border._160}` : 'none',
        ':hover': {
          backgroundColor: color.surface.hover.white,
        },
      }}
    >
      <div
        css={{
          width: '16px',
          marginRight: '8px',
        }}
      >
        {RIF(props.isSelected, <img width='16' src={CheckmarkIcon} />)}
      </div>
      <div>
        <p
          css={{
            fontWeight: fontWeight.thick,
            marginBottom: '4px',
          }}
        >
          {props.label}
        </p>
        <p
          css={{
            fontSize: fontSize.h7,
            color: color.textIcon.secondary,
            width: '248px',
            whiteSpace: 'break-spaces',
          }}
        >
          {props.label === 'Viewer'
            ? 'Viewers can only view the project and download data.'
            : props.label === 'Editor'
            ? 'Editors can create, duplicate and edit projects.'
            : 'Admins can manage billing and workspace upgrades.'}
        </p>
      </div>
    </components.Option>
  )
}
