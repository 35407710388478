import { OptionalId } from './type'

export interface ParticipantDigestDay extends OptionalId {
  day: string
  dayDate: Date
  dayUnixTimestamp: number
  participantId: string
  projectId: string
  batchId: string
  garminConnectSynced: boolean
  garminConnect?: ParticipantDigestDayGarminConnectData
  task: Record<string, ParticipantDigestDayTask>
  dexcomDataCount?: number
}

export interface ParticipantDigestDayTask {
  taskId: string
  taskType: string
  timestampList?: ParticipantDigestDayTaskTimestamp[]
}

export interface ParticipantDigestDayTaskComplete
  extends ParticipantDigestDayTask {
  timestampList: ParticipantDigestDayTaskCompleteTimestamp[]
}

export interface ParticipantDigestDayTaskTimer
  extends ParticipantDigestDayTask {
  timestampList: ParticipantDigestDayTaskTimerTimestamp[]
}

// TODO add boolean values for each data type collected
export interface ParticipantDigestDayTaskStopwatchMovesenseStream
  extends ParticipantDigestDayTask {
  timestampList: ParticipantDigestDayTaskStreamDataTimestamp[]
}

export interface ParticipantDigestDayTaskGarminDevice
  extends ParticipantDigestDayTask {
  bbiCollected: boolean
  accelerometerCollected: boolean
  zeroCrossingCollected: boolean
  stepsCollected: boolean
  stressCollected: boolean
  heartRateCollected: boolean
  pulseOxCollected: boolean
  respirationCollected: boolean
  actigraphyCollected: boolean
  temperatureCollected: boolean
  bbiUnixTimestampFirst: number | null
  bbiUnixTimestampLast: number | null
  bbiMSDuration: number
  bbiMSAccumulated: number
  bbiMSMissing: number
  bbiChunkCount: number
  bbiSampleCount: number
  bbiIntegrityChunkAverage: number | null
  bbiIntegrityAccumulated: number | null
  bbiIntegrityDay: number | null
}

export interface ParticipantDigestDayTaskGarminDeviceV2
  extends ParticipantDigestDayTask {
  bbi?: boolean
  acc?: boolean
  zeroCrossing?: boolean
  steps?: boolean
  stress?: boolean
  hr?: boolean
  pulseOx?: boolean
  respiration?: boolean
  actigraphy?: boolean
  bbiMSAccumulated?: number
  temperature?: boolean
}

export enum ParticipantDigestDayGarminConnectDataType {
  Activity = 'activityCollected',
  ActivityDetails = 'activityDetailsCollected',
  ActivityFiles = 'activityFilesCollected',
  ActivityMoveIQ = 'activityMoveIQCollected',
  HealthHRVSummary = 'healthHRVSummaryCollected',
  HealthBodyCompositions = 'healthBodyCompositionsCollected',
  HealthDailies = 'healthDailiesCollected',
  HealthEpochs = 'healthEpochsCollected',
  HealthSnapshot = 'healthSnapshotCollected',
  HealthPulseOx = 'healthPulseOxCollected',
  HealthRespiration = 'healthRespirationCollected',
  HealthSleeps = 'healthSleepsCollected',
  HealthStress = 'healthStressCollected',
  HealthThirdPartyDailies = 'healthThirdPartyDailiesCollected',
  HealthUserMetrics = 'healthUserMetricsCollected',
  HealthBloodPressure = 'healthBloodPressureCollected',
  WomenHealthMCT = 'womenHealthMCTCollected',
}

export interface ParticipantDigestDayGarminConnectData {
  synced: boolean
  [ParticipantDigestDayGarminConnectDataType.Activity]: boolean
  [ParticipantDigestDayGarminConnectDataType.ActivityDetails]: boolean
  [ParticipantDigestDayGarminConnectDataType.ActivityFiles]: boolean
  [ParticipantDigestDayGarminConnectDataType.ActivityMoveIQ]: boolean
  [ParticipantDigestDayGarminConnectDataType.HealthHRVSummary]: boolean
  [ParticipantDigestDayGarminConnectDataType.HealthBodyCompositions]: boolean
  [ParticipantDigestDayGarminConnectDataType.HealthDailies]: boolean
  [ParticipantDigestDayGarminConnectDataType.HealthEpochs]: boolean
  [ParticipantDigestDayGarminConnectDataType.HealthSnapshot]: boolean
  [ParticipantDigestDayGarminConnectDataType.HealthPulseOx]: boolean
  [ParticipantDigestDayGarminConnectDataType.HealthRespiration]: boolean
  [ParticipantDigestDayGarminConnectDataType.HealthSleeps]: boolean
  [ParticipantDigestDayGarminConnectDataType.HealthStress]: boolean
  [ParticipantDigestDayGarminConnectDataType.HealthThirdPartyDailies]: boolean
  [ParticipantDigestDayGarminConnectDataType.HealthUserMetrics]: boolean
  [ParticipantDigestDayGarminConnectDataType.HealthBloodPressure]: boolean
  [ParticipantDigestDayGarminConnectDataType.WomenHealthMCT]: boolean
}

export type ParticipantDigestDayTaskTimestamp =
  | ParticipantDigestDayTaskCompleteTimestamp
  | ParticipantDigestDayTaskTimerTimestamp
  | ParticipantDigestDayTaskStreamDataTimestamp

export interface ParticipantDigestDayTaskCompleteTimestamp {
  timezone: string
  timeOffset: number
  unixTimestampComplete: number
}

export interface ParticipantDigestDayTaskTimerTimestamp {
  timezone: string
  timeOffset: number
  unixTimestampStart: number
  unixTimestampComplete: number
}

export interface ParticipantDigestDayTaskStreamDataTimestamp
  extends ParticipantDigestDayTaskTimerTimestamp {
  completionId: string
}
