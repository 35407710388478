import {useEffect, useMemo, useState} from 'react'
import {Select, Tooltip, ToolTipHover} from '..'
import {RIF} from '../../lib'
import {ParticipantDataDigestMap, selectAdherenceData, selectTheme} from '../../store'
import {GarminConnectIcon, LabfrontIcon} from '../../asset/image'
import {TaskStateType} from '../../model'

export enum AdherenceOverviewDataType {
  JoinedProject = 'joinedProject',
  setupGarminConnect = 'setupGarminConnect',
  setupGarminDirect = 'setupGarminDirect',
  setupDexcom = 'setupDexcom',
  GarminConnect = 'garminConnectDataDates',
  GarminDirect = 'garminDeviceDataDatesByType',
  DexcomEGV = 'dexcomDataDates',
  LabfronTask = 'taskDataDates',
}

export interface ProjectAdherenceOverviewProp {
  taskList?: TaskStateType[]
  projectId: string
  finalDate: Date
  dateDuration: number
  totalParticipantCount: number
  garminDeviceSpec?: Record<string, boolean>
  adherenceOverviewClickCallback: (
    dataType: AdherenceOverviewDataType,
    setupStatus: boolean,
    yymmddIndex: number,
    subDataName?: {
      label: string
      value: string
    },
  ) => void
}

export interface AdherenceOverviewCardProp extends ProjectAdherenceOverviewProp {
  title: string
  hintDescription: string
  dataType: AdherenceOverviewDataType
  mainColor: string
  defaultSubDataType?: SubDataTypeOption
  subDataTypeOptions?: SubDataTypeOption[]
}

export interface DailyAdherenceOverview {
  yymmddIndex: number
  adherenceCount: number
  participantIdList: string[]
}

export interface SubDataTypeOption {
  label: string
  value: string
}

export const AdherenceOverviewCard = (props: AdherenceOverviewCardProp) => {
  const {
    title,
    projectId,
    dataType,
    finalDate,
    dateDuration,
    totalParticipantCount,
    mainColor,
    defaultSubDataType,
    subDataTypeOptions,
    adherenceOverviewClickCallback,
  } = props
  const {color, fontWeight, fontSize} = selectTheme()
  const [subDataType, setSubDataType] = useState<SubDataTypeOption | undefined>(defaultSubDataType)

  useEffect(() => {
    if (!defaultSubDataType) return
    setSubDataType(defaultSubDataType)
  }, [defaultSubDataType])

  const dateList = useMemo<number[]>(() => {
    const convertDateToYYMMDDIndex = (date: Date): number => {
      const year = date.getUTCFullYear() % 100 // take 2 digits
      const month = date.getUTCMonth() + 1
      const day = date.getUTCDate()
      return year * 10000 + month * 100 + day
    }

    const result: number[] = [convertDateToYYMMDDIndex(finalDate)]
    for (let i = 1; i < dateDuration; i++) {
      const date = new Date(finalDate)
      date.setDate(date.getDate() - i)
      result.push(convertDateToYYMMDDIndex(date))
    }
    return result.reverse()
  }, [finalDate, dateDuration])

  const dataSource = selectAdherenceData()?.[projectId]?.projectDataDigest?.participantDataDigestMap

  const convertParticipantDataDigestMapToDailyOverviewAdherence = (
    digestMap: ParticipantDataDigestMap,
    yymmddIndex: number,
    dataType: AdherenceOverviewDataType,
    subDataType?: SubDataTypeOption,
  ): DailyAdherenceOverview => {
    let adherenceCount = 0
    const participantIdList: string[] = []
    for (const [participantId, digest] of Object.entries(digestMap)) {
      if (dataType === AdherenceOverviewDataType.GarminConnect || dataType === AdherenceOverviewDataType.DexcomEGV) {
        if (digest[dataType]?.includes(yymmddIndex)) {
          adherenceCount++
          participantIdList.push(participantId)
        }
      } else if (
        dataType === AdherenceOverviewDataType.GarminDirect ||
        dataType === AdherenceOverviewDataType.LabfronTask
      ) {
        const data = digest[dataType] as Record<string, number[]> | undefined
        if (subDataType && data?.[subDataType.value]?.includes(yymmddIndex)) {
          adherenceCount++
          participantIdList.push(participantId)
        }
      }
    }
    return {
      yymmddIndex,
      adherenceCount,
      participantIdList,
    }
  }

  const overviewAdherenceList = useMemo<DailyAdherenceOverview[]>(() => {
    if (dataSource) {
      return dateList.map((yymmddIndex) => {
        return convertParticipantDataDigestMapToDailyOverviewAdherence(dataSource, yymmddIndex, dataType, subDataType)
      })
    }
    return []
  }, [dataSource, dataType, dateList, subDataType])

  interface DailyOverviewAdherenceBarProps {
    data: DailyAdherenceOverview
    totalParticipantCount: number
  }

  const DailyOverviewAdherenceBar = (props: DailyOverviewAdherenceBarProps) => {
    const {data, totalParticipantCount} = props
    const {color, fontSize} = selectTheme()
    const adherenceCountDiff = totalParticipantCount - data.adherenceCount
    const adherencePercentage = Math.floor((data.adherenceCount / totalParticipantCount) * 100)
    const monthIndex = Math.floor((data.yymmddIndex % 10000) / 100) - 1
    const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const month = monthList[monthIndex]
    const day = data.yymmddIndex % 100
    const dateString = `${month} ${day}`
    const [hovering, setHovering] = useState(false)
    return (
      <button
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        key={data.yymmddIndex}
        css={{
          border: 'none',
          borderRadius: '2px',
          backgroundColor: 'transparent',
          padding: '8px 0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '140px',
          width: '40px',
          cursor: 'pointer',
          position: 'relative',
          ':hover': {
            backgroundColor: color.hover,
          },
        }}
        onClick={() => adherenceOverviewClickCallback(dataType, false, data.yymmddIndex, subDataType)}
      >
        {/* <div
          css={{
            background: color.white,
            height: '15px',
            width: '15px',
            borderRadius: '2px',
            border: `1px solid ${color.border._160}`,
            color: color.textIcon.secondary,
            fontWeight: fontWeight.thick,
            fontSize: fontSize.h8,
            marginBottom: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {adherenceCountDiff}
        </div> */}
        <div
          css={{
            marginBottom: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: fontSize.h7,
            fontWeight: fontWeight.thick,
          }}
        >
          {data.adherenceCount}
          <span
            css={{
              fontSize: fontSize.h7,
              fontWeight: fontWeight.thick,
              color: color.textIcon.light,
              marginLeft: '4px',
            }}
          >{`/ ${totalParticipantCount}`}</span>
        </div>

        <div
          css={{
            height: '58px',
            width: '16px',
            borderRadius: '2px',
            border: `1px solid ${mainColor}`,
            marginBottom: '8px',
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <div
            css={{
              background: mainColor,
              height: `${adherencePercentage}%`,
              width: '100%',
            }}
          />
        </div>

        <div
          css={{
            color: color.textIcon.secondary,
            fontSize: fontSize.h7,
          }}
        >
          {dateString}
        </div>
        {RIF(
          hovering,
          <ToolTipHover
            {...{
              hoverTopPosition: '-100%',
              pseudoTopPosition: '100%',
              pseudoRightPosition: '50%',
            }}
            title={
              <>
                <p css={{textAlign: 'center', color: color.white, fontSize: fontSize.h6}}>
                  Participants w/o Data: {adherenceCountDiff}
                </p>
                <br />
                <br />
                <p css={{textAlign: 'center', color: color.white, fontSize: fontSize.h6}}>
                  Participants w/ Data: {data.adherenceCount}
                </p>
                <br />
                <p css={{textAlign: 'center', color: color.white, fontSize: fontSize.h6}}>
                  Click to view participants list
                </p>
              </>
            }
          />,
        )}
      </button>
    )
  }

  const adherenceOverviewBarList = (dataList: DailyAdherenceOverview[], totalParticipantCount: number) => {
    return (
      <div
        css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${color.border._80}`,
          paddingBottom: '8px',
          marginBottom: '8px',
        }}
      >
        {dataList.map((item) => (
          <DailyOverviewAdherenceBar
            {...{
              key: item.yymmddIndex,
              data: item,
              totalParticipantCount,
            }}
          />
        ))}
      </div>
    )
  }

  const displayDataTypeSelect = (
    <Select
      value={subDataType}
      options={subDataTypeOptions}
      onChange={(e: SubDataTypeOption) => {
        setSubDataType(e)
      }}
      css={{
        marginBottom: '16px',
        width: '100%',
        fontSize: fontSize.h7,
      }}
    />
  )

  return (
    <div
      css={{
        background: color.white,
        width: '100%',
        padding: '24px 24px 16px 24px',
        borderRadius: '5px',
        boxShadow: '0px 4px 12px 0px rgba(212, 212, 212, 0.25)',
        marginBottom: '24px',
      }}
    >
      {/* Title */}
      <div
        css={{
          width: '100%',
          display: 'flex',
          marginBottom: '16px',
        }}
      >
        {RIF(
          props.dataType === AdherenceOverviewDataType.GarminConnect ||
            props.dataType === AdherenceOverviewDataType.GarminDirect,
          <img
            width={24}
            height={24}
            css={{marginRight: '8px'}}
            src={props.dataType === AdherenceOverviewDataType.GarminConnect ? GarminConnectIcon : LabfrontIcon}
          />,
        )}
        <div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <p
              css={{
                fontSize: fontSize.h6,
                fontWeight: fontWeight.thick,
                marginRight: '6px',
                whiteSpace: 'nowrap',
              }}
            >
              {title}
            </p>
            {/* <Tooltip content={''} /> */}
          </div>
          <p
            css={{
              color: color.textIcon.light,
              fontSize: fontSize.h7,
            }}
          >
            {title === 'Tasks'
              ? '# of participants who completed task in past 7 days'
              : '# of participants with data in the past 7 days'}
          </p>
        </div>
      </div>

      {RIF(subDataTypeOptions, displayDataTypeSelect)}

      {adherenceOverviewBarList(overviewAdherenceList, totalParticipantCount)}

      <div
        css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          css={{
            backgroundColor: mainColor,
            width: '12px',
            height: '12px',
            borderRadius: '2px',
            marginRight: '4px',
          }}
        />
        <p
          css={{
            fontSize: fontSize.h8,
            color: color.textIcon.light,
            marginRight: '16px',
          }}
        >
          Data collected
        </p>
        {/* <p css={{
          width: '12px',
          height: '12px',
          borderRadius: '2px',
          border: `1px solid ${color.border._160}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '4px',
          fontSize: fontSize.h8,
        }}>#</p>
        <p css={{
          fontSize: fontSize.h8,
          color: color.textIcon.light,
        }}># of participants has no data</p> */}
      </div>
    </div>
  )
}
