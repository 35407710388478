import {useState, useEffect, Dispatch, SetStateAction, useMemo, useRef} from 'react'
import {ParticipantTimeSeriesDataState} from '../../store'

import {StreamTaskEvent, TaskTimeLineChart} from '../charts/task_timeline_chart'
import _ from 'lodash'
import {DateObject} from 'react-multi-date-picker'
import {TimeSeriesChartsSection} from '../charts/time_series_charts_section'
import {CommonTaskDataMarker} from '../charts/template/time_series_data_chart'
import {GarminDirectDataLoader} from '../../lib/chart_data/data_loader/garmin_direct_data_loader'
import {GarminConnectDataLoader} from '../../lib/chart_data/data_loader/garmin_connect_data_loader'
import {StreamDataLoader, StreamDataLoaderProps} from '../../lib/chart_data/data_loader/stream_data_loader'

export interface TimeSeriesChartBlockProps {
  participantId: string
  yymmddIndex: number
  setSelectedDate: Dispatch<SetStateAction<DateObject>>
}

export const TimeSeriesChartBlock = (props: TimeSeriesChartBlockProps) => {
  const {participantId, yymmddIndex, setSelectedDate} = props
  /* ------------------ reducer & store basic state ------------------ */
  useState<ParticipantTimeSeriesDataState>()
  const [loadDataYYMMDDIndex, setLoadDataYYMMDDIndex] = useState<number[]>([yymmddIndex])
  const [timeSeriesChartDateRange, setTimeSeriesChartDateRange] = useState<[Date, Date]>()
  const [commonTaskDataMarkerList, setCommonTaskDataMarkerList] = useState<CommonTaskDataMarker[]>([])
  const [completionIdList, setCompletionIdList] = useState<string[]>([])
  const [streamDataLoaderProps, setStreamDataLoaderProps] = useState<StreamDataLoaderProps>({
    timeRange: [0, 0],
    eventList: [],
  })
  /* ------------------ default effect ------------------ */
  GarminDirectDataLoader({participantId, loadDataYYMMDDIndex})
  GarminConnectDataLoader({participantId, loadDataYYMMDDIndex})
  StreamDataLoader({...streamDataLoaderProps})

  const attemptToLoadStreamDataCallback = (timeRange: [number, number], eventList: StreamTaskEvent[]) => {
    if (eventList.length > 0) {
      setStreamDataLoaderProps({
        timeRange,
        eventList,
      })

      const completionIdList = eventList.map((event) => {
        return event.completionId
      })

      setCompletionIdList(completionIdList)
    } else {
      setCompletionIdList([])
    }
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 66px)',
      }}
    >
      <TaskTimeLineChart
        {...{
          participantId: participantId,
          selectedYYMMDDIndex: yymmddIndex,
          setSelectedDate,
          setTimeSeriesChartDateRange,
          setLoadDataYYMMDDIndex,
          setCommonTaskDataMarkerList,
          attemptToLoadStreamDataCallback,
        }}
      />

      <div
        css={{
          background: '#FAFAFA',
          flex: 1,
          overflow: 'hidden',
          padding: '24px 32px',
        }}
      >
        <div
          css={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <TimeSeriesChartsSection
            {...{
              height: '220px',
              width: '100%',
              participantId,
              completionIdList,
              plotDataRange: timeSeriesChartDateRange,
              commonTaskDataMarkerList,
            }}
          />
        </div>
      </div>
    </div>
  )
}
