import {configureStore, combineReducers} from '@reduxjs/toolkit'

import {createLogger} from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

import {loadSessionStorage} from '../lib'
import * as stateInterface from './state'
import * as sagaInterface from './saga'
import Instrumentation from '../instrumentation'

const storeInterface: {[key: string]: any} = {
  ...sagaInterface,
  ...stateInterface,
}

// combine all functions in src/store/{state,saga} that end en Reduce into one Object
// keys will be function name without the 'Reducer' suffix
// for example the 'themeReducer' function well be come a key called 'theme'
const reducer = combineReducers(
  Object.keys(storeInterface).reduce((result, key) => {
    const current = key.endsWith('Reducer')
      ? ({[key.replace('Reducer', '')]: storeInterface[key]} as {[key: string]: any})
      : {}
    return {
      ...current,
      ...result,
    }
  }, {}),
)

// export type RootState = ReturnType<typeof reducer>

const sagaFunctionList = Object.keys(storeInterface).reduce((result: any, key) => {
  const current = key.endsWith('Saga') ? [storeInterface[key]] : []
  return [...current, ...result]
}, [])

export const createAppStore = () => {
  const logger = createLogger()
  const saga = createSagaMiddleware({
    onError: (error, info) => {
      Instrumentation.shared.perform((api) => {
        const state = store.getState() as any
        if (state?.profile?.id) {
          const {id, email, firstName, lastName} = state.profile
          api.setUser({
            id,
            email,
            username: `${firstName} ${lastName}`,
          })
        }
        api.pushError(error, {
          context: {
            info: JSON.stringify(info),
            source: 'Saga',
          },
        })
      })
      throw error
    },
  })
  const preloadedState: any = loadSessionStorage() ? loadSessionStorage() : {}
  if (preloadedState?.totalParticipants) {
    delete preloadedState.totalParticipants
    delete preloadedState.currentWorkspaceId
  }
  const store = configureStore({
    reducer,
    middleware: [saga, logger],
    preloadedState,
  })
  sagaFunctionList.forEach(saga.run)
  return store
}

export const createTestStore = (initialState?: any) => {
  const logger = createLogger()
  const preloadedState: any = loadSessionStorage() ? loadSessionStorage() : {}
  const saga = createSagaMiddleware()
  const store = configureStore({
    reducer,
    middleware: [saga],
    preloadedState: {...preloadedState, ...initialState},
  })
  sagaFunctionList.forEach(saga.run)

  return store
}
