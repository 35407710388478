import {useEffect, useRef, useState} from 'react'
import {ChevronDownGreyIcon} from '../../asset/image'
import {ITag} from '../../shared/db'
import {selectTheme} from '../../store'
import {Button, Checkbox} from '../atoms'
import {RIF} from '../../engine'
import {useClickOutside} from '../../engine'

export const ParticipantTagsFilter = (props: {
  fullTagList: ITag[]
  selectedTagList: ITag[]
  setSelectedTagList: (tags: ITag[]) => void
}) => {
  const {color, fontSize} = selectTheme()
  const {fullTagList, selectedTagList, setSelectedTagList} = props
  const [tempSelectedTagList, setTempSelectedTagList] = useState<ITag[]>([])
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    if (showDropdown) {
      setTempSelectedTagList(selectedTagList)
    }
  }, [showDropdown])

  const ref = useRef(null)
  useClickOutside(ref, () => {
    setShowDropdown(false)
  })

  const handleToggleTags = (checked: boolean, tagId: string) => {
    if (checked) {
      const findResult = fullTagList.find((t) => t.id === tagId)
      if (!findResult) return
      setTempSelectedTagList([...tempSelectedTagList, findResult])
    } else {
      setTempSelectedTagList(tempSelectedTagList.filter((t) => t.id !== tagId))
    }
  }

  const handleApply = () => {
    if (tempSelectedTagList.length) setSelectedTagList(tempSelectedTagList)
    setShowDropdown(false)
  }

  const handleClick = () => {
    setShowDropdown((prev) => !prev)
  }

  return (
    <div
      ref={ref}
      css={{
        position: 'relative',
      }}
    >
      <button
        onClick={handleClick}
        css={{
          marginRight: '16px',
          height: '32px',
          width: '160px',
          borderRadius: '5px',
          border: `1px solid ${color.border.default}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 8px',
          background: color.white,
          cursor: 'pointer',
        }}
      >
        <p
          css={{
            fonSize: fontSize.h7,
            color: color.textIcon.secondary,
          }}
        >
          {selectedTagList.length === fullTagList.length ? 'All Tags' : `${selectedTagList.length} Tag(s) Selected`}
        </p>
        <img src={ChevronDownGreyIcon} width={20} />
      </button>
      {RIF(
        showDropdown,
        <div
          css={{
            position: 'absolute',
            left: '0',
            top: '34px',
            width: '160px',
            borderRadius: '5px',
            border: `1px solid ${color.border.default}`,
            padding: '16px',
            background: color.white,
            zIndex: 100,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          {fullTagList.map((tag: ITag) => (
            <label
              key={tag.id}
              css={{
                display: 'flex',
                alignItems: 'center',
                height: '24px',
                marginBottom: '4px',
                cursor: 'pointer',
              }}
            >
              <Checkbox
                onChange={(e: boolean) => handleToggleTags(e, tag.id)}
                value={tempSelectedTagList.some((t) => t.id === tag.id)}
              />
              <p
                css={{
                  marginLeft: '8px',
                  padding: '2px 6px',
                  borderRadius: '5px',
                  color: `#${tag.color}`,
                  backgroundColor: `#${tag.color}1A`,
                  fontSize: fontSize.h8,
                }}
              >
                {tag.value}
              </p>
            </label>
          ))}
          <Button
            onClick={handleApply}
            btnPadding={'small'}
            css={{marginTop: '12px', fontSize: fontSize.h7}}
            children={'Apply'}
          />
        </div>,
      )}
    </div>
  )
}
