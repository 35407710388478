import {Dispatch, SetStateAction} from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import {getToken} from './token_fetcher'
import { AnalysisDataType } from '../../shared/mongo'

export const REQUEST_PROJECT_ANALYSIS_DATA_JOB_STATE_FETCH = 'REQUEST_PROJECT_ANALYSIS_DATA_JOB_STATE_FETCH'
export const doREQUEST_PROJECT_ANALYSIS_DATA_JOB_STATE_FETCH = createDoRequestAction(
  REQUEST_PROJECT_ANALYSIS_DATA_JOB_STATE_FETCH,
)

export const requestProjectAnalysisDataJobStateFetchActionCreators = {
  doREQUEST_PROJECT_ANALYSIS_DATA_JOB_STATE_FETCH,
}

interface RequestProjectAnalysisDataJobStateFetchPayload {
  payload: {
    requestId: string
    projectId: string
    analysisDataTypeList: AnalysisDataType[]
  }
  type: string
  setRequestResult?: Dispatch<SetStateAction<RequestResult>>
}

export function* requestProjectAnalysisDataJobStateFetch({
  payload,
  setRequestResult,
}: RequestProjectAnalysisDataJobStateFetchPayload): SagaIterator {
  debug('saga*requestProjectAnalysisDataJobStateFetch')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
        analysisDataTypeList: v.array().items(v.string()).required(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_PROJECT_ANALYSIS_DATA_JOB_STATE_FETCH,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const {projectId, analysisDataTypeList} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/project-analysis-data-job-state-fetch`,
    accessToken,
    data: {
      projectId,
      analysisDataTypeList
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_PROJECT_ANALYSIS_DATA_JOB_STATE_FETCH,
          fromPayload: payload,
          requestId: payload.requestId,
          url: '/v1/web/project-analysis-data-job-state-fetch',
          ...result,
          error,
        },
        setRequestResult,
      ),
    )
  }

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: REQUEST_PROJECT_ANALYSIS_DATA_JOB_STATE_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/project-analysis-data-job-state-fetch',
        ...result,
      },
      setRequestResult,
    ),
  )
}

export function* ProjectAnalysisDataJobStateFetchSaga() {
  yield takeEvery(REQUEST_PROJECT_ANALYSIS_DATA_JOB_STATE_FETCH, requestProjectAnalysisDataJobStateFetch)
}
