import {useState, useEffect, forwardRef, Ref, ChangeEvent} from 'react'
import {calculateInputCSS} from '../../lib/calculate_input_css'

/** a styled input component, the onChange hook has the func signature onChange(value, event) */
export const Input = forwardRef((props: any, ref?: Ref<HTMLInputElement>) => {
  const [value, setValue] = useState(props.value || '')
  const sanitizedProps = {...props}
  delete sanitizedProps.hasError

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    // const asciiOnly = inputValue.replace(/[^\x00-\x7F]/g, '')
    setValue(inputValue)
    if (props.onChange) props.onChange(e, e.target.value)
  }

  // useEffect(() => {
  //   if (props.value != value && props.value) setValue(props.value)
  // }, [props.value])

  return (
    <input
      {...sanitizedProps}
      ref={ref}
      css={{...calculateInputCSS(props), ...sanitizedProps.style}}
      value={value}
      onChange={handleChange}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
    />
  )
})
